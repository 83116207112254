import {Component, Input, OnInit} from '@angular/core'
import {FormControl, ReactiveFormsModule} from '@angular/forms'
import {Observable} from 'rxjs'
import {map, startWith} from 'rxjs/operators'
import {MatFormField, MatHint} from '@angular/material/form-field'
import {
  MatAutocomplete,
  MatAutocompleteTrigger,
  MatOption
} from '@angular/material/autocomplete'
import {I18nModule} from '../../../../i18n/i18n.module'
import {AsyncPipe} from '@angular/common'
import {MatInput} from '@angular/material/input'
import {SortPipe} from '../../../../common/pipes/sort.pipe'

@Component({
  selector: 'kdl-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrl: './general-settings.component.scss',
  imports: [
    MatFormField,
    ReactiveFormsModule,
    MatAutocompleteTrigger,
    I18nModule,
    AsyncPipe,
    MatInput,
    MatAutocomplete,
    MatOption,
    SortPipe,
    MatHint
  ],
  standalone: true
})

export class GeneralSettingsComponent implements OnInit {

  @Input() public label = ''
  @Input() public control: FormControl = new FormControl<string | null>(null)
  @Input() public optionsMap: Map<string, string> = new Map()
  @Input() hint: string = ''
  @Input() width: number = 160
  public filteredOptions: Observable<string[]>

  public ngOnInit(): void {
    this.filteredOptions = this.control.valueChanges.pipe(
      startWith(''),
      map(value => this.filter(value))
    )
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase()
    const result: string[] = []
    this.optionsMap.forEach((opt: string) => {
      if (opt.toLowerCase().includes(filterValue)) {
        result.push(opt)
      }
    })
    return result
  }

}
