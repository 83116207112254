import {TApplianceTypeName} from '../service/appliance.service'
import {Control, LeafFactory} from './leaf-factory'
import {TOptionSelectName} from '../../model/cabinet-option'

/**
 * Array containing all possible values of {@link TApplianceConfigConditionParam}
 */
const ApplianceConfigConditionParams = [
  'width',
  'height',
  'depth',
  'openingWidth',
  'openingHeight',
  // Special case: Cabinet width - recess left - recess right
  'widthWithRecess',
  // Special case: Only appliance height dimensions considered
  'applianceHeight',
  // Special case: Only cabinet opening width dimensions only considered
  'cabinetOpeningWidth'
] as const
/**
 * Condition type for an appliance configuration. It will be used to know
 * which parameter for appliance/cabinet to use when making calculation for
 * the conditions.
 */
export type TApplianceConfigConditionParam = typeof ApplianceConfigConditionParams[number]

/**
 * Array containing all possible values of {@link TApplianceItemKey}
 */
const ApplianceItemKeys = [
  'white_goods',
  'stoves',
  'hobs',
  'extractorHobs',
  'ovens',
  'microwaveCombos',
  'micros',
  'extractors',
  'dishwashers',
  'fridge',
  'wine_fridge',
  'freezers',
  'fridge_freezers',
  'electric',
  'gas',
  'free_standing',
  'integrated',
  'fixed',
  'pull_out',
  'wall_mounted',
  'free_hanging',
  'width_60',
  'width_70',
  'width_90',
  'width_100',
  'width_120',
  'height_standard',
  'height_xxl',
  'other',
  'width_45',
  'sliding_door',
  'door_on_door',
  // Fittings
  'fittings',
  'handles',
  'knobs',
  'turning_knobs',
  'knob',
  'hooks',
  'knob_hook',
  'brass',
  'chrome',
  'chrome_nickel',
  'copper',
  'stainless',
  'antique',
  'wood',
  'leather',
  'black',
  'white',
  'large',
  'small',
  'overlay_door',
  'inset_door',
  // Sinks & faucet
  'sink_faucet',
  'sink',
  'faucet',
  'basket_strainer',
  'single',
  'double',
  'porcelain',
  'gold',
  'antique_bronze',
  'brushed_nickel',
  'sink_inset',
  'sink_outside',
  // Paint
  'paints',
  'linseed',
  'standard',
  '1', '12', '19', '25', '31',
  '32', '34', '38', '48', '58',
  '67', '69', '86', '87', '97',
  '100', '101', '107', '111', '116',
  '148', '151',
  'accessories_others'

] as const
/**
 * Appliance tree key, which represents part of the path/tree of an appliance.
 * For example, an over could have a tree like with three keys:
 * White Goods > Ovens > Electric
 */
export type TApplianceItemKey = typeof ApplianceItemKeys[number]

export interface IAppliancePossibleCabinet {
  cat: string | RegExp
  neededCabinetOptions?: TOptionSelectName[]
}

export interface IApplianceConfigCondition {
  description: string
  parameter: TApplianceConfigConditionParam
  upperExtra?: number
  lowerExtra?: number
}

export interface IAppliancePossibleCabinetsConfig {
  /**
   * Cabinet categories, or a regex to achieve things like "all categories
   * starting with 'B'", to which an appliance-tree can be connected to.
   */
  categories: (string | RegExp)[]
  /**
   * Some cabinet require specific options to be active ("active" parameter to
   * true) in order to be able to have a connected appliance.
   *
   * If multiple are needed, all of them need to be active to connect appliance.
   */
  neededCabinetOptions?: TOptionSelectName[]
  /**
   * Conditions that an appliance must fulfill in order to be able to connect
   * to a cabinet.
   * If the conditions are not met, the appliance will not appear a possibility
   * when picking for an appliance-to-be-connected-to-cabinet.
   */
  connectConditions: IApplianceConfigCondition[]
  /**
   * Similar to connect conditions, however, only needs to be met and a
   * warning will be shown next to the appliance when connecting to cabinet.
   * The appliance can be picked to be connected per-se, but with a warning.
   */
  warningConditions: IApplianceConfigCondition[]
}

/**
 * It is an item, not a Map so we name it item for clarity
 */
export interface IApplianceConfig {
  /**
   * A future icon for each selection
   */
  icon: string
  /**
   * Display name, currently English only
   */
  name: string
  /**
   * Key as an identifier for this "level"
   */
  key: TApplianceItemKey
  /**
   * Basically a legacy selector for "applianceType"
   */
  type?: TApplianceTypeName
  /**
   * If we have others following mutually exclusive with controls
   */
  options?: IApplianceConfig[]
  /**
   * Form controls for this leaf item.
   */
  controls?: Control[]
  /**
   * Possible cabinets (their categories) that an appliance type can be linked
   * to. For example, a "White good > Oven > Electric" can go into:
   * BD1o, BL1o, BD1o+PC, BL1o+PC, ToDx2, ToD1L2, ToD1L3, TomD1L2 or TomDx2
   * Furthermore, some conditions will need to be met in order for a connection
   * to be possible. And sometimes, even if there is a possible connection, a
   * warning might need to be displayed.
   *
   * IMPORTANT: More generic conditions, those with cabinet categories as
   * RexExps instead of strings, should go deeper in the array. This is
   * because when evaluating them, the first one matching will be used.
   */
  possibleCabinetsConfigs?: IAppliancePossibleCabinetsConfig[]
}

/////////////////////////////////////////////////////////////////////////
// Common conditions for possible cabinets - Ovens & Microwaves
/////////////////////////////////////////////////////////////////////////
const ovenAndMicrosConnectConditions: IApplianceConfigCondition[] = [
  {
    description: '[Appliance width] should be [cabinet opening width] +/- 45 mm',
    parameter: 'openingWidth', upperExtra: 45, lowerExtra: -45
  }
]
const ovenAndMicrosWarningConditions: IApplianceConfigCondition[] = [
  {
    description: '[Cabinet opening width] should be [appliance width] + 5-9 mm',
    parameter: 'openingWidth', upperExtra: -5, lowerExtra: -9
  },
  {
    description: '[Cabinet depth] should be [appliance depth] + 10 mm or more',
    parameter: 'depth', upperExtra: -10
  }
]
const ovenAndMicrosPCWarningConditions: IApplianceConfigCondition[] = [
  {
    description: '[Cabinet opening width] should be [appliance width] + 100 mm or more',
    parameter: 'width', upperExtra: -100
  },
  {
    description: '[Cabinet depth] should be [appliance depth] + 10 mm or more',
    parameter: 'depth', upperExtra: -10
  }
]

/////////////////////////////////////////////////////////////////////////
// Common conditions for possible cabinets - Extractors
/////////////////////////////////////////////////////////////////////////
const extractorsConnectConditions: IApplianceConfigCondition[] = [
  {
    description: '[Appliance width] should be same as [cabinet width] - 45 mm or less',
    parameter: 'width', upperExtra: -45
  }
]
const extractorsWarningConditions: IApplianceConfigCondition[] = [
  {
    description: '[Cabinet width] should be minimum: [appliance width] + [left recess] + [right recess] + 45 mm',
    parameter: 'width', upperExtra: -45
  },
  {
    description: '[Cabinet depth] should be [appliance depth] + 35 mm or more',
    parameter: 'depth', upperExtra: -35
  }
]

/////////////////////////////////////////////////////////////////////////
// Common conditions for possible cabinets - Dishwashers
/////////////////////////////////////////////////////////////////////////
const dishwashersConnectConditions: IApplianceConfigCondition[] = [
  {
    description: '[Appliance width] should be [cabinet opening width] +/- 45 mm',
    parameter: 'openingWidth', upperExtra: 45, lowerExtra: -45
  }
]
const dishwashersWarningConditions: IApplianceConfigCondition[] = [
  {
    description: '[Cabinet opening width] should be [appliance width] + 0-10 mm',
    parameter: 'openingWidth', upperExtra: 0, lowerExtra: -10
  },
  {
    description: '[Cabinet opening height] should be [appliance height] + 5 mm or more',
    parameter: 'openingHeight', upperExtra: -5
  },
  {
    description: '[Cabinet depth] should be [appliance depth] + 10 mm or more',
    parameter: 'depth', upperExtra: -10
  }
]

/////////////////////////////////////////////////////////////////////////
// Common conditions for possible cabinets - Fridge & Freezers in TNd
/////////////////////////////////////////////////////////////////////////
const coolersInTndConnectConditions: IApplianceConfigCondition[] = [
  {
    description: '[Appliance width] should be [cabinet opening width] +/- 45 mm',
    parameter: 'openingWidth', upperExtra: 45, lowerExtra: -45
  }
]
const coolersInTndWarningConditions: IApplianceConfigCondition[] = [
  {
    description: '[Cabinet opening width] should be [appliance width] + 5-15 mm',
    parameter: 'openingWidth', upperExtra: -5, lowerExtra: -15
  },
  {
    description: '[Cabinet opening height] should be [appliance height] + 10-15 mm',
    parameter: 'openingHeight', upperExtra: -10, lowerExtra: -15
  },
  {
    description: '[Cabinet depth] should be [appliance depth] + 10 mm or more',
    parameter: 'depth', upperExtra: -10
  }
]

/////////////////////////////////////////////////////////////////////////
// Common conditions for possible cabinets - Fridge & Freezers in TWD (Door-On-Door)
/////////////////////////////////////////////////////////////////////////
const coolersDoDInTWDConnectConditions: IApplianceConfigCondition[] = [
  {
    description: '[Appliance height] should be minimum 1000 mm',
    parameter: 'applianceHeight', lowerExtra: 1000
  },
  {
    description: '[Appliance width] should be [cabinet opening width] +/- 60 mm',
    parameter: 'openingWidth',
    upperExtra: 60,
    lowerExtra: -60
  }
]
const coolersDoDInTWDWarningConditions: IApplianceConfigCondition[] = [
  {
    description: '[Cabinet opening width] should be 570-580 mm',
    parameter: 'cabinetOpeningWidth',
    upperExtra: 580,
    lowerExtra: 570
  },
  {
    description: '[Cabinet opening height] should be [appliance height] + 10 mm or more',
    parameter: 'openingHeight', upperExtra: -10
  },
  {
    description: '[Cabinet depth] should be [appliance depth] + 30 mm or more',
    parameter: 'depth', upperExtra: -30
  }
]

/////////////////////////////////////////////////////////////////////////
// Common conditions for possible cabinets - Fridge & Freezers in TWD (Sliding-Door)
/////////////////////////////////////////////////////////////////////////
const coolersSDInTWDConnectConditions: IApplianceConfigCondition[] = coolersDoDInTWDConnectConditions
const coolersSDInTWDWarningConditions: IApplianceConfigCondition[] = [
  // TODO - Special cases that involves hinges!
  {
    description: 'If Hinge type is "1. Klassiskt..." -> then opening width should be 580 mm',
    parameter: 'cabinetOpeningWidth',
    upperExtra: 580,
    lowerExtra: 580
  },
  // TODO - Special cases that involves hinges!
  {
    description: 'If Hinge type is "2. Moderna mjuksstängande..." -> then opening width should be 585 mm',
    parameter: 'cabinetOpeningWidth',
    upperExtra: 585,
    lowerExtra: 585
  },
  {
    description: '[Cabinet opening height] should be [appliance height] + 10 mm or more',
    parameter: 'openingHeight', upperExtra: -10
  },
  {
    description: '[Cabinet depth] should be [appliance depth] + 30 mm or more',
    parameter: 'depth', upperExtra: -30
  }
]

/////////////////////////////////////////////////////////////////////////
// Common conditions for possible cabinets - Fridge & Freezers in BD (Door-On-Door)
/////////////////////////////////////////////////////////////////////////
const coolersDoDInBDConnectConditions: IApplianceConfigCondition[] = [
  {
    description: 'Appliance height should be MAX 1000 mm',
    parameter: 'applianceHeight', upperExtra: 1000
  },
  {
    description: '[Appliance width] should be [cabinet opening width] +/- 60 mm',
    parameter: 'openingWidth',
    upperExtra: 60,
    lowerExtra: -60
  }
]
const coolersDoDInBDWarningConditions: IApplianceConfigCondition[] = [
  {
    description: '[Cabinet opening width] should be 600-610 mm',
    parameter: 'cabinetOpeningWidth',
    upperExtra: 600,
    lowerExtra: 610
  },
  {
    description: '[Cabinet opening height] should be [appliance height] + 5 mm or more',
    parameter: 'openingHeight', upperExtra: -5
  },
  {
    description: '[Cabinet depth] should be [appliance depth] + 30 mm or more',
    parameter: 'depth', upperExtra: -30
  }
]

/////////////////////////////////////////////////////////////////////////
// Common conditions for possible cabinets - Fridge & Freezers in BD (Sliding-Door)
/////////////////////////////////////////////////////////////////////////
const coolersSDInBDConnectConditions: IApplianceConfigCondition[] = coolersDoDInBDConnectConditions
const coolersSDInBDWarningConditions: IApplianceConfigCondition[] = [
  {
    description: '[Cabinet opening width] should be MIN 40 mm larger than [appliance width]',
    parameter: 'openingWidth', upperExtra: -40
  },
  {
    description: '[Cabinet opening height] should be [appliance height] + 5 mm or more',
    parameter: 'openingHeight', upperExtra: -5
  },
  {
    description: '[Cabinet depth] should be [appliance depth] + 30 mm or more',
    parameter: 'depth', upperExtra: -30
  }
]

/////////////////////////////////////////////////////////////////////////
// Common conditions for possible cabinets - Sink Inset
/////////////////////////////////////////////////////////////////////////
const sinkInsetConnectConditions: IApplianceConfigCondition[] = [{
  description: '[Appliance width] should be same as [cabinet width] - 45 mm or less',
  parameter: 'width', upperExtra: -45
}]
const sinkInsetWarningConditions: IApplianceConfigCondition[] = [
  {
    description: '[Cabinet width] should be minimum: [appliance width] + [left recess] + [right recess] + 45 mm',
    parameter: 'widthWithRecess', upperExtra: -45
  },
  {
    description: '[Cabinet depth] should be [appliance depth] + 100 mm or more',
    parameter: 'depth', upperExtra: -100
  }
]

/////////////////////////////////////////////////////////////////////////
// Common conditions for possible cabinets - Sink Outside
/////////////////////////////////////////////////////////////////////////
const sinkOutsideConnectConditions: IApplianceConfigCondition[] = [{
  description: '[Appliance width] should be same as [cabinet width] - 45 mm or less',
  parameter: 'width', upperExtra: -45
}]
const sinkOutsideWarningConditions: IApplianceConfigCondition[] = [
  {
    description: '[Cabinet opening width] should be: [appliance width] + 5 + 10 mm',
    parameter: 'openingWidth', upperExtra: -5, lowerExtra: -10
  },
  {
    description: '[Cabinet height] should be [appliance height] + 3 mm',
    parameter: 'height', upperExtra: -3
  },
  {
    description: '[Cabinet depth] should be [appliance depth] + 100 mm or more',
    parameter: 'depth', upperExtra: -100
  }
]


/**
 * Array of freestanding appliance families (path/appliance tree).
 */
export const FreestandingApplianceTrees: TApplianceItemKey[][] = [
  ['white_goods', 'stoves', 'electric'],
  ['white_goods', 'stoves', 'gas'],
  ['white_goods', 'microwaveCombos', 'free_standing'],
  ['white_goods', 'extractors', 'wall_mounted'],
  ['white_goods', 'extractors', 'free_hanging'],
  ['white_goods', 'dishwashers', 'free_standing'],
  ['white_goods', 'fridge', 'free_standing'],
  ['white_goods', 'wine_fridge', 'free_standing'],
  ['white_goods', 'freezers', 'free_standing'],
  ['white_goods', 'fridge_freezers', 'free_standing']
]

/**
 * All appliance families (paths/appliance trees) and their configurations.
 * Basically, all possible configurations and values for Mill Appliances.
 */
export const ApplianceTrees: IApplianceConfig[] = [
  {
    name: 'White Goods',
    key: 'white_goods',
    icon: 'white-goods.png',
    type: 'group',
    options: [
      {
        name: 'Stove',
        key: 'stoves',
        icon: 'stove.png',
        type: 'stove',
        options: [
          {
            name: 'Electric',
            key: 'electric',
            icon: '',
            controls: LeafFactory.getStoveHobControls()
          },
          {
            name: 'Gas',
            key: 'gas',
            icon: '',
            controls: LeafFactory.getStoveHobControls()
          }
        ]
      },
      {
        name: 'Hob',
        key: 'hobs',
        icon: 'hob.png',
        type: 'hob',
        possibleCabinetsConfigs: [{
          categories: [/^B\w+$/],
          neededCabinetOptions: ['StoveTopAdoption'],
          connectConditions: [{
            description: '[Appliance width] should be same as [cabinet width] - 110 mm or less',
            parameter: 'depth', upperExtra: -110
          }],
          warningConditions: [{
            description: '[Cabinet depth] should be [appliance depth] + 35 mm or more',
            parameter: 'depth', upperExtra: -35
          }]
        }],
        options: [
          {
            name: 'Electric',
            key: 'electric',
            icon: '',
            controls: LeafFactory.getStoveHobControls()
          },
          {
            name: 'Gas',
            key: 'gas',
            icon: '',
            controls: LeafFactory.getStoveHobControls()
          }
        ]
      },
      {
        name: 'Hob with extractor',
        key: 'extractorHobs',
        icon: 'hob-w-extractor.png',
        type: 'hobWExt',
        possibleCabinetsConfigs: [
          {
            categories: [/^B\w+$/],
            neededCabinetOptions: ['StoveTopAdoption', 'FanAdoption'],
            connectConditions: extractorsConnectConditions,
            warningConditions: extractorsWarningConditions
          }
        ],
        options: [
          {
            name: 'Electric',
            key: 'electric',
            icon: '',
            controls: LeafFactory.getStoveHobControls()
          },
          {
            name: 'Gas',
            key: 'gas',
            icon: '',
            controls: LeafFactory.getStoveHobControls()
          }
        ]
      },
      {
        name: 'Oven',
        key: 'ovens',
        icon: '',
        type: 'oven',
        possibleCabinetsConfigs: [
          {
            categories: ['BD1o', 'BL1o', 'ToDx2', 'ToD1L2', 'ToD1L3', 'TomD1L2', 'TomDx2'],
            connectConditions: ovenAndMicrosConnectConditions,
            warningConditions: ovenAndMicrosWarningConditions
          },
          {
            categories: ['BD1o+PC', 'BL1o+PC'],
            connectConditions: [],
            warningConditions: ovenAndMicrosPCWarningConditions
          }
        ],
        options: [
          {
            name: 'Electric',
            key: 'electric',
            icon: '',
            controls: LeafFactory.getOvenControls()
          },
          {
            name: 'Gas',
            key: 'gas',
            icon: '',
            controls: LeafFactory.getOvenControls()
          },
          {
            name: 'Combo microwave',
            key: 'microwaveCombos',
            icon: '',
            controls: LeafFactory.getOvenControls()
          }
        ]
      },
      {
        name: 'Microwave oven',
        key: 'micros',
        icon: 'microwave-oven.png',
        type: 'micro',
        options: [
          {
            name: 'Free standing',
            key: 'free_standing',
            icon: '',
            controls: LeafFactory.getMicroControls()
          },
          {
            name: 'Integrated',
            key: 'integrated',
            icon: '',
            controls: LeafFactory.getMicroControls(),
            possibleCabinetsConfigs: [
              {
                categories: ['BD1o', 'BL1o', 'ToDx2', 'ToD1L2', 'ToD1L3', 'TomD1L2', 'TomDx2', 'TmDx2', 'TmD1L3'],
                connectConditions: ovenAndMicrosConnectConditions,
                warningConditions: ovenAndMicrosWarningConditions
              },
              {
                categories: [/^OD\w+$/],
                neededCabinetOptions: ['MicroAdoption'],
                connectConditions: ovenAndMicrosConnectConditions,
                warningConditions: ovenAndMicrosWarningConditions
              },
              {
                categories: ['BD1o+PC', 'BL1o+PC'],
                connectConditions: [],
                warningConditions: ovenAndMicrosPCWarningConditions
              }
            ]
          }
        ]
      },
      {
        name: 'Extractor',
        key: 'extractors',
        icon: 'extractor.png',
        type: 'fan',
        options: [
          {
            name: 'Integrated',
            key: 'integrated',
            icon: '',
            possibleCabinetsConfigs: [
              {
                categories: [/^OD\w+$/],
                neededCabinetOptions: ['FanAdoption'],
                connectConditions: extractorsConnectConditions,
                warningConditions: extractorsWarningConditions
              },
              {
                categories: ['ONdEx'],
                connectConditions: extractorsConnectConditions,
                warningConditions: extractorsWarningConditions
              }
            ],
            options: [
              {
                name: 'Fixed',
                key: 'fixed',
                icon: '',
                controls: LeafFactory.getExtractorIntControls()
              },
              {
                name: 'Pull-out front',
                key: 'pull_out',
                icon: '',
                controls: LeafFactory.getExtractorIntControls()
              }
            ]
          },
          {
            name: 'Wall mounted',
            key: 'wall_mounted',
            icon: '',
            controls: LeafFactory.getExtractorWallFreeControls()
          },
          {
            name: 'Free hanging',
            key: 'free_hanging',
            icon: '',
            controls: LeafFactory.getExtractorWallFreeControls()
          }
        ]
      },
      {
        name: 'Dishwasher',
        key: 'dishwashers',
        icon: 'dishwasher.png',
        type: 'dishwasher',
        options: [
          {
            name: 'Free standing',
            key: 'free_standing',
            icon: '',
            options: [
              {
                name: 'Width: 60 cm',
                key: 'width_60',
                icon: '',
                options: [
                  {
                    name: 'Standard height (820)',
                    key: 'height_standard',
                    icon: '',
                    controls: LeafFactory.getDishwasherControls()
                  },
                  {
                    name: 'XXL (865)',
                    key: 'height_xxl',
                    icon: '',
                    controls: LeafFactory.getDishwasherControls()
                  },
                  {
                    name: 'Other height',
                    key: 'other',
                    icon: '',
                    controls: LeafFactory.getDishwasherControls()
                  }
                ]
              },
              {
                name: 'Width: 45 cm',
                key: 'width_45',
                icon: '',
                options: [
                  {
                    name: 'Standard height (820)',
                    key: 'height_standard',
                    icon: '',
                    controls: LeafFactory.getDishwasherControls()
                  },
                  {
                    name: 'XXL (865)',
                    key: 'height_xxl',
                    icon: '',
                    controls: LeafFactory.getDishwasherControls()
                  },
                  {
                    name: 'Other height',
                    key: 'other',
                    icon: '',
                    controls: LeafFactory.getDishwasherControls()
                  }
                ]
              }
            ]
          },
          {
            name: 'Integrated',
            key: 'integrated',
            icon: '',
            options: [
              {
                name: 'Sliding door',
                key: 'sliding_door',
                icon: '',
                options: [
                  {
                    name: 'Width: 60 cm',
                    key: 'width_60',
                    icon: '',
                    possibleCabinetsConfigs: [{
                      categories: ['BD1dw-600'],
                      connectConditions: dishwashersConnectConditions,
                      warningConditions: dishwashersWarningConditions
                    }],
                    options: [
                      {
                        name: 'Standard height (820)',
                        key: 'height_standard',
                        icon: '',
                        controls: LeafFactory.getDishwasherControls()
                      },
                      {
                        name: 'XXL (865)',
                        key: 'height_xxl',
                        icon: '',
                        controls: LeafFactory.getDishwasherControls()
                      },
                      {
                        name: 'Other height',
                        key: 'other',
                        icon: '',
                        controls: LeafFactory.getDishwasherControls()
                      }
                    ]
                  },
                  {
                    name: 'Width: 45 cm',
                    key: 'width_45',
                    icon: '',
                    possibleCabinetsConfigs: [{
                      categories: ['BD1dw-450'],
                      connectConditions: dishwashersConnectConditions,
                      warningConditions: dishwashersWarningConditions
                    }],
                    options: [
                      {
                        name: 'Standard height (820)',
                        key: 'height_standard',
                        icon: '',
                        controls: LeafFactory.getDishwasherControls()
                      },
                      {
                        name: 'XXL (865)',
                        key: 'height_xxl',
                        icon: '',
                        controls: LeafFactory.getDishwasherControls()
                      },
                      {
                        name: 'Other height',
                        key: 'other',
                        icon: '',
                        controls: LeafFactory.getDishwasherControls()
                      }
                    ]
                  }
                ]
              },
              {
                name: 'Door-on-door',
                key: 'door_on_door',
                icon: '',
                options: [
                  {
                    name: 'Width: 60 cm',
                    key: 'width_60',
                    icon: '',
                    possibleCabinetsConfigs: [{
                      categories: ['BD1dw-600'],
                      connectConditions: dishwashersConnectConditions,
                      warningConditions: dishwashersWarningConditions
                    }],
                    options: [
                      {
                        name: 'Standard height (820)',
                        key: 'height_standard',
                        icon: '',
                        controls: LeafFactory.getDishwasherControls()
                      },
                      {
                        name: 'XXL (865)',
                        icon: '',
                        key: 'height_xxl',
                        controls: LeafFactory.getDishwasherControls()
                      },
                      {
                        name: 'Other height',
                        icon: '',
                        key: 'other',
                        controls: LeafFactory.getDishwasherControls()
                      }
                    ]
                  },
                  {
                    name: 'Width: 45 cm',
                    key: 'width_45',
                    icon: '',
                    possibleCabinetsConfigs: [{
                      categories: ['BD1dw-450'],
                      connectConditions: dishwashersConnectConditions,
                      warningConditions: dishwashersWarningConditions
                    }],
                    options: [
                      {
                        name: 'Standard height (820)',
                        key: 'height_standard',
                        icon: '',
                        controls: LeafFactory.getDishwasherControls()
                      },
                      {
                        name: 'XXL (865)',
                        key: 'height_xxl',
                        icon: '',
                        controls: LeafFactory.getDishwasherControls()
                      },
                      {
                        name: 'Other height',
                        key: 'other',
                        icon: '',
                        controls: LeafFactory.getDishwasherControls()
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        name: 'Fridge',
        key: 'fridge',
        icon: 'fridge.png',
        type: 'refrigerator',
        options: [
          {
            name: 'Free standing',
            key: 'free_standing',
            icon: '',
            possibleCabinetsConfigs: [{
              categories: ['TNd'],
              connectConditions: coolersInTndConnectConditions,
              warningConditions: coolersInTndWarningConditions
            }],
            options: [
              {
                name: 'Width: 600 mm',
                key: 'width_60',
                icon: '',
                controls: LeafFactory.getFridgeFreezerControls()
              },
              {
                name: 'Width: 700 mm',
                key: 'width_70',
                icon: '',
                controls: LeafFactory.getFridgeFreezerControls()
              },
              {
                name: 'Width: 900 mm',
                key: 'width_90',
                icon: '',
                controls: LeafFactory.getFridgeFreezerControls()
              },
              {
                name: 'Width: 1000 mm',
                key: 'width_100',
                icon: '',
                controls: LeafFactory.getFridgeFreezerControls()
              },
              {
                name: 'Width: 1200 mm',
                key: 'width_120',
                icon: '',
                controls: LeafFactory.getFridgeFreezerControls()
              }
            ]
          },
          {
            name: 'Integrated',
            key: 'integrated',
            icon: '',
            options: [
              {
                name: 'Door-on-Door',
                key: 'door_on_door',
                icon: '',
                controls: LeafFactory.getFridgeFreezerControls(),
                possibleCabinetsConfigs: [
                  {
                    categories: ['TWD1'],
                    connectConditions: coolersDoDInTWDConnectConditions,
                    warningConditions: coolersDoDInTWDWarningConditions
                  },
                  {
                    categories: ['BD1wg'],
                    connectConditions: coolersDoDInBDConnectConditions,
                    warningConditions: coolersDoDInBDWarningConditions
                  }
                ]
              },
              {
                name: 'Sliding doors',
                key: 'sliding_door',
                icon: '',
                controls: LeafFactory.getFridgeFreezerControls(),
                possibleCabinetsConfigs: [
                  {
                    categories: ['TWD1'],
                    connectConditions: coolersSDInTWDConnectConditions,
                    warningConditions: coolersSDInTWDWarningConditions
                  },
                  {
                    categories: ['BD1wg'],
                    connectConditions: coolersSDInBDConnectConditions,
                    warningConditions: coolersSDInBDWarningConditions
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        name: 'Wine fridge',
        key: 'wine_fridge',
        icon: 'wine-fridge.png',
        type: 'wineFridge',
        options: [
          {
            name: 'Free standing',
            key: 'free_standing',
            icon: '',
            controls: LeafFactory.getFridgeFreezerControls(),
            possibleCabinetsConfigs: [{
              categories: ['BWnd'],
              connectConditions: [
                {
                  description: '[Appliance width] should be [cabinet opening width] +/- 60 mm',
                  parameter: 'openingWidth', upperExtra: -5, lowerExtra: -15
                }
              ],
              warningConditions: [
                {
                  description: '[Cabinet opening width] should be [appliance width] + 5-15 mm',
                  parameter: 'openingWidth', upperExtra: -5, lowerExtra: -15
                },
                {
                  description: '[Cabinet opening height] should be [appliance height] + 5-15 mm',
                  parameter: 'openingHeight', upperExtra: -5, lowerExtra: -15
                },
                {
                  description: '[Cabinet depth] should be [appliance depth] + 5 mm or more',
                  parameter: 'depth', upperExtra: -5
                }
              ]
            }]
          },
          {
            name: 'Integrated',
            key: 'integrated',
            icon: '',
            possibleCabinetsConfigs: [{
              categories: ['BD1wg'],
              connectConditions: [],
              warningConditions: []
            }],
            options: [
              {
                name: 'Door-on-Door',
                key: 'door_on_door',
                icon: '',
                controls: LeafFactory.getFridgeFreezerControls(),
                possibleCabinetsConfigs: [{
                  categories: ['BD1wg'],
                  connectConditions: coolersDoDInBDConnectConditions,
                  warningConditions: coolersDoDInBDWarningConditions
                }]
              },
              {
                name: 'Sliding doors',
                key: 'sliding_door',
                icon: '',
                controls: LeafFactory.getFridgeFreezerControls(),
                possibleCabinetsConfigs: [{
                  categories: ['BD1wg'],
                  connectConditions: coolersSDInBDConnectConditions,
                  warningConditions: coolersSDInBDWarningConditions
                }]
              }
            ]
          }
        ]
      },
      {
        name: 'Freezer',
        key: 'freezers',
        icon: 'freezer.png',
        type: 'freezer',
        options: [
          {
            name: 'Free standing',
            key: 'free_standing',
            icon: '',
            possibleCabinetsConfigs: [{
              categories: ['TNd'],
              connectConditions: coolersInTndConnectConditions,
              warningConditions: coolersInTndWarningConditions
            }],
            controls: LeafFactory.getFridgeFreezerControls()
          },
          {
            name: 'Integrated',
            key: 'integrated',
            icon: '',
            options: [
              {
                name: 'Door-on-Door',
                key: 'door_on_door',
                icon: '',
                controls: LeafFactory.getFridgeFreezerControls(),
                possibleCabinetsConfigs: [
                  {
                    categories: ['TWD1'],
                    connectConditions: coolersDoDInTWDConnectConditions,
                    warningConditions: coolersDoDInTWDWarningConditions
                  },
                  {
                    categories: ['BD1wg'],
                    connectConditions: coolersDoDInBDConnectConditions,
                    warningConditions: coolersDoDInBDWarningConditions
                  }
                ]
              },
              {
                name: 'Sliding doors',
                key: 'sliding_door',
                icon: '',
                controls: LeafFactory.getFridgeFreezerControls(),
                possibleCabinetsConfigs: [
                  {
                    categories: ['TWD1'],
                    connectConditions: coolersSDInTWDConnectConditions,
                    warningConditions: coolersSDInTWDWarningConditions
                  },
                  {
                    categories: ['BD1wg'],
                    connectConditions: coolersSDInBDConnectConditions,
                    warningConditions: coolersSDInBDWarningConditions
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        name: 'Combo fridge/freezer',
        key: 'fridge_freezers',
        icon: 'combo-fridge-freezer.png',
        type: 'fridgeFreezer',
        options: [
          {
            name: 'Free standing',
            key: 'free_standing',
            icon: '',
            possibleCabinetsConfigs: [{
              categories: ['TNd'],
              connectConditions: coolersInTndConnectConditions,
              warningConditions: coolersInTndWarningConditions
            }],
            controls: LeafFactory.getFridgeFreezerControls()
          },
          {
            name: 'Integrated',
            key: 'integrated',
            icon: '',
            options: [
              {
                name: 'Door-on-Door',
                key: 'door_on_door',
                icon: '',
                controls: LeafFactory.getFridgeFreezerControls(),
                possibleCabinetsConfigs: [
                  {
                    categories: ['TWDx2'],
                    connectConditions: coolersDoDInTWDConnectConditions,
                    warningConditions: coolersDoDInTWDWarningConditions
                  },
                  {
                    categories: ['TWD2L2'],
                    connectConditions: [{
                      description: '[Appliance width] should be [cabinet opening width] +/- 60 mm',
                      parameter: 'openingWidth', upperExtra: 60, lowerExtra: -60
                    }],
                    warningConditions: [
                      {
                        description: '[Cabinet opening width] should be [appliance width] + 5-15 mm',
                        parameter: 'openingWidth',
                        upperExtra: -5,
                        lowerExtra: -15
                      },
                      {
                        description: '[Cabinet opening height should be [appliance height] + 5 mm or more',
                        parameter: 'openingHeight', upperExtra: -5
                      },
                      {
                        description: '[Cabinet depth] should be [appliance depth] + 30 mm or more',
                        parameter: 'depth', upperExtra: -30
                      }
                    ]
                  }
                ]
              },
              {
                name: 'Sliding doors',
                icon: '',
                key: 'sliding_door',
                controls: LeafFactory.getFridgeFreezerControls(),
                possibleCabinetsConfigs: [{
                  categories: ['TWDx2'],
                  connectConditions: coolersSDInTWDConnectConditions,
                  warningConditions: coolersSDInTWDWarningConditions
                }]
              }
            ]
          }
        ]
      },
      {
        name: 'Accessories and others',
        key: 'accessories_others',
        icon: '',
        controls: LeafFactory.getOtherAccessoriesControls()
      }
    ]
  },
  {
    name: 'Fittings',
    key: 'fittings',
    icon: 'fittings.png',
    type: 'group',
    options: [
      {
        name: 'Handles',
        key: 'handles',
        icon: 'handle.png',
        type: 'handle',
        options: [
          {
            name: 'Brass',
            key: 'brass',
            icon: '',
            controls: LeafFactory.getFittingControls()
          },
          {
            name: 'Chrome / Nickel plated',
            key: 'chrome_nickel',
            icon: '',
            controls: LeafFactory.getFittingControls()
          },
          {
            name: 'Copper',
            key: 'copper',
            icon: '',
            controls: LeafFactory.getFittingControls()
          },
          {
            name: 'Stainless',
            key: 'stainless',
            icon: '',
            controls: LeafFactory.getFittingControls()
          },
          {
            name: 'Antique',
            key: 'antique',
            icon: '',
            controls: LeafFactory.getFittingControls()
          },
          {
            name: 'Wood',
            key: 'wood',
            icon: '',
            controls: LeafFactory.getFittingControls()
          },
          {
            name: 'Leather',
            key: 'leather',
            icon: '',
            controls: LeafFactory.getFittingControls()
          },
          {
            name: 'Black',
            key: 'black',
            icon: '',
            controls: LeafFactory.getFittingControls()
          },
          {
            name: 'White',
            key: 'white',
            icon: '',
            controls: LeafFactory.getFittingControls()
          }
        ]
      },
      {
        name: 'Knobs',
        key: 'knobs',
        icon: 'knobs.png',
        type: 'knobs',
        options: [
          {
            name: 'Brass',
            key: 'brass',
            icon: '',
            controls: LeafFactory.getFittingControls()
          },
          {
            name: 'Chrome / Nickel plated',
            key: 'chrome_nickel',
            icon: '',
            controls: LeafFactory.getFittingControls()
          },
          {
            name: 'Copper',
            key: 'copper',
            icon: '',
            controls: LeafFactory.getFittingControls()
          },
          {
            name: 'Stainless',
            key: 'stainless',
            icon: '',
            controls: LeafFactory.getFittingControls()
          },
          {
            name: 'Antique',
            key: 'antique',
            icon: '',
            controls: LeafFactory.getFittingControls()
          },
          {
            name: 'Wood',
            key: 'wood',
            icon: '',
            controls: LeafFactory.getFittingControls()
          },
          {
            name: 'Leather',
            key: 'leather',
            icon: '',
            controls: LeafFactory.getFittingControls()
          },
          {
            name: 'Black',
            key: 'black',
            icon: '',
            controls: LeafFactory.getFittingControls()
          },
          {
            name: 'White',
            key: 'white',
            icon: '',
            controls: LeafFactory.getFittingControls()
          }
        ]
      },
      {
        name: 'Turning knobs',
        key: 'turning_knobs',
        icon: 'turning-knobs.png',
        type: 'knob',
        options: [
          {
            name: 'Knob',
            key: 'knob',
            icon: '',
            options: [
              {
                name: 'Brass',
                key: 'brass',
                icon: '',
                controls: LeafFactory.getFittingControls()
              },
              {
                name: 'Chrome / Nickel plated',
                key: 'chrome',
                icon: '',
                controls: LeafFactory.getFittingControls()
              }
            ]
          },
          {
            name: 'Hook',
            key: 'hooks',
            icon: '',
            options: [
              {
                name: 'For overlay door (e.g. Högalid)',
                key: 'overlay_door',
                icon: '',
                options: [
                  {
                    name: 'Brass',
                    key: 'brass',
                    icon: '',
                    controls: LeafFactory.getFittingControls()
                  },
                  {
                    name: 'Chrome / Nickel plated',
                    key: 'chrome',
                    icon: '',
                    controls: LeafFactory.getFittingControls()
                  }
                ]
              },
              {
                name: 'For inset door (e.g. Djupadal)',
                key: 'inset_door',
                icon: '',
                options: [
                  {
                    name: 'Brass',
                    key: 'brass',
                    icon: '',
                    controls: LeafFactory.getFittingControls()
                  },
                  {
                    name: 'Chrome / Nickel plated',
                    key: 'chrome',
                    icon: '',
                    controls: LeafFactory.getFittingControls()
                  }
                ]
              }
            ]
          },
          {
            name: 'Knob & hook',
            icon: '',
            key: 'knob_hook',
            options: [
              {
                name: 'For inset door (e.g. Djupadal)',
                key: 'inset_door',
                icon: '',
                options: [
                  {
                    name: 'Small',
                    key: 'small',
                    icon: '',
                    options: [
                      {
                        name: 'Brass',
                        key: 'brass',
                        icon: '',
                        controls: LeafFactory.getFittingControls()
                      },
                      {
                        name: 'Chrome / Nickel plated',
                        key: 'chrome',
                        icon: '',
                        controls: LeafFactory.getFittingControls()
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        name: 'Accessories and others',
        key: 'accessories_others',
        type: 'fittings',
        icon: '',
        controls: LeafFactory.getOtherAccessoriesControls()
      }
    ]
  },
  {
    name: 'Sink & Faucets',
    key: 'sink_faucet',
    icon: 'sinks-and-faucets.png',
    type: 'group',
    options: [
      {
        name: 'Sink',
        key: 'sink',
        icon: 'sink.png',
        type: 'sink',
        options: [
          {
            name: 'Inset',
            key: 'sink_inset',
            icon: '',
            possibleCabinetsConfigs: [{
              categories: ['BSP1L1', 'BSP2L2'],
              connectConditions: sinkInsetConnectConditions,
              warningConditions: sinkInsetWarningConditions
            }],
            options: [
              {
                name: 'Stainless',
                key: 'stainless',
                icon: '',
                options: [
                  {
                    name: 'Single',
                    key: 'single',
                    icon: '',
                    controls: LeafFactory.getSinkControls()
                  },
                  {
                    name: 'Double',
                    key: 'double',
                    icon: '',
                    controls: LeafFactory.getSinkControls()
                  }
                ]
              },
              {
                name: 'Porcelain',
                key: 'porcelain',
                icon: '',
                options: [
                  {
                    name: 'Single',
                    key: 'single',
                    icon: '',
                    controls: LeafFactory.getSinkControls()
                  },
                  {
                    name: 'Double',
                    key: 'double',
                    icon: '',
                    controls: LeafFactory.getSinkControls()
                  }
                ]
              },
              {
                name: 'Other',
                key: 'other',
                icon: '',
                options: [
                  {
                    name: 'Single',
                    key: 'single',
                    icon: '',
                    controls: LeafFactory.getSinkControls()
                  },
                  {
                    name: 'Double',
                    key: 'double',
                    icon: '',
                    controls: LeafFactory.getSinkControls()
                  }
                ]
              }
            ]
          },
          {
            name: 'Outside',
            key: 'sink_outside',
            icon: '',
            possibleCabinetsConfigs: [{
              categories: ['BSP1L1S40', 'BSP1L1S60', 'BSP2L2S80'],
              connectConditions: sinkOutsideConnectConditions,
              warningConditions: sinkOutsideWarningConditions
            }],
            options: [
              {
                name: 'Stainless',
                key: 'stainless',
                icon: '',
                options: [
                  {
                    name: 'Single',
                    key: 'single',
                    icon: '',
                    controls: LeafFactory.getSinkControls()
                  },
                  {
                    name: 'Double',
                    key: 'double',
                    icon: '',
                    controls: LeafFactory.getSinkControls()
                  }
                ]
              },
              {
                name: 'Porcelain',
                key: 'porcelain',
                icon: '',
                options: [
                  {
                    name: 'Single',
                    key: 'single',
                    icon: '',
                    controls: LeafFactory.getSinkControls()
                  },
                  {
                    name: 'Double',
                    key: 'double',
                    icon: '',
                    controls: LeafFactory.getSinkControls()
                  }
                ]
              },
              {
                name: 'Other',
                key: 'other',
                icon: '',
                options: [
                  {
                    name: 'Single',
                    key: 'single',
                    icon: '',
                    controls: LeafFactory.getSinkControls()
                  },
                  {
                    name: 'Double',
                    key: 'double',
                    icon: '',
                    controls: LeafFactory.getSinkControls()
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        name: 'Faucet',
        key: 'faucet',
        icon: 'faucet.png',
        type: 'faucet',
        options: [
          {
            name: 'Brass',
            key: 'brass',
            icon: '',
            controls: LeafFactory.getFaucetControls()
          },
          {
            name: 'Chrome / Nickel plated',
            key: 'chrome_nickel',
            icon: '',
            controls: LeafFactory.getFaucetControls()
          },
          {
            name: 'Stainless',
            key: 'stainless',
            icon: '',
            controls: LeafFactory.getFaucetControls()
          },
          {
            name: 'Other',
            key: 'other',
            icon: '',
            controls: LeafFactory.getFaucetControls()
          }
        ]
      },
      {
        name: 'Basket strainer',
        key: 'basket_strainer',
        icon: 'basket-strainer.png',
        type: 'basketStrainer',
        options: [
          {
            name: 'Gold',
            key: 'gold',
            icon: '',
            controls: LeafFactory.getBasketStrainerControls()
          },
          {
            name: 'Antique bronze',
            key: 'antique_bronze',
            icon: '',
            controls: LeafFactory.getBasketStrainerControls()
          },
          {
            name: 'Chrome',
            key: 'chrome',
            icon: '',
            controls: LeafFactory.getBasketStrainerControls()
          },
          {
            name: 'Brushed nickel',
            key: 'brushed_nickel',
            icon: '',
            controls: LeafFactory.getBasketStrainerControls()
          }
        ]
      },
      {
        name: 'Accessories and others',
        key: 'accessories_others',
        icon: '',
        controls: LeafFactory.getOtherAccessoriesControls()
      }
    ]
  },
  {
    name: 'Paint',
    key: 'paints',
    icon: 'paint.png',
    type: 'group',
    options: [
      {
        name: 'Linseed paint',
        key: 'linseed',
        icon: 'linseed-paint.png',
        type: 'linseedPaint',
        options: [
          {
            name: 'Kulladal standard',
            key: 'standard',
            icon: '',
            options: [
              {
                name: '1',
                key: '1',
                icon: '',
                controls: LeafFactory.getKdlPaintControls()
              },
              {
                name: '12',
                key: '12',
                icon: '',
                controls: LeafFactory.getKdlPaintControls()
              },
              {
                name: '19',
                key: '19',
                icon: '',
                controls: LeafFactory.getKdlPaintControls()
              },
              {
                name: '25',
                key: '25',
                icon: '',
                controls: LeafFactory.getKdlPaintControls()
              },
              {
                name: '31',
                key: '31',
                icon: '',
                controls: LeafFactory.getKdlPaintControls()
              },
              {
                name: '32',
                key: '32',
                icon: '',
                controls: LeafFactory.getKdlPaintControls()
              },
              {
                name: '34',
                key: '34',
                icon: '',
                controls: LeafFactory.getKdlPaintControls()
              },
              {
                name: '38',
                key: '38',
                icon: '',
                controls: LeafFactory.getKdlPaintControls()
              },
              {
                name: '48',
                key: '48',
                icon: '',
                controls: LeafFactory.getKdlPaintControls()
              },
              {
                name: '58',
                key: '58',
                icon: '',
                controls: LeafFactory.getKdlPaintControls()
              },
              {
                name: '67',
                key: '67',
                icon: '',
                controls: LeafFactory.getKdlPaintControls()
              },
              {
                name: '69',
                key: '69',
                icon: '',
                controls: LeafFactory.getKdlPaintControls()
              },
              {
                name: '86',
                key: '86',
                icon: '',
                controls: LeafFactory.getKdlPaintControls()
              },
              {
                name: '87',
                key: '87',
                icon: '',
                controls: LeafFactory.getKdlPaintControls()
              },
              {
                name: '97',
                key: '97',
                icon: '',
                controls: LeafFactory.getKdlPaintControls()
              },
              {
                name: '100',
                key: '100',
                icon: '',
                controls: LeafFactory.getKdlPaintControls()
              },
              {
                name: '101',
                key: '101',
                icon: '',
                controls: LeafFactory.getKdlPaintControls()
              },
              {
                name: '107',
                key: '107',
                icon: '',
                controls: LeafFactory.getKdlPaintControls()
              },
              {
                name: '111',
                key: '111',
                icon: '',
                controls: LeafFactory.getKdlPaintControls()
              },
              {
                name: '116',
                key: '116',
                icon: '',
                controls: LeafFactory.getKdlPaintControls()
              },
              {
                name: '148',
                key: '148',
                icon: '',
                controls: LeafFactory.getKdlPaintControls()
              },
              {
                name: '151',
                key: '151',
                icon: '',
                controls: LeafFactory.getKdlPaintControls()
              },
              {
                name: 'White',
                key: 'white',
                icon: '',
                controls: LeafFactory.getKdlPaintControls()
              }
            ]
          },
          {
            name: 'Other',
            key: 'other',
            icon: 'other.png',
            controls: LeafFactory.getOtherPaintControls()
          }
        ]
      },
      {
        name: 'Other',
        key: 'other',
        icon: 'other.png',
        type: 'other',
        controls: LeafFactory.getOtherPaintControls()
      }
    ]
  }
]

/**
 * Array of cabinet categories that can have multiple (two identical)
 * appliances connected to them.
 */
export const ApplianceCabinetCategoriesMultiAppliance: string[] = [
  'BD1o', 'BL1o', 'BD1o+PC', 'BL1o+PC', 'TomD1L2', 'TomDx2', 'TNd'
]

/**
 * Array of all cabinet categories that can have appliances connected to them,
 * and the required cabinet options to be active for that category.
 *
 * For example: category starting by "OD" (a regexp) needs MicroAdoption option
 * active -> {categories: [/^OD\w+$/], neededCabinetOptions: ['MicroAdoption']}
 */
export const AppliancePossibleCabinets: IAppliancePossibleCabinet[] = [];
(() => {
  const hasOptions = (option: IAppliancePossibleCabinet) => {
    return AppliancePossibleCabinets
      .some(o =>
        JSON.stringify(o) === JSON.stringify(option))
  }
  const add = (item: IApplianceConfig) => {
    // Add all possible cabinets from this appliance item (tree level)
    item.possibleCabinetsConfigs?.forEach(config => {
      config.categories.forEach(category => {
        const option: IAppliancePossibleCabinet = {
          cat: category,
          neededCabinetOptions: config.neededCabinetOptions
        }
        // Only add to array if "option" is not already added.
        // We compare using JSON.stringify since they are objects.
        if (!hasOptions(option)) {
          AppliancePossibleCabinets.push(option)
        }
      })
    })
    // Then continue going deeper into further levels
    item.options?.forEach(i => add(i))
  }
  ApplianceTrees.forEach(root => add(root))
})()

/**
 * This should be scoped in service but I do not have
 * the time or energy to do just that so I leave it here
 * for now.
 */
export const ApplianceTreeKeysMap = new Map<TApplianceItemKey, string>();
(() => {
  const setItem = (i: IApplianceConfig) => {
    ApplianceTreeKeysMap.set(i.key, i.name)
    if (i.options) {
      i.options.forEach(j => setItem(j))
    }
  }
  ApplianceTrees.forEach(root => setItem(root))
})()
