<mat-checkbox (change)="setOption('assemblyCarry', $event.checked)"
              [checked]="projectForm().assemblyCarry">
  <span kdlI18n label="selectCarryService"></span>
</mat-checkbox>
<mat-checkbox (change)="setOption('assemblyWaste', $event.checked)"
              [checked]="projectForm().assemblyWaste">
  <span kdlI18n label="selectWasteService"></span>
</mat-checkbox>
<mat-checkbox (change)="setOption('assemblyMeasure', $event.checked)"
              [checked]="projectForm().assemblyMeasure">
  <span kdlI18n label="selectMeasureService"></span>
</mat-checkbox>