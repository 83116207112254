/**
 * Creates the Prodboard Cabinets based on the
 * provided prodboard export file and set additional
 * data on the Cabinet based on what has been saved
 * in the project.
 */
import {Injectable} from '@angular/core'
import {forkJoin, Observable, of} from 'rxjs'
import {map, switchMap} from 'rxjs/operators'
import {
  ProductCategory,
  ProductStaticService
} from '../services/product-static.service'
import {IProjectFile} from '../services/project-file-types'
import {IProject} from '../services/project-types'
import {SettingsItemService} from '../services/settings-item.service'
import {ProdboardCabinet} from './cabinet/prodboard-cabinet'
import {ProdboardCabinetFactory} from './prodboard-cabinet-factory'

@Injectable({
  providedIn: 'root'
})

export class ProdboardFactory {

  constructor(
    private settingsItemService: SettingsItemService,
    private productStaticService: ProductStaticService,
    private cabinetFactory: ProdboardCabinetFactory
  ) {
  }

  /**
   * This is only called by the Project Service. Only the project service knows
   * when we have a new fancy prodboard file to process.
   *
   * @param file - A prodboard file
   * @param project
   */
  public createCabinets(file: IProjectFile, project: IProject):
    Observable<ProdboardCabinet[]> {
    // Distill a list of unique product codes
    const codes = this.getAllProductCodes(file)
    // Fork Join 'em, the pipe is activated when all are done.
    // Finally, we will return a list of cabinets, full of fancy
    // options and such.
    // We do the first one first to make sure all products are loaded before
    // we continue with all.
    const firstCode$ = this.productStaticService
      .getPriceLockVersion(codes.shift(), project.priceLockTime)
    return firstCode$.pipe(
      switchMap((f: ProductCategory) => {
        const codes$ = codes.map((code: string) =>
          this.productStaticService
            .getPriceLockVersion(code, project.priceLockTime))
        codes$.push(of(f))
        return forkJoin(codes$)
      }),
      map((res: ProductCategory[]) => {

        // Create a map that we can use for easy retrieval
        const products =
          new Map(res.map(i => [i.pc, i]))
        return file.plan.items
          .map((item) => {
            const code = item.code
            // If we have received stuff from Prodboard that we cannot cover
            // for. This should be recorded in the service that have already
            // called the problem service.
            if (!products.get(code)) {
              return
            }
            // This is immediate, no calls to the internet or so,
            // all data is available.
            return this.cabinetFactory.createCabinet(
              this.settingsItemService,
              products.get(code),
              item,
              file.plan.room,
              project,
            )
          })
          .filter(Boolean) // Remove the nulls/undefined that is the ones
        // from the return undefined above
      })
    )
  }

  private getAllProductCodes(f: IProjectFile): string[] {
    const prodboardCodes = f.plan.items
      .map((item) => item.code)
    return [...new Set(prodboardCodes)].filter(i => i)
  }
}
