import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {BehaviorSubject, Observable} from 'rxjs'
import {filter, first, map, tap} from 'rxjs/operators'
import {environment} from '../../../environments/environment'
import {OpenProjectService} from '../../services/open-project.service'
import {ICustomer, ICustomerProject} from '../customer-types'
import {CustomerProject} from '../model/customer-project.class'
import {
  Customer,
  CustomerDeliveryFields,
  CustomerInvoiceFields
} from '../model/customer.class'

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  /**
   * Any one can listen and get a list
   */
  public customers$: Observable<Customer[]>

  /**
   * Keep internal track of customers and currentCustomer.
   */
  private pCustomers$ = new BehaviorSubject<Customer[]>([])

  constructor(
    private httpClient: HttpClient
  ) {
    this.customers$ = this.pCustomers$.asObservable()
  }

  /**
   * Returns true if invoice address === delivery address.
   * Basically, if all invoice address fields are the same as delivery address
   * fields it means that it is the same address for invoice and delivery.
   */
  public static hasSameInvoiceAddress(customer: ICustomer): boolean {
    // Compare all fields then reduce to one all true or nothing true
    return CustomerDeliveryFields
      .map((field: string, index: number) =>
        customer[field] === customer[CustomerInvoiceFields[index]])
      .reduce((acc: boolean, equal: boolean) => acc && equal, true)
  }

  public static setCountryToCustomer(customer: ICustomer, country: string) {
    // If invoice and delivery addresses are the same then set country in both
    // addresses. If they are different, it will set just delivery.
    if (this.hasSameInvoiceAddress(customer)) {
      customer.invoiceCountry = country
    }
    customer.country = country
  }

  public static archiveCurrentCustomerProject(
    openProjectService: OpenProjectService
  ) {
    openProjectService.customerProject$
      .pipe(
        first(),
        // It needs to be un-archived in order to archive it
        filter(c => !c.archived),
        tap((c) => c.archive())
      )
      .subscribe(() =>
        openProjectService.triggerChanges({customerProjectChange: true}))
  }

  public static unArchiveCurrentCustomerProject(
    openProjectService: OpenProjectService
  ) {
    openProjectService.customerProject$
      .pipe(
        first(),
        // It needs to be archived in order to un-archive it
        filter(c => c.archived),
        tap((c) => c.unArchive())
      )
      .subscribe(() =>
        openProjectService.triggerChanges({customerProjectChange: true}))
  }

  /**
   * Get a _customer project_ from backend by ID. This is called when
   * we open a project (home) or when we want to archive a project.
   *
   * If we do not get one from backend that project is lost and we create
   * a new one. Meaning that you can send bollocks as Id and always get a
   * project back.
   *
   * @param id - The customer project ID
   * @param projectId - Kitchen Project ID
   */
  public getProject(id: string, projectId: string): Observable<CustomerProject> {
    const url = `${environment.productUrl}/customer-projects/${id}/$LATEST`
    return this.httpClient.get<ICustomerProject>(url).pipe(
      map((customerProject: ICustomerProject) => {
        const cp = new CustomerProject(customerProject)
        cp.projectId = projectId
        return cp
      })
    )
  }

  public loadCustomers(): void {
    const url = `${environment.productUrl}/customers`
    this.httpClient.get<ICustomer[]>(url).subscribe({
      next: (customers: ICustomer[]) => {
        const customerList = customers.map((c: ICustomer) => new Customer(c))
        customerList.sort((a: Customer, b: Customer) => b.timeStamp - a.timeStamp)
        this.pCustomers$.next(customerList)
      }
    })
  }

  /**
   * Just get a customer by ID, make sure it is the latest
   * return the Class not the interface
   */
  public get(id: string): Observable<Customer> {
    const url = `${environment.productUrl}/customers/${id}/$LATEST`
    return this.httpClient.get<ICustomer>(url).pipe(
      map((customer: ICustomer) =>
        new Customer(customer || new Customer()))
    )
  }

  /**
   * Create and delete are server operations
   */
  public saveCustomer(customer: Customer): Observable<Customer> {
    let url = `${environment.productUrl}/customers`
    if (customer.id) {
      url += `/${customer.id}`
    }

    return this.httpClient.put<ICustomer>(url, customer).pipe(
      map((c) => new Customer(c)),
      map((updatedCustomer: Customer) => {
        // Update id an version from server.
        customer.id = updatedCustomer.id
        customer.version = updatedCustomer.version
        return customer
      })
    )
  }

  public saveCustomerProject(customerProject: CustomerProject): Observable<CustomerProject> {
    let url = `${environment.productUrl}/customer-projects`
    if (customerProject.id) {
      url += `/${customerProject.id}`
    }
    const saveProject = customerProject.getSaveData()
    return this.httpClient.put<ICustomerProject>(url, saveProject).pipe(
      map(cp => new CustomerProject(cp)),
      map(cp => {
        customerProject.version = cp.version
        customerProject.id = cp.id
        return customerProject
      })
    )
  }

  public delete(customer: Customer): Observable<any> {
    const url = `${environment.productUrl}/customers/${customer.id}`
    return this.httpClient.delete(url).pipe(
      map(() => {
        this.pCustomers$.next(this.pCustomers$.value.filter((c: Customer) => c.id !== customer.id))
      })
    )
  }
}

