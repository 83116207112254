import {Door} from '../door'
import {Filler} from '../filler'
import {FrameWidth} from '../frame-width/frame-width'
import {Lighting} from '../lighting'
import {DOOR_GLASS_DOOR, DOOR_NO_DOOR_SELECTED} from '../model-types'
import {CabinetLayout} from './cabinet-layout'
import {
  DIMMABLE_DRIVERS,
  ILightProduct,
  ILightSharing,
  LedSpot,
  LedStrip,
  LightDriver,
  LightProductsMap,
  NON_DIMMABLE_DRIVERS,
  P972743,
  P973501,
  P973521,
  P973522,
  P973523,
  P973524,
  P973575,
  P973900_41,
  P973906,
  P974263,
  P992613,
  P992615,
  TLightProductNo,
  TLightsType
} from './lights-types'
import {ProdboardCabinet} from './prodboard-cabinet'

const LED_STRIP_POWER_MARGIN_MM = 100

interface ILightingProduct {
  productNo: TLightProductNo
  quantity: number
}

export class CabinetLighting {

  ////////////////////////////////////////////////////////////////
  // Needed Cabinet's data to identify associate lighting to it
  ////////////////////////////////////////////////////////////////
  /**
   * Cabinet's UUID
   */
  public uid: string
  public name: string

  ////////////////////////////////////////////////////////////////
  // Lighting information, both underneath and inside
  ////////////////////////////////////////////////////////////////

  public driverUnderneathSharing: ILightSharing = {reduced: false}
  public driverInsideSharing: ILightSharing = {reduced: false}
  public ledUnderneathSharing: ILightSharing = {reduced: false}

  /**
   * Array of "productNo" & "quantity" pairs for all products used for inside
   * lighting. LED-strips, drivers, extension cords, sensors and control boxes.
   * However, unlike the public variable, it has real quantities for LED strips,
   * with decimals, to be used correctly in power calculations.
   */
  private readonly pInsideLights: ILightingProduct[] = []

  /**
   * Array of "productNo" & "quantity" pairs for all products used for
   * underneath lighting. Spots or LED-strips, drivers and extension cords.
   * However, unlike the public variable, it has real quantities for LED strips,
   * with decimals, to be used correctly in power calculations.
   */
  private readonly pUnderneathLights: ILightingProduct[] = []

  constructor(cabinet: ProdboardCabinet) {
    this.uid = cabinet.uid
    this.name = cabinet.displayName

    // Cabinets that contain fan extractors do not have lighting. Extractors
    // usually have their own lightning
    if (cabinet.whiteGoodsAdaptation === 'ex') {
      return
    }

    cabinet.getActiveOptions<Lighting>('Lighting')
      .forEach((l: Lighting) => {
        if (l.spots) {
          this.checkSpot(cabinet)
        }
        if (l.ledUnderneath) {
          this.checkLedUnderneath([cabinet])
        }
        if (l.ledInside) {
          this.checkLedInside(cabinet)
        }
      })
  }

  /**
   * Map of "productNo" and "quantity" of every product used for underneath
   * lighting. Spots or LED-strips, drivers and extension cords.
   */
  public get underneathLights(): Map<TLightProductNo, number> {
    // Round up every quantity. We cannot have 0.45 LED strips, we need integer
    // numbers. Real quantities are only used here to do power calculations.
    const map = new Map<TLightProductNo, number>()
    this.pUnderneathLights.forEach(product => {
      if (!map.has(product.productNo)) {
        map.set(product.productNo, 0)
      }
      map.set(product.productNo,
        map.get(product.productNo) + Math.ceil(product.quantity))
    })
    return map
  }

  /**
   * Map of "productNo" and "quantity" of every product used for inside
   * lighting. LED-strips, drivers, extension cords, sensors and control boxes.
   */
  public get insideLights(): Map<TLightProductNo, number> {
    // Round up every quantity. We cannot have 0.45 LED strips, we need integer
    // numbers. Real quantities are only used here to do power calculations.
    const map = new Map<TLightProductNo, number>()
    this.pInsideLights.forEach(product => {
      if (!map.has(product.productNo)) {
        map.set(product.productNo, 0)
      }
      map.set(product.productNo,
        map.get(product.productNo) + Math.ceil(product.quantity))
    })
    return map
  }

  private get spots(): LedSpot[] {
    return this.pUnderneathLights
      .filter(product =>
        LightProductsMap[product.productNo].type === 'spot')
      .flatMap((p: ILightingProduct): LedSpot[] => {
        const arrayOfSpots: LedSpot[] = []
        const spotProduct = LightProductsMap[p.productNo] as LedSpot
        for (let i = 0; i < p.quantity; i++) {
          arrayOfSpots.push(spotProduct)
        }
        return arrayOfSpots
      })
  }

  private get hasSpots(): boolean {
    return this.spots.length > 0
  }

  private get ledsUnderneath(): ILightingProduct[] {
    return this.pUnderneathLights
      .filter(product =>
        LightProductsMap[product.productNo].type === 'strip')
  }

  private get hasLedsUnderneath(): boolean {
    return this.ledsUnderneath.length > 0
  }

  private get ledsInside(): ILightingProduct[] {
    return this.pInsideLights
      .filter(product =>
        LightProductsMap[product.productNo].type === 'strip')
  }

  private get hasLedsInside(): boolean {
    return this.ledsInside.length > 0
  }

  public static getCabinetFromLighting(lighting: CabinetLighting, cabinets: ProdboardCabinet[]): ProdboardCabinet {
    return cabinets.find(c => c.uid === lighting.uid)
  }

  public calculateSharing(cabinets: ProdboardCabinet[]): void {
    this.reduceUnderneathLeds(cabinets)
    this.reduceUnderneathLedsDriver(cabinets)
    this.reduceSpotlightsDriver(cabinets)
    this.reduceInsideDriver(cabinets)
  }

  private reduceUnderneathLeds(cabinets: ProdboardCabinet[]) {
    // Get all cabinets that are forming a combined row with this one (cabinet
    // associated to CabinetLighting)
    const cabinetsRow = CabinetLayout.getNeighboursRow(
      CabinetLighting.getCabinetFromLighting(this, cabinets).layout,
      (a, b): boolean => {
        const aCab = CabinetLayout.getCabinetFromLayout(a, cabinets)
        const bCab = CabinetLayout.getCabinetFromLayout(b, cabinets)
        // Cabinets in a row for underneath LEDs must be:
        //  - Wall cabinets
        //  - Same lighting (LED-strips underneath)
        //  - Bottoms aligned
        //  - Combined cabinets
        return a.isWallCabinet && b.isWallCabinet &&
          aCab.lighting.hasLedsUnderneath && bCab.lighting.hasLedsUnderneath &&
          CabinetLayout.checkIfBottomAligned(a, b) &&
          CabinetLayout.checkIfCombined(aCab, bCab)
      })
      .map(l => CabinetLayout.getCabinetFromLayout(l, cabinets))
      // Filter out those cabinets already reduced
      .filter(c => !c.lighting.ledUnderneathSharing.reduced &&
        !c.lighting.driverUnderneathSharing.reduced)

    // If there are less than 2 items in the row, do not reduce anything
    if (cabinetsRow.length < 2) {
      return
    }

    // Add sons (empty for now) and mark "this" lighting as reduced.
    // Both for driver and leds, since combined cabinets in a row share it all
    this.ledUnderneathSharing = {reduced: true, sons: []}

    // We check, cabinet by cabinet, if "this" can handle absorbing its
    // neighbour driver.
    cabinetsRow
      // We will filter "this", as "this" is the cabinet we are configuring.
      .filter(c => c.uid !== this.uid)
      .forEach(c => {
        // Remove all lighting from cabinet, since "this" will have it all.
        c.lighting.pUnderneathLights.splice(0, Infinity)
        // Add parent and mark cabinet as reduced
        c.lighting.ledUnderneathSharing = {
          parent: this,
          reduced: true
        }
        // Add cabinet as son of "this".
        this.ledUnderneathSharing.sons.push(c.lighting)
      })

    // Clear current underneath lights and recalculate all lighting as a row
    // instead of a single cabinet
    this.pUnderneathLights.splice(0, Infinity)
    this.checkLedUnderneath(cabinetsRow)
  }

  private reduceUnderneathLedsDriver(cabinets: ProdboardCabinet[]) {
    // Get all cabinets that are forming a row with this one (cabinet
    // associated to CabinetLighting)
    const cabinetsRow = CabinetLayout.getNeighboursRow(
      CabinetLighting.getCabinetFromLighting(this, cabinets).layout,
      (a, b): boolean => {
        const aCab = CabinetLayout.getCabinetFromLayout(a, cabinets)
        const bCab = CabinetLayout.getCabinetFromLayout(b, cabinets)
        // Cabinets in a row for underneath LEDs must be:
        //  - Wall cabinets
        //  - Same lighting (LED-strips underneath)
        return a.isWallCabinet && b.isWallCabinet &&
          aCab.lighting.hasLedsUnderneath && bCab.lighting.hasLedsUnderneath
      })
      .map(l => CabinetLayout.getCabinetFromLayout(l, cabinets))
      // Filter out those cabinets already reduced
      .filter(c => !c.lighting.ledUnderneathSharing.reduced &&
        !c.lighting.driverUnderneathSharing.reduced)

    // If there are less than 2 items in the row, do not reduce anything
    if (cabinetsRow.length < 2) {
      return
    }

    // Add sons (empty for now) and mark "this" lighting as reduced
    this.driverUnderneathSharing = {reduced: true, sons: []}

    // Gather all led strips from whole neighbour groups
    const totalLeds: ILightingProduct[] = this.ledsUnderneath
    cabinetsRow
      // We will filter "this", as "this" is the cabinet we are configuring.
      .filter(c => c.uid !== this.uid)
      .forEach(c => {
        // Add leds to totalLeds for later power usage calculations
        totalLeds.push(...c.lighting.ledsUnderneath)
        // Remove driver from cabinet
        this.removeDriverNew(c.lighting.pUnderneathLights)
        // Add parent and mark cabinet as reduced
        c.lighting.driverUnderneathSharing = {
          parent: this,
          reduced: true
        }
        // Add cabinet as son of "this".
        this.driverUnderneathSharing.sons.push(c.lighting)
      })

    // Remove current driver before adding a new one
    this.removeDriverNew(this.pUnderneathLights)

    // Add (new) suitable dimmable driver
    const powerUse = this.calculateStripPowerUsage(totalLeds)
    this.addProductToUnderneath(this.getBestDimmableDriver(powerUse), 1)
  }

  private reduceSpotlightsDriver(cabinets: ProdboardCabinet[]) {
    // Get all possible neighbours of this cabinet
    const cabinetsRow = CabinetLayout.getNeighboursRow(
      CabinetLighting.getCabinetFromLighting(this, cabinets).layout,
      (a, b): boolean => {
        const aCab = CabinetLayout.getCabinetFromLayout(a, cabinets)
        const bCab = CabinetLayout.getCabinetFromLayout(b, cabinets)
        // Cabinets in a row for underneath LEDs must be:
        //  - Wall cabinets
        //  - Same lighting (spotlights underneath)
        return a.isWallCabinet && b.isWallCabinet &&
          aCab.lighting.hasSpots && bCab.lighting.hasSpots
      })
      .map(l => CabinetLayout.getCabinetFromLayout(l, cabinets))
      // Filter out those cabinets already reduced
      .filter(c => !c.lighting.driverInsideSharing.reduced)

    // If there are less than 2 items in the row, do not reduce anything
    if (cabinetsRow.length < 2) {
      return
    }

    // Add sons (empty for now) and mark "this" lighting as reduced
    this.driverUnderneathSharing = {reduced: true, sons: []}

    // We check, cabinet by cabinet, if "this" can handle absorbing its
    // neighbour driver.
    const totalSpots: LedSpot[] = this.spots
    cabinetsRow
      // We will filter "this", as "this" is the cabinet we are configuring.
      .filter(c => c.uid !== this.uid)
      .forEach(c => {
        // There can only be 12 spotlights max per shared driver
        if (totalSpots.length + c.lighting.spots.length <= 12) {
          // Add spots to totalSpots for later power usage calculations
          totalSpots.push(...c.lighting.spots)
          // Remove driver and possible extension (plinth) from cabinet
          this.removeDriverNew(c.lighting.pUnderneathLights)
          // Add parent and mark cabinet as reduced
          c.lighting.driverUnderneathSharing = {
            parent: this,
            reduced: true
          }
          // Add cabinet as son of "this".
          this.driverUnderneathSharing.sons.push(c.lighting)
        }
      })

    // Remove current driver before adding a new one
    this.removeDriverNew(this.pUnderneathLights)

    // Add (new) suitable dimmable driver
    const powerUse = this.calculateSpotPowerUsage(totalSpots)
    this.addProductToUnderneath(this.getBestDimmableDriver(powerUse), 1)

    // Add extension (plinth) if "this" lighting after reduction is powering
    // more than 6 spots.
    if (totalSpots.length > 6) {
      this.addProductToUnderneath(P992613, 1)
    }
  }

  private reduceInsideDriver(cabinets: ProdboardCabinet[]) {
    const thisCabinet = CabinetLighting.getCabinetFromLighting(this, cabinets)
    // Get all possible neighbours of this cabinet
    const cabinetsRow = CabinetLayout.getNeighboursRow(
      thisCabinet.layout,
      (a, b): boolean => {
        const aCab = CabinetLayout.getCabinetFromLayout(a, cabinets)
        const bCab = CabinetLayout.getCabinetFromLayout(b, cabinets)
        // Cabinets in a row for underneath LEDs must be:
        //  - Wall cabinets
        //  - Same lighting (LED-strips inside)
        //  - Same type of doors (standard doors)
        return a.isWallCabinet && b.isWallCabinet &&
          aCab.lighting.hasLedsInside && bCab.lighting.hasLedsInside &&
          (aCab.lighting.haveStandardDoors(aCab) ===
            bCab.lighting.haveStandardDoors(bCab))
      })
      .map(l => CabinetLayout.getCabinetFromLayout(l, cabinets))
      // Filter out those cabinets already reduced
      .filter(c => !c.lighting.driverInsideSharing.reduced)

    // If there are less than 2 items in the row, do not reduce anything
    if (cabinetsRow.length < 2) {
      return
    }

    // Add sons (empty for now) and mark "this" lighting as reduced
    this.driverInsideSharing = {reduced: true, sons: []}

    // Gather all led strips from whole neighbour groups
    const totalLeds: ILightingProduct[] = this.ledsInside
    cabinetsRow
      // We will filter "this", as "this" is the cabinet we are configuring.
      .filter(c => c.uid !== this.uid)
      .forEach(c => {
        // Add leds to totalLeds for later power usage calculations
        totalLeds.push(...c.lighting.ledsInside)
        // Remove driver from cabinet
        this.removeDriverNew(c.lighting.pInsideLights)
        // Add cabinet as son of "this".
        this.driverInsideSharing.sons.push(c.lighting)
        // Add parent and mark cabinet as reduced
        c.lighting.driverInsideSharing = {
          parent: this,
          reduced: true
        }
      })

    // Remove current driver before adding a new one
    this.removeDriverNew(this.pInsideLights)

    // Add (new) suitable driver:
    // non-dimmable for standard-door and dimmable for others
    const powerUse = this.calculateStripPowerUsage(totalLeds)
    if (this.haveStandardDoors(thisCabinet)) {
      this.addProductToInside(this.getBestDriver(powerUse), 1)
    } else {
      this.addProductToInside(this.getBestDimmableDriver(powerUse), 1)
    }
  }

  /**
   * Set the spotlights. LED-spots are calculated either by width if it is a
   * shelf (cabinet category 'OSH') or by the amount of door in other cabinets.
   *
   * There are 3 intervals for shelves height:
   *  [0-600]: 1x 972743 (LED-spotlight)
   *  [601-1600]: 2x 972743 (LED-spotlight)
   *  [> 1600]: 4x 972743 (LED-spotlight)
   *
   * In case is not a shelf, there will be as many LED-spotlights as doors
   * columns. There could be 1, 2 or 3 columns.
   */
  private checkSpot(cabinet: ProdboardCabinet): void {
    // If cabinet is a shelf, then spots are selected with a map
    if (cabinet.cat === 'OSH') {
      // If width is bigger than map's key, it will have map's value of spots.
      const map: Record<number, number> = {
        1600: 4,
        600: 2,
        0: 1
      }
      const spots: number = Object.keys(map)
        // Get all those that cabinet's width is bigger than
        .filter((limit: string) => this.getWidth(cabinet) > +limit)
        // Map to get number of spots
        .map(limit => map[limit])
        // Sort by biggest and get first (the biggest)
        .sort((a, b) => b - a)[0]
      // Add spotlights
      this.addProductToUnderneath(P972743, spots)
      // Add as many extension cords as spotlights
      this.addProductToUnderneath(P992615, spots)
    } else {
      // If not, then spots are as many as door columns
      const doorColumns = this.getDoorColumns(cabinet)
      // Add spotlights
      this.addProductToUnderneath(P972743, doorColumns)
      // Add as many extension cords as spotlights
      this.addProductToUnderneath(P992615, doorColumns)
    }

    // Add a suitable dimmable driver
    const powerUse = this.calculateUnderneathPower()
    this.addProductToUnderneath(this.getBestDimmableDriver(powerUse), 1)
  }

  /**
   * Underneath LED-strips are calculated based on cabinet's width only.
   * There are two different LED-strips: 1m or 2m. With a combination of these
   * two, we will calculate how many we need to fulfill the needs of cabinet's
   * width.
   */
  private checkLedUnderneath(cabinets: ProdboardCabinet[]): void {
    // Width is all cabinets width added up.
    const width = cabinets.reduce(
      (acc, c) => this.getWidth(c) + acc, 0)

    // Add 0.5 profile and 0.5 diffusor per 1000mm (ceiling result).
    const interval = Math.ceil(width / 1000)
    this.addProductToUnderneath(P973501, interval * 0.5)
    this.addProductToUnderneath(P973575, interval * 0.5)

    // Add LED-strips correspondent to cabinet's width:
    let remainingWidth: number = width
    let totalStrips: number = 1
    // 1. First we get as many 2m long LED-strips as possible
    while (remainingWidth > 2000) {
      // Add 2m 19,2W/m LED-strip
      this.addProductToUnderneath(P973522, 1)
      remainingWidth -= 2000
      totalStrips++
    }
    // 2. Then we decide if for last remaining width we need a 1m or a 2m
    // LED-strip
    if (remainingWidth < 1000) {
      // Add 1m 19,2W/m LED-strip
      this.addProductToUnderneath(P973521, remainingWidth / 1000)
    } else {
      // Add 2m 19,2W/m LED-strip
      this.addProductToUnderneath(P973522, remainingWidth / 2000)
    }

    // Add as many extension cords as LED-strips (doesn't matter their power)
    this.addProductToUnderneath(P992615, totalStrips)

    // And the appropriate driver
    const powerUse = this.calculateUnderneathPower()
    this.addProductToUnderneath(this.getBestDimmableDriver(powerUse), 1)
  }

  /**
   * Inside LED-strips are calculated based on cabinet's doors and their height,
   * because lights are places vertically right next to the door, illuminating
   * the inside of the cabinet. This door height depends on door rows on the
   * cabinet.
   *
   * All cabinets use LED-strips of 9,6W/m, except cabinets taller than 2000mm
   * (usually corner cabinets like TD1C-tall) which will use LED-strips of
   * 19,2W/m.
   */
  private checkLedInside(cabinet: ProdboardCabinet): void {
    // Height is inside-space-height of the cabinet
    const height = this.getHeight(cabinet)
    let doorHeight = this.getDoorHeight(cabinet)
    // Sometimes, if cabinets don't have doors, like those with drawers
    // instead, are getting errors. So better rule them pout now.
    if (Number.isNaN(doorHeight)) {
      return
    }

    // Calculate door factor (number of doors per cabinet). Special cases apply:
    // - For corner tall cabinets, we double all illumination products because
    // there will be two lines of LEDs, one on each side of the door, to
    // properly light the inside of the wardrobe.
    let doorFactor = cabinet.numberOfDoors * (cabinet.isPantry ? 2 : 1)
    // - For cabinets with "double doors" we halve the number of illumination
    // products and instead double the length of each LED strip to ensure even
    // lighting coverage.
    if (cabinet.cat.includes('x2')) {
      doorFactor /= 2
      doorHeight *= 2
    }

    // Add 0.5 profile and 0.5 diffusor per 1000mm per door (ceiling result)
    const interval = Math.ceil(doorHeight / 1000)
    this.addProductToInside(P973501, interval * 0.5 * doorFactor)
    this.addProductToInside(P973575, interval * 0.5 * doorFactor)

    // Add LED-strips correspondent to cabinet's door height:
    const strip1m = height > 2000 ? P973521 : P973523
    const strip2m = height > 2000 ? P973522 : P973524
    let remainingHeight: number = doorHeight
    let totalStrips: number = 1
    // 1. First we get as many 2m long LED-strips as possible
    while (remainingHeight > 2000) {
      // Add 2m LED-strip
      this.addProductToInside(strip2m, doorFactor)
      remainingHeight -= 2000
      totalStrips++
    }
    // 2. Then we decide if for last remaining width we need a 1m or a 2m
    // LED-strip (add as many as "doorFactor" individually)
    for (let i = 0; i < doorFactor; i++) {
      if (remainingHeight < 1000) {
        // Add 1m LED-strip
        this.addProductToInside(strip1m, remainingHeight / 1000)
      } else {
        // Add 2m LED-strip
        this.addProductToInside(strip2m, remainingHeight / 2000)
      }
    }

    // Add as many extension cords as LED-strips (doesn't matter their size)
    this.addProductToInside(P992615, totalStrips)

    // Two different cases now for driver and sensors: cabinet w/ standard door,
    // or cabinet w/o door or cristal door
    const totalPowerUse = this.calculateStripPowerUsage(this.ledsInside)
    if (this.haveStandardDoors(cabinet)) {
      // Add as many sensors as doors. However, if cabinet only has one door,
      // it will use one "dotti sensor" instead. Real logic explained by KM:
      //  - "Dotti" is only used when the cabinet has one door. Never if
      //  the cabinet has 2 or more doors.
      //  - If there are 2 or more doors, then "Control box" and "sensor" are
      //  used.
      //  - "Control box" is always combined with "Sensor".
      //  - The qty of "sensors" are always the same as the quantity of doors
      //  in the cabinet
      //  - One "Control box" can drive up to 3 "sensors". So if the cabinet
      //  has more than 3 doors, then 2 control boxes are needed.
      this.addProductToInside(
        cabinet.numberOfDoors === 1 ? P974263 : P973906,
        cabinet.numberOfDoors)

      // Add 1 control boxes every 3 doors (rounding up of course)
      // (add them if cabinet has more than 1 door)
      if (cabinet.numberOfDoors > 1) {
        this.addProductToInside(P973900_41,
          Math.ceil(cabinet.numberOfDoors / 3))
      }

      // Add a suitable non-dimmable driver
      this.addProductToInside(this.getBestDriver(totalPowerUse), 1)
    } else {
      // Add a suitable dimmable driver
      this.addProductToInside(this.getBestDimmableDriver(totalPowerUse), 1)
    }
  }

  /**
   * Adds a product No with its quantity to "underneath lights" array.
   */
  private addProductToUnderneath(product: ILightProduct, quantity: number): void {
    this.pUnderneathLights.push({productNo: product.productNo, quantity})
  }

  /**
   * Adds a product No with its quantity to "inside lights" array.
   */
  private addProductToInside(product: ILightProduct, quantity: number): void {
    this.pInsideLights.push({productNo: product.productNo, quantity})
  }

  /**
   * Width usable for lights, which will be used to calculate how many LEDs
   * are needed underneath the cabinet (either spotlights or LED-strips).
   * Value is cabinet's width minus the filler's (aka recess) left and right.
   */
  private getWidth(cabinet: ProdboardCabinet): number {
    let width = cabinet.dimensions.x
    cabinet.getOption<Filler>('Filler').forEach(f => {
      width = width - f.left - f.right
    })
    // There is a 40mm reduce "just because". KM orders.
    return width - 40
  }

  /**
   * Height usable for lights, which will be used to calculate how many LEDs
   * are needed inside the cabinet.
   * Value is cabinet's height minus the filler's (aka recess) bottom and top.
   */
  private getHeight(cabinet: ProdboardCabinet): number {
    let height = cabinet.actualHeight
    cabinet.getOption<FrameWidth>('Filler').forEach(f => {
      height = height - f.top - f.bottom
    })
    // There is a 40mm reduce "just because". KM orders.
    return height - 40
  }

  /**
   * We must check if we have standard doors, if so, then we should
   * add sensors and control boxes.
   */
  private haveStandardDoors(cabinet: ProdboardCabinet): boolean {
    // "it has standard doors" if ALL doors are not glass or no-door.
    return !cabinet.getOption<Door>('Door')
      .some(d =>
        [DOOR_GLASS_DOOR, DOOR_NO_DOOR_SELECTED].includes(d.typeOfDoor()))
  }

  /**
   * Gets all light products by type from a map of product numbers. An easy
   * way to relate productNo to its product object.
   */
  private getProductsByType<T>(type: TLightsType, map: Map<TLightProductNo, number>): T[] {
    const result: ILightProduct[] = []
    map.forEach((quantity: number, productNo: TLightProductNo) => {
      const product: ILightProduct = LightProductsMap[productNo]
      if (product.type === type && quantity) {
        for (let i = 0; i < quantity; i++) {
          result.push(product)
        }
      }
    })
    return result as T[]
  }

  /**
   * Calculates power required by underneath lights. Theoretically, both
   * spotlights and LED-strips can be there, but in reality it will be one or
   * the other. We add both up just in case. It simplified "if:s" in the code.
   */
  private calculateUnderneathPower(): number {
    return this.calculateStripPowerUsage(this.ledsUnderneath) +
      this.calculateSpotPowerUsage(this.spots)
  }

  /**
   * Calculate power required by a group of LED-strips
   */
  private calculateStripPowerUsage(strips: ILightingProduct[]): number {
    let totalPower = 0
    strips.forEach((product) => {
      const s: LedStrip = LightProductsMap[product.productNo] as LedStrip
      // When calculating strip power we always add a little margin.
      // Make sure to make power calculations using meters, not millimeters.
      totalPower += s.power * product.quantity *
        (s.length + (LED_STRIP_POWER_MARGIN_MM / 1000))
    })
    return totalPower
  }

  /**
   * Calculate power required by a group of spotlights
   */
  private calculateSpotPowerUsage(spots: LedSpot[]): number {
    return spots
      .map((s: LedSpot) => s.power)
      .reduce((acc: number, power: number) => acc + power, 0)
  }

  /**
   * Get the best driver money can buy for dimmable lights.
   * This works fine for spots and LED-strips underneath.
   */
  private getBestDimmableDriver(power: number): LightDriver | undefined {
    // If power is >100W it will return undefined driver. This is not OK, but
    // IRL it is very, very rare, so it is ok-ish.
    return DIMMABLE_DRIVERS
      .filter((d: LightDriver) => d.power >= Math.ceil(power))[0]
  }

  /**
   * Get the best driver money can buy for non-dimmable lights.
   * This works fine for LEDs inside
   */
  private getBestDriver(power: number): LightDriver | undefined {
    // If power is >150W it will return undefined driver. This is not OK, but
    // IRL it is very, very rare, so it is ok-ish.
    return NON_DIMMABLE_DRIVERS
      .filter((d: LightDriver) => d.power >= Math.ceil(power))[0]
  }

  private removeDriver(lightsMap: Map<TLightProductNo, number>): void {
    [...DIMMABLE_DRIVERS, ...NON_DIMMABLE_DRIVERS, P992613]
      .forEach((product: ILightProduct) => lightsMap.delete(product.productNo))
  }

  private removeDriverNew(lightsArray: ILightingProduct[]): void {
    const productNosToRemove: TLightProductNo[] =
      [...DIMMABLE_DRIVERS, ...NON_DIMMABLE_DRIVERS, P992613]
        .map(p => p.productNo)

    // Remove all products we want (drivers and plinths), modifying the
    // original array
    while (lightsArray.some(product =>
      productNosToRemove.includes(product.productNo))) {
      lightsArray.splice(lightsArray.findIndex(p =>
        productNosToRemove.includes(p.productNo)), 1)
    }
  }

  /**
   * This maps the number of doors to "columns"?
   *
   * The problem here is that 2 can be either horizontal => 2, or
   * vertical => 1
   *
   * X.X or X           X.X.X = 3 but X.X.X.X does not exist have to be X.X
   *        X                                                           X.X
   *                                                      which => 2 columns
   * x.x.x
   * x.x.x = 6 doors, 3x columns
   *
   * X
   * X
   * X does not exist either! No cabinet has three doors on top. That
   * is accomplished by putting one on top of another.
   */
  private getDoorColumns(cabinet: ProdboardCabinet): number {
    if (cabinet.numberOfDoors !== 2) {
      // All simple cases mapped as nº of doors to nº of columns
      // In case there are 2 doors, we check it later.
      const doorCountColumnsMap: Record<number, number> = {
        1: 1,
        3: 3,
        4: 2,
        6: 3
      }
      return doorCountColumnsMap[cabinet.numberOfDoors]
    }

    // We "think" that x2 in the cat string is the indicator for 1 column
    // OD2x2 = 2, two doors in x2 config
    return cabinet.cat.indexOf('x2') === -1 ? 2 : 1
  }

  /**
   * Gets door height based on cabinet's height, number of columns and number
   * of doors.
   */
  private getDoorHeight(cabinet: ProdboardCabinet): number {
    // 1 door => 1 column -> 1/1 => 1 row
    // 2 doors side by side => 2 columns full height -> 2/2 = 1 row
    // 2 doors on top => 1 column -> 2/1 = 2 rows
    // 3 doors => 3 columns => 3/3 = 1 row
    // 4 doors => 2 columns = 4/2 = 2 rows
    // 6 doors => 3 columns => 6/3 = 2 rows
    // For now, we say that the doors have equal height so divide by rows.
    // This might change in the future, Tall cabinets!

    const rows = cabinet.numberOfDoors * (1 / this.getDoorColumns(cabinet))
    return this.getHeight(cabinet) / rows
  }
}
