/**
 * this is the Angular 6 way of getting access to the window.
 *
 * Admittedly, I do not understand shit of this!
 *
 * However since we use "window" to change the location and
 * we to be able to mock it so that it does not actually navigate
 * in code when called we need to "inject" window into the application.
 *
 */
import {FactoryProvider, InjectionToken} from '@angular/core'

/**
 * Defines and exports an injection token that replaces the browser
 * window object.
 */
export const WINDOW = new InjectionToken<Window>('window')

export const DOCUMENT = new InjectionToken<Document>('document')

/**
 * The provider
 */
const windowProvider: FactoryProvider = {
  provide: WINDOW,
  useFactory: () => window
}

/**
 * The exported provider(s). We only have one. This is "provided"
 * in app.component.
 */
export const WINDOW_PROVIDERS = [
  windowProvider
]
