import {Component, inject} from '@angular/core'
import {filter, first, switchMap} from 'rxjs/operators'
import {Comment} from '../../comments/model/comment'
import {FactoryCabinet} from '../../factory-internal/helpers/factory-cabinet'
import {CabinetOption, TOptionSelectName} from '../../model/cabinet-option'
import {ProdboardCabinet} from '../../model/cabinet/prodboard-cabinet'
import {SummaryProperties} from '../../model/project-option/project-option'
import {OpenProjectService} from '../../services/open-project.service'
import {IProject} from '../../services/project-types'
import {MatIcon} from '@angular/material/icon'
import {MatIconButton} from '@angular/material/button'
import {I18nModule} from '../../i18n/i18n.module'
import {DOCUMENT} from '../../application/window.provider'

@Component({
  selector: 'kdl-cad',
  templateUrl: './cad.component.html',
  styleUrls: ['./cad.component.scss'],
  standalone: true,
  imports: [
    MatIcon,
    MatIconButton,
    I18nModule
  ],
  providers: [
    {provide: DOCUMENT, useValue: document}
  ]
})
export class CadComponent {

  private readonly rowItems = [
    'Cabinet number',
    'Module name',
    'Description',
    'IMPORTANT NOTES',
    'Extra',
    '(A) TOTAL WIDTH (mm)',
    '(B) FRAME LEFT width (mm)',
    '(C) Recess left',
    '(D) FRAME RIGHT width (mm)',
    '(E) Recess right',
    'Leg? options:"yes right side" "yes left side" "yes both sides"',
    'MId-post? options: "standard mid-post" "N.B mid-post fixed to door" "N.B. no mid-post"',
    '(F) HOLE WIDTH (for door/drawer) (mm)',
    '(G) Height (mm)',
    '(H) Top frame',
    '(I) Bottom frame',
    '(J) Door Height',
    '(Hinge Location) Left/Right/Both/Top',
    '(K) Depth (mm)',
    '(L) Recess top',
    '(M) Recess bottom',
    'Number of shelves',
    'Color',
    'Endpanels',
    '"Console" decoration on end panel',
    '"Curve" in the bottom corners',
    'Chopping board',
    'Backpanel',
    'Cornice',
    'Lights',
    'Cutlery dividers',
    'Knife block',
    'Wavy block',
    '"Hidden" internal drawer',
    'Skirting',
    'Door Type',
    'Painted inside of carcass',
    'Built-in oven?',
    'Built-in microwave?',
    'Built-in hood',
    'Drawer 1 Height',
    'Drawer 2 Height',
    'Drawer 3 Height',
    'Drawer 4 Height',
    'Drawer5 Height',
    'Types of Hinges',
    '(P) Height facade frame',
    '(G) Height carcass',
    'X', // This is the dimensions of the cabinet
    'Y',
    'Z',
    'pX', // These represent to location of the cabinet. Note it is the center
    'pY',
    'pZ',
    'combined left',
    'combined top',
    'combined right',
    'combined bottom'
  ]

  private readonly DOCUMENT = inject(DOCUMENT)
  private readonly openProjectService = inject(OpenProjectService)

  public save(): void {
    let project: IProject
    this.openProjectService.project$
      .pipe(
        filter(Boolean),
        first(),
        switchMap((p: IProject) => {
          project = p
          return this.openProjectService.cabinets$
        }),
        filter((cabinets: ProdboardCabinet[]) => cabinets && cabinets.length > 0),
        first()
      )
      .subscribe({
        next: (cabinets: ProdboardCabinet[]) => {
          const rows: string[] = cabinets.map((cab: ProdboardCabinet) => {
            // This is legacy and should be removed in the future
            // TODO - Check if this is still necessary
            if ((cab.position) as any === 'REMOVED') {
              cab.position = {
                direction: {x: 0, y: 0, z: 0},
                center: {x: 0, y: 0, z: 0}
              }
            }
            const optionMap = this.getOptionValues(cab.options)
            const factoryCabinet = new FactoryCabinet(cab)
            const row = []
            this.addProperty(row, cab.index) // Cabinet number // A
            this.addProperty(row, cab.cat) // Module Name // B
            this.addProperty(row, cab.description) // Description from product // C
            this.addProperty(row, factoryCabinet.importantNotes.join(' xx_xx ')) // IMPORTANT NOTES // D
            this.addProperty(row, 'Extra') // E, unclear what this is
            this.addProperty(row, factoryCabinet.a) // F
            this.addProperty(row, factoryCabinet.b) // G
            this.addProperty(row, factoryCabinet.c) // H
            this.addProperty(row, factoryCabinet.d) // I
            this.addProperty(row, factoryCabinet.e) // J
            this.addProperty(row, optionMap.Legs) // K Legs are left, right or both
            this.addProperty(row, this.resolveSummary(factoryCabinet.getCenterPost())) // L Known as Midpost in CAD
            this.addProperty(row, factoryCabinet.f) // M
            this.addProperty(row, factoryCabinet.g) // N
            this.addProperty(row, factoryCabinet.h) // O
            this.addProperty(row, factoryCabinet.i) // P
            this.addProperty(row, factoryCabinet.j) // Q (J) = Door height
            this.addProperty(row, optionMap.Hanging) // R Hinged irection
            this.addProperty(row, factoryCabinet.k) // S (K) Depth
            this.addProperty(row, factoryCabinet.l) // T (L) Top Recess
            this.addProperty(row, factoryCabinet.m) // U (K) Bottom recess
            this.addProperty(row, cab.numberOfShelves) // V
            this.addProperty(row, project.form.color)  // W Color
            this.addProperty(row, optionMap.CoverSide) // X Endpanels in CAD
            this.addProperty(row, 'tbd') // Y top console '"Console" decoration on end panel'
            this.addProperty(row, 'tbd') // Z  Curve decorations "Curve" in the bottom corners'
            this.addProperty(row, optionMap.CuttingBoard) // AA "Chopping"
            this.addProperty(row, optionMap.BackPanel) // AB Back panel
            this.addProperty(row, optionMap.Cornice) // AC // Should be yes no.
            this.addProperty(row, optionMap.Lighting) // AD
            this.addProperty(row, optionMap.Cutlery) // AE
            this.addProperty(row, optionMap.Knife) // AF
            this.addProperty(row, optionMap.Wavy) // AG
            this.addProperty(row, optionMap.HiddenDrawer) // AH
            this.addProperty(row, optionMap.Skirt) // AI

            this.addProperty(row, this.resolveSummary(factoryCabinet.getDoors())) // AJ Door Type as in Lucka Sunnanå or Type 3
            this.addProperty(row, optionMap.Paint) // AK 'Painted inside of carcass' // Correct
            this.addProperty(row, cab.isOven) // AL Is oven?
            this.addProperty(row, 'n/a') // AM Microwave not supported
            this.addProperty(row, optionMap.FanAdoption) // AN

            // AM -- AS
            for (let i = 0; i < 5; i++) {
              this.addProperty(row, cab.drawers[i] || '') // Drawer 1 height
            }
            this.addProperty(row, this.resolveSummary(factoryCabinet.getHinges())) // AT
            this.addProperty(row, factoryCabinet.p) // Height facade frame (P) // AU
            this.addProperty(row, factoryCabinet.g) // Height Carcass (G) // AV
            this.addProperty(row, cab.dimensions.x) // AW
            this.addProperty(row, cab.dimensions.y) // AX
            this.addProperty(row, cab.dimensions.z) // AY
            this.addProperty(row, cab.position.center.x) // AZ
            this.addProperty(row, cab.position.center.y) // BA
            this.addProperty(row, cab.position.center.z) // BB
            // Combined
            this.addProperty(row, factoryCabinet.combined.left) // BC
            this.addProperty(row, factoryCabinet.combined.top) // BD
            this.addProperty(row, factoryCabinet.combined.right) // BE
            this.addProperty(row, factoryCabinet.combined.bottom) // BF
            return row.join(',')
          })

          const headerRow = this.rowItems.join(',')
          rows.unshift(headerRow)
          const result = rows.join('\n')
          const taBlob = new Blob([result], {type: 'text/csv;charset=utf-8'})
          const href = URL.createObjectURL(taBlob)
          const el = this.DOCUMENT.createElement('a')
          el.href = href
          el.download = `${project.customer.name}-CAD.csv`
          el.click()
          el.remove()
        }
      })
  }

  private addProperty(row: Array<string | number>, prop: string | boolean | number): void {
    switch (typeof prop) {
      case 'string': {
        // Remove all white space (tabs, \n \r etc.) replace with simple ' '
        // Then replace all ',' with ##_(.)(.)_## to avoid extra semis
        let res = prop.replaceAll(/\s+/g, ' ').trim()
        res = res.replaceAll(/,/g, '| ')
        row.push(res)
        break
      }
      case 'number':
        row.push(prop)
        break
      case 'boolean':
        row.push(prop ? 'yes' : 'no')
        break
      case 'undefined':
      case 'object':
        // Object is the type of null.
        row.push('')
        break
    }
  }

  private getOptionValues(options: CabinetOption[]): { [key: string]: string } {
    const optionMap: Record<string, string> = {} as any
    options
      .filter((option: CabinetOption) => option.active && option.getCustomerListing('f').length > 0)
      .forEach((option: CabinetOption) => {

        switch (option.optionSelectName) {
          case 'Skirting': {
            const valueMap = option.valueMap()
            optionMap['Skirting'] = valueMap.skirt as string
            break
          }
          case 'Legs': {
            const valueMap = option.valueMap()
            optionMap.Legs = valueMap.legs as string
            break
          }
          case 'Door': {
            const valueMap = option.getFactoryData()
            optionMap['Paint'] = valueMap.paintedInside + ''
            break
          }
          case 'DrawerInsert': {
            const valueMap = option.valueMap()
            optionMap['Cutlery'] = valueMap.cutlery as string
            optionMap['Knife'] = valueMap.knife as string
            optionMap['Wavy'] = valueMap.wavy as string
            break
          }
          default:
            this.setDefaultOptionValues(option, optionMap)
        }
      })
    return optionMap
  }

  private setDefaultOptionValues(option: CabinetOption, optionMap: Record<TOptionSelectName, string>): void {
    const opt: string[] = option.getCustomerListing('f')
    const comments = option.comments
      .filter((comment: Comment) => comment.translation)
      .map((comment: Comment) => comment.translation).join('| ')
    if (comments) {
      opt.push(comments)
    }
    optionMap[option.optionSelectName] = opt.join('| ')
  }

  private resolveSummary(sps: SummaryProperties[]): string {
    return sps.map((sp: SummaryProperties) => sp.description).join(``)
  }
}
