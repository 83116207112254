<ng-template #saveButton>
  @if (changed && !saving) {
    <span>
      <span kdlI18n label="saveIn"></span>: {{ autoSaveTimeout - timer }}s
    </span>
  } @else if (!changed && !saving) {
    <span kdlI18n label="save"></span>
  } @else if (saving) {
    <span kdlI18n label="saving"></span>
  }
</ng-template>

@if (showFloater && currentProject?.id) {
  <div
    [class.is-saving]="autoSaveService.saving$ | async"
    class="floating-menu-holder opacity-transition">
    <div class="floating-menu-project-holder">
      <div [routerLink]="['edit-project', 'project', currentProject.id]"
           class="floating-menu-title">
        {{ currentProject.customer?.name }}
      </div>

      <kdl-lock [id]="currentProject.id" class="lock"></kdl-lock>
    </div>

    <div class="floating-menu-actions-holder">
      <button
        (click)="saveProject()"
        [disabled]="!changed || saving"
        color="primary"
        mat-raised-button>
        <mat-icon class="material-icons-outlined" fontIcon="save"></mat-icon>
        <ng-container [ngTemplateOutlet]="saveButton"></ng-container>
      </button>

      <kdl-header-print-buttons [class.is-saving]="changed && !saving"/>

      <div class="links">
        <!-- Open in prodboard -->
        @if (prodboardUrl) {
          <a [href]="prodboardUrl" kdlI18n label="openInProdboard"
             target="_blank">
            Text overridden by i18n label
          </a>
        } @else {
          <div>No URL</div>
        }

        <!-- Upload new JSON file -->
        <a [routerLink]="['prodboard', 'update', currentProject.id]"
           kdlI18n label="selectNewProdboard">
          Text overridden by i18n label
        </a>
      </div>
    </div>

    <div class="floating-menu-price-holder">
      @if (currentProject.pricing) {
        <!-- Pricing will only be shown if country ("lc") is selected -->
        @if (currentProject.form.lc) {
          <a (click)="openPriceDialog()">
            <mat-icon class="material-symbols-outlined"
                      svgIcon="kdl_price_tag"/>
            {{ currentProject.pricing.totalCustomerPrice | number:'1.0-0':'fr' }}
            kr
          </a>
        } @else {
          <a (click)="openSelectCountryDialog()"
             kdlI18n label="selectCountry"
          >Text overridden by i18n label</a>
        }
      }
    </div>
  </div>
}

