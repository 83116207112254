import {TLanguageCode} from '../application/i18n.provider'
import {IProduct} from '../common/interface/product-types'
import {TSettingOptionUseCase} from '../services/settings-item.service'
import {
  CabinetOption,
  TOptionSelectName,
  ViewOptionType
} from './cabinet-option'
import {FactoryData} from './factory/factory-data'
import {DoorMill} from './mill-file/mill-file-types'
import {
  DOOR_GLASS_DOOR,
  DOOR_NO_DOOR_SELECTED,
  DOOR_NO_PAINTED_INSIDE,
  DOOR_PAINTED_INSIDE,
  DOOR_STANDARD_DOOR
} from './model-types'

export class Door extends CabinetOption {
  /**
   * Set this to notify factory if no door/glass door and NOT
   * painted inside
   */
  public paintedInsideNote = ''

  constructor(option: DoorMill, product: IProduct, cabinetId: number) {
    super(product, cabinetId)

    this.priority = 10
    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Lucktyp',
        name: 'doorType',
        values: [DOOR_GLASS_DOOR, DOOR_STANDARD_DOOR, DOOR_NO_DOOR_SELECTED],
        selection: DOOR_STANDARD_DOOR,
        disabled: false
      },
      {
        type: ViewOptionType.binary,
        title: 'Målad insida',
        name: 'paintedInside',
        values: [DOOR_PAINTED_INSIDE, DOOR_NO_PAINTED_INSIDE], // Ja or Nej
        selection: DOOR_NO_PAINTED_INSIDE,
        disabled: false
      }
    ]
    this.active = true // Door is always active b/c if you remove the door you still have a door.

    this.description = 'Different door settings: Glass door, or "no door", etc'
    this.title = 'optDoor'
    this.setValuesFromProdboardData(option)
    this.setPrice()
  }

  get optionSelectName(): TOptionSelectName {
    return 'Door'
  }

  get hasPaintedInside(): boolean {
    return this.viewOptions[1].selection === DOOR_PAINTED_INSIDE
  }

  public getFactoryData(): FactoryData {
    return {
      paintedInside: this.hasPaintedInside
    }
  }

  /**
   * This is called from the outside to update the values of
   * this item based on data stored in the project. The data
   * will match the data we have on the form.
   */
  public update(data: any): void {
    super.update(data)
    this.setFromProperties(data)
    this.setPrice()
  }

  public typeOfDoor(): string {
    return this.viewOptions[0].selection
  }

  public getCustomCustomerListing(
    useCase: TSettingOptionUseCase,
    lc: TLanguageCode
  ): string[] {
    const answer = [this.settingOption.getI18n(this.viewOptions[0].selection, useCase, lc)]
    // Painted insides
    if (this.viewOptions[1].selection === DOOR_PAINTED_INSIDE) {
      answer.push(
        this.settingOption.getI18n(this.viewOptions[1].selection, useCase, lc))
    }
    return answer
  }

  /**
   * This function converts the complex structure from Prodboard to
   * out own complex structure that we use in presentation.
   */
  private setValuesFromProdboardData(option: DoorMill): void {
    this.viewOptions[0].selection = option.value
    this.viewOptions[1].selection = DOOR_NO_PAINTED_INSIDE // We say that you cannot paint inside if you have a door.

    if (option.value === DOOR_NO_DOOR_SELECTED ||
      option.value === DOOR_GLASS_DOOR) {
      this.viewOptions[1].selection = option.paintedInside
    }
    /**
     * If no price for Glass Door, then we cannot have glass doors either.
     * Remove it from the selection list
     */
    if (!this.product.glDoPr.price) {
      this.viewOptions[0].values.splice(0, 1)
    }
  }

  private configureOptions(): void {
    this.paintedInsideNote = ''
    this.viewOptions[1].disabled = false

    if (this.viewOptions[0].selection === DOOR_STANDARD_DOOR) {
      this.viewOptions[1].disabled = true
      this.viewOptions[1].selection = DOOR_NO_PAINTED_INSIDE

    } else if (this.viewOptions[1].selection === DOOR_NO_PAINTED_INSIDE) {
      this.paintedInsideNote = 'Standard waxed insides (NOT painted)'
    }
  }

  /**
   * Note that we calculate prices on OUR model.
   */
  private setPrice(): void {
    this.configureOptions()
    this.resetPrices()

    if (this.typeOfDoor() === DOOR_GLASS_DOOR) {
      this.price = this.product.glDoPr.price
      this.labor = this.product.glDoPr.labor
      this.shouldHavePrice = true
    }

    if (this.typeOfDoor() === DOOR_NO_DOOR_SELECTED) {
      this.price = this.product.noDoPr.price
      this.labor = this.product.noDoPr.labor
    }

    // Painted inside, this ADDS to the price so we do this last
    if (this.hasPaintedInside) {
      this.price += this.product.paInPr.price
      this.labor += this.product.paInPr.labor
      this.shouldHavePrice = true
    }
  }
}
