<div matDialogTitle>Select customer country</div>

<mat-dialog-content>
  <kdl-edit-project
    [show]="['lc']"
  ></kdl-edit-project>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close color="primary"
  >Close
  </button>
</mat-dialog-actions>
