import {Component, DestroyRef, inject, OnInit} from '@angular/core'
import {filter} from 'rxjs/operators'
import {OpenProjectService} from '../../services/open-project.service'
import {IProjectFile} from '../../services/project-file-types'
import {IProject} from '../../services/project-types'
import {AutoSaveComponent} from '../auto-save/auto-save.component'
import {
  PriceSummaryDialogComponent
} from './price-summary-dialog/price-summary-dialog.component'
import {
  SelectCustomerCountryDialogComponent
} from './select-customer-country-dialog/select-customer-country-dialog.component'
import {takeUntilDestroyed} from '@angular/core/rxjs-interop'

@Component({
  selector: 'kdl-floating-menu',
  templateUrl: './floating-menu.component.html',
  styleUrls: ['./floating-menu.component.scss']
})
export class FloatingMenuComponent extends AutoSaveComponent implements OnInit {

  /**
   * Current open project
   */
  currentProject: IProject | null = null

  /**
   * Unless we have project we hide ourselves.
   */
  public showFloater = false

  public prodboardUrl: string

  private readonly destroyRef = inject(DestroyRef)
  private readonly openProjectService = inject(OpenProjectService)

  public ngOnInit(): void {
    super.ngOnInit()

    this.openProjectService.project$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (project: IProject | null) => {
          this.showFloater = project !== null
          this.currentProject = project
        }
      })

    this.openProjectService.projectFile$
      .pipe(takeUntilDestroyed(this.destroyRef), filter(Boolean))
      .subscribe({
        next: (file: IProjectFile) => this.prodboardUrl = file.prodboardUrl
      })
  }

  public saveProject(): void {
    this.autoSaveService.saveChanges()
  }

  public openPriceDialog(): void {
    PriceSummaryDialogComponent.open(this.dialog)
  }

  public openSelectCountryDialog(): void {
    SelectCustomerCountryDialogComponent.open(this.dialog)
  }
}
