import {MillFileTransformer} from '../model/mill-file/mill-file-transformer'
import {IOldProjectFile, IProjectFile} from './project-file-types'

export class ProjectFileUtils {
  public static createNewEmptyProjectFile(): IProjectFile {
    return {
      isMilf: true,
      customer: {
        name: ''
      },
      prodboardNumber: '',
      prodboardId: '',
      prodboardUrl: '',
      plan: {
        items: [],
        room: {
          beams: [],
          dimensions: {
            x: 0, y: 0, z: 0
          }
        }
      },
      type: 'F',
      version: 0,
      timeStamp: Date.now(),
      // ID and project ID will be set once they are created in our API (BE).
      // But properties are set here in FE. Check somewhere in ProjectService.
      id: '',
      projectId: ''
    }
  }

  public static createNewProjectFile(file: string): IProjectFile {
    const millFile = MillFileTransformer.transformProdboardFile(file)
    if (!millFile) {
      throw new Error('Error mapping file - Could not convert uploaded file into MillFile')
    }
    return {
      ...millFile,
      type: 'F',
      version: 0,
      timeStamp: Date.now(),
      // ID and project ID will be set once they are created in our API (BE)
      id: '',
      projectId: ''
    }
  }

  /**
   * Method to be used before working with any file. All files must be fixed!
   * @param file
   * @param currentFile
   */
  public static fixFile(file: IProjectFile | IOldProjectFile, currentFile: IProjectFile | null): IProjectFile {
    // Make sure we always work with IProjectFile data-models, converting
    // those old projects still available.
    // This could be removed if we can ensure that in the whole database there
    // is no project with an old file. But we need to run a script for that,
    // and we might never do that, too risky ^^ - Darío 2024-10-31.
    const fixedFile: IProjectFile = !this.isMillFile(file) ?
      this.convertOldToNew(file as IOldProjectFile) : file as IProjectFile

    // If we have a current file we must copy some values from it:
    // ID, version and prodboard comments (if they are the same)
    if (currentFile) {
      // Copy version and ID to new file.
      fixedFile.version = currentFile.version++
      fixedFile.id = currentFile.id

      // Copy old prodboard comments to new file items.
      fixedFile.plan.items.forEach(newItem => {
        // Find old item that related to the new item (mainly by UUID, but
        // it doesn't have one, weird case, then find by index).
        const oldItem = currentFile.plan.items.find(i => {
          if (!i.uuid) {
            return i.index === newItem.index
          }
          return i.uuid === newItem.uuid
        })
        // If the old item exists and new one doesn't, copy old into new.
        if (oldItem) {
          if (!newItem.prodboardComment) {
            newItem.prodboardComment = {...oldItem.prodboardComment}
          }
          // If both, old and new items, have the same comment, copy "deleted"
          // property from old to new.
          if (oldItem.prodboardComment.comment === newItem.prodboardComment.comment) {
            newItem.prodboardComment.deleted = oldItem.prodboardComment.deleted
          }
        }
      })
    }

    return fixedFile
  }

  /**
   * It basically checks if the file passed here IS NOT a MillFile. To be a
   * Mill File we'll check the specific boolean that is included in every
   * MillFile, "isMilf".
   *
   * If this boolean (which is always true) is present, it is a MillFile
   *
   * @param file file object to check, that can be of both types, old or
   * MillFile.
   */
  private static isMillFile(file: IProjectFile | IOldProjectFile): boolean {
    return (file as IProjectFile).isMilf === true
  }

  /**
   * Method that will convert an old ProdboardFile model to MillFile, the new.
   *
   * Since old ProdboardFile data model is inspired in Prodboard V1 File,
   * almost all parameters are the same. However, there are some properties
   * that have been added "manually" in older versions that we need to
   * maintain. For example, version, ID, prodboard comments already deleted,
   * etc.
   *
   * @param file previously stored in DB file that is not a MillFile yet.
   * @private
   */
  private static convertOldToNew(file: IOldProjectFile): IProjectFile {
    const millFile = MillFileTransformer
      .transformProdboardFile(JSON.stringify(file))
    if (!millFile) {
      throw new Error('Error mapping file - Could not convert old file into MillFile')
    }

    // Transforming "file" to MillFile will cause that prodboardId is assigned
    // as database ID because "file.id" is not a Prodboard ID.
    // Compatibility stuff...
    millFile.prodboardId = file.prodboardId?.toString()
    // Something similar happens with "prodboardNumber"
    millFile.prodboardNumber = file.prodboardNumber

    // Make sure that all prodboard comments are maintained from old file.
    millFile.plan.items.forEach(millItem => {
      const oldItem = file.plan.items
        .find(i => i.uid === millItem.uuid)
      if (oldItem?.item?.prodboardComment) {
        millItem.prodboardComment = {
          id: oldItem.item.prodboardComment.id,
          comment: oldItem.item.prodboardComment.comment,
          deleted: oldItem.item.prodboardComment.deleted
        }
      }
    })

    return {
      ...millFile,
      type: 'F',
      // Make sure that ID, project ID and version are maintained from old file.
      id: file.id,
      version: file.version,
      projectId: file.projectId,
      // Along with some non-that-important properties
      prodboardUrl: file.url,
      serverId: file.serverId,
      customer: {
        name: file.customer?.name,
        email: file.customer?.email,
        phone: file.customer?.phone
      },
      // Timestamp is not in the model, but it is present in DB so... We keep it
      timeStamp: file['timeStamp']
    }
  }
}
