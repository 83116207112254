import {Component, Inject, OnInit} from '@angular/core'
import {FormControl, FormGroup, Validators} from '@angular/forms'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import {DateTime} from 'luxon'
import {of, switchMap} from 'rxjs'
import {first, map, tap} from 'rxjs/operators'
import {originalOrder} from '../../../../application/helpers'
import {
  CustomerProject
} from '../../../../customer/model/customer-project.class'
import {CustomerService} from '../../../../customer/service/customer.service'
import {OpenProjectService} from '../../../../services/open-project.service'
import {TagService} from '../../../../services/tag.service'
import {BallsTag, BallTagOwnerMap, TBallTagOwner} from '../../balls-tag'

@Component({
  selector: 'kdl-balls-tag-dialog',
  templateUrl: './balls-tag-dialog.component.html',
  styleUrl: './balls-tag-dialog.component.scss'
})
export class BallsTagDialogComponent implements OnInit {

  public owners = BallTagOwnerMap

  /**
   * Toggle select date.
   */
  public select = false

  public existing = false

  public dueDate: DateTime

  public form = new FormGroup({
    owner: new FormControl<TBallTagOwner>(this.data.owner,
      {nonNullable: true, validators: [Validators.required]})
  })

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: BallsTag,
    private ref: MatDialogRef<BallsTagDialogComponent>,
    private tagService: TagService,
    private openProjectService: OpenProjectService,
    private customerService: CustomerService
  ) {
  }

  public ngOnInit(): void {
    this.existing = this.data.project.tags
      .map((t: BallsTag) => t.id)
      .some((id: string) => id === this.data.id)

    if (this.data.auto) {
      this.form.controls.owner.disable()
      // Woot!, using this to hide the delete button only.!
      this.existing = false
    }

  }

  protected readonly originalOrder = originalOrder

  public deleteMe(): void {
    this.tagService.unTagProject(this.data, this.data.project).subscribe({
      next: () => this.ref.close(null)
    })
  }

  public save(): void {
    this.data.dueDate = this.dueDate?.toISODate()
    // If the control is disabled it has no value
    this.data.owner = this.form.value.owner ?? this.data.owner

    // When saving, if there has been on "dueDate", it should update the
    // associated condition (if any) in CustomerProject.
    // Try getting CustomerProject from open-project. But if not preset, get it.
    this.openProjectService.customerProject$
      .pipe(
        first(),
        switchMap(cp => cp ?
          this.modifyOpenCustomerProject(cp) :
          this.modifyClosedCustomerProject())
      )
      .subscribe(() => {
        // Close dialog
        this.ref.close(this.data)
      })
  }

  private modifyOpenCustomerProject(customerProject: CustomerProject) {
    return of(customerProject)
      .pipe(
        // Store CustomerProject and calculate if it needs to be modified
        map(cp => cp.updateStateTimeStamp(
          this.data.auto, new Date(this.data.dueDate).getTime())),
        // Send event to change open CustomerProject (if result is true)
        tap((result) => {
          if (result) {
            this.openProjectService
              .triggerChanges({customerProjectChange: true})
          }
        })
      )
  }

  private modifyClosedCustomerProject() {
    let customerProject: CustomerProject
    return this.customerService.getProject(
      this.data.project.customerProjectId, this.data.project.id)
      .pipe(
        // Store CustomerProject and calculate if it needs to be modified
        map(cp => {
          customerProject = cp
          return cp.updateStateTimeStamp(
            this.data.auto, new Date(this.data.dueDate).getTime())
        }),
        // Save CustomerProject with new changes (if result is true)
        switchMap((result) =>
          !result ? of({}) :
            this.customerService.saveCustomerProject(customerProject))
      )
  }
}
