import {Component} from '@angular/core'
import {Router} from '@angular/router'
import {OpenProjectService} from '../../services/open-project.service'
import {PRODBOARD_HOME_PATH} from '../interface/routing'

@Component({
  selector: 'kdl-shortcuts',
  templateUrl: './shortcuts.component.html',
  styleUrls: ['./shortcuts.component.scss']
})
export class ShortcutsComponent {

  constructor(
    protected openProjectService: OpenProjectService,
    private readonly router: Router
  ) {
  }

  /**
   * We must reset current project otherwise it will immediately load the
   * current project
   */
  public openProject(): void {
    this.openProjectService.unselectProjectAndReset()
    this.router.navigate([PRODBOARD_HOME_PATH]).then()
  }
}
