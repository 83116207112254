const ControlTypes = ['text', 'number', 'radio', 'select', 'file', 'url'] as const
type TControlType = typeof ControlTypes[number]

const ControlNames = [
  'artNr',
  // Legacy name for "description
  'description',
  'price',
  'material',
  'labor',
  'width',
  'url',
  'image',
  'depth',
  'height',
  'size',
  'dishwasherValve',
  'supplier',
  'brand',
  // We use name for legacy reasons. Is Model Name.
  'name',
  'isRecommended'
] as const
type TControlName = typeof ControlNames[number]

interface IControlSetting {
  type: TControlType
  label: TControlName | 'additionalInfo' | 'modelName'
  suffix?: string
}

const ControlSettingsMap = new Map<TControlName, IControlSetting>([
  ['artNr', {type: 'text', label: 'artNr'}],
  ['isRecommended', {type: 'radio', label: 'isRecommended'}],
  ['description', {type: 'text', label: 'additionalInfo'}],
  ['url', {type: 'text', label: 'url'}],
  ['image', {type: 'file', label: 'image'}],
  ['dishwasherValve', {type: 'radio', label: 'dishwasherValve'}],
  ['supplier', {type: 'select', label: 'supplier'}],
  ['brand', {type: 'text', label: 'brand'}],
  ['name', {type: 'text', label: 'modelName'}],
  ['price', {type: 'number', label: 'price', suffix: 'kr'}],
  ['labor', {type: 'number', label: 'price', suffix: '€'}],
  ['material', {type: 'number', label: 'price', suffix: 'kr'}],
  ['width', {type: 'number', label: 'width', suffix: 'mm'}],
  ['depth', {type: 'number', label: 'depth', suffix: 'mm'}],
  ['height', {type: 'number', label: 'height', suffix: 'mm'}],
  ['size', {type: 'number', label: 'size', suffix: 'mm'}]
])

export class Control {
  public value = null

  /**
   * Actually string, number, radio
   *
   * This is sometimes used for the input type..
   */
  public type: TControlType

  /**
   * What we show if we want to. Not that
   * this default is a kdl18n label that
   * matches our name but that may change.
   *
   * Potentially make this a K18n item...
   */
  public label: string


  /**
   * An optional suffix
   */
  public suffix?: string

  constructor(
    public name: TControlName,
    public required: boolean = true
  ) {
    // This _shall_ break if no match!
    this.type = ControlSettingsMap.get(name).type
    this.label = ControlSettingsMap.get(name).label
    this.suffix = ControlSettingsMap.get(name).suffix
  }
}

export class LeafFactory {
  /**
   * For stove, hob & hob with extractor
   */
  public static getStoveHobControls(): Control[] {
    return [
      new Control('artNr', false),
      new Control('brand'),
      new Control('description', false),
      new Control('image'),
      new Control('name'),
      new Control('price'),
      new Control('supplier'),
      new Control('url'),
      new Control('width'),
      new Control('isRecommended', false)
    ]
  }

  /**
   * For oven
   */
  public static getOvenControls(): Control[] {
    return [
      new Control('artNr', false),
      new Control('brand'),
      new Control('description', false),
      new Control('height'),
      new Control('image'),
      new Control('name'),
      new Control('price'),
      new Control('supplier'),
      new Control('url'),
      new Control('width'),
      new Control('isRecommended', false)
    ]
  }

  /**
   * For microwave oven
   */
  public static getMicroControls(): Control[] {
    return [
      new Control('brand'),
      new Control('name'),
      new Control('supplier'),
      new Control('artNr', false),
      new Control('description', false),
      new Control('price'),
      new Control('width'),
      new Control('height'),
      new Control('depth'),
      new Control('url'),
      new Control('image'),
      new Control('isRecommended', false)
    ]
  }

  /**
   * For integrated extractor
   */
  public static getExtractorIntControls(): Control[] {
    return [
      new Control('brand'),
      new Control('name'),
      new Control('supplier'),
      new Control('artNr', false),
      new Control('description', false),
      new Control('price'),
      new Control('width'),
      new Control('depth'),
      new Control('url'),
      new Control('image'),
      new Control('isRecommended', false)
    ]

  }

  /**
   * For wall and free hanging extractor
   */
  public static getExtractorWallFreeControls(): Control[] {
    return [
      new Control('brand'),
      new Control('name'),
      new Control('supplier'),
      new Control('artNr', false),
      new Control('description', false),
      new Control('price'),
      new Control('width'),
      new Control('url'),
      new Control('image'),
      new Control('isRecommended', false)
    ]
  }

  /**
   * For dishwasher
   */
  public static getDishwasherControls(): Control[] {
    return [
      new Control('brand'),
      new Control('name'),
      new Control('supplier'),
      new Control('artNr', false),
      new Control('description', false),
      new Control('price'),
      new Control('url'),
      new Control('image'),
      new Control('isRecommended', false)
    ]
  }

  /**
   * For fridges and freezers
   */
  public static getFridgeFreezerControls(): Control[] {
    return [
      new Control('brand'),
      new Control('name'),
      new Control('supplier'),
      new Control('artNr', false),
      new Control('description', false),
      new Control('price'),
      new Control('width'),
      new Control('height'),
      new Control('depth'),
      new Control('url'),
      new Control('image'),
      new Control('isRecommended', false)
    ]
  }

  /**
   * For fittings
   */
  public static getFittingControls(): Control[] {
    return [
      new Control('brand'),
      new Control('name'),
      new Control('supplier'),
      new Control('artNr', false),
      new Control('description', false),
      new Control('price'),
      new Control('url'),
      new Control('size'),
      new Control('image'),
      new Control('isRecommended', false)
    ]
  }

  /**
   * For sinks
   */
  public static getSinkControls(): Control[] {
    return [
      new Control('brand'),
      new Control('name'),
      new Control('supplier'),
      new Control('artNr', false),
      new Control('description', false),
      new Control('price'),
      new Control('width'),
      new Control('height'),
      new Control('depth'),
      new Control('url'),
      new Control('image'),
      new Control('isRecommended', false)
    ]
  }

  /**
   * For faucet
   */
  public static getFaucetControls(): Control[] {
    return [
      new Control('brand'),
      new Control('name'),
      new Control('supplier'),
      new Control('artNr', false),
      new Control('description', false),
      new Control('price'),
      new Control('height'),
      new Control('dishwasherValve'),
      new Control('url'),
      new Control('image'),
      new Control('isRecommended', false)
    ]
  }

  /**
   * For basketStrainer
   */
  public static getBasketStrainerControls(): Control[] {
    return [
      new Control('brand'),
      new Control('name'),
      new Control('supplier'),
      new Control('artNr', false),
      new Control('description', false),
      new Control('price'),
      new Control('url'),
      new Control('image'),
      new Control('isRecommended', false)
    ]
  }

  /**
   * For KDL paint
   */
  public static getKdlPaintControls(): Control[] {
    return [
      new Control('supplier'),
      new Control('artNr', false),
      new Control('description', false),
      new Control('price'),
      new Control('isRecommended', false)
    ]
  }

  /**
   * For other paint
   */
  public static getOtherPaintControls(): Control[] {
    return [
      new Control('brand', false),
      new Control('supplier'),
      new Control('artNr', false),
      new Control('description', false),
      new Control('price'),
      new Control('url', false),
      new Control('isRecommended', false)
    ]
  }

  public static getOtherAccessoriesControls(): Control[] {
    return [
      new Control('brand', false),
      new Control('name', false),
      new Control('supplier', false),
      new Control('artNr', false),
      new Control('description', false),
      new Control('price', false),
      new Control('width', false),
      new Control('height', false),
      new Control('depth', false),
      new Control('size', false),
      new Control('url', false),
      new Control('image', false),
      new Control('isRecommended', false)
    ]
  }
}
