import {CommonModule, NgOptimizedImage} from '@angular/common'
import {NgModule} from '@angular/core'
import {ReactiveFormsModule} from '@angular/forms'
import {MatLuxonDateModule} from '@angular/material-luxon-adapter'
import {MatAutocompleteModule} from '@angular/material/autocomplete'
import {MatBadgeModule} from '@angular/material/badge'
import {MatButtonModule} from '@angular/material/button'
import {MatCardModule} from '@angular/material/card'
import {MatCheckboxModule} from '@angular/material/checkbox'
import {MatChipsModule} from '@angular/material/chips'
import {MatDatepickerModule} from '@angular/material/datepicker'
import {MatDialogModule} from '@angular/material/dialog'
import {MatExpansionModule} from '@angular/material/expansion'
import {MatIconModule} from '@angular/material/icon'
import {MatInputModule} from '@angular/material/input'
import {MatMenuModule} from '@angular/material/menu'
import {MatRadioModule} from '@angular/material/radio'
import {MatSelectModule} from '@angular/material/select'
import {MatSnackBarModule} from '@angular/material/snack-bar'
import {MatTabsModule} from '@angular/material/tabs'
import {MatToolbarModule} from '@angular/material/toolbar'
import {MatTooltipModule} from '@angular/material/tooltip'
import {RouterModule} from '@angular/router'
import {
  GeneralSettingsComponent
} from '../edit-project/project-data/edit/general-settings/general-settings.component'
import {I18nModule} from '../i18n/i18n.module'
import {
  BallsTagDialogComponent
} from '../model/tags/dialogs/balls/balls-tag-dialog.component'
import {HiddenTagPipe} from '../model/tags/pipes/hidden-tag.pipe'
import {SortTagPipe} from '../model/tags/pipes/sort-tag.pipe'
import {TagDialogComponent} from './add-tag-dialog/tag-dialog.component'
import {AutoSaveComponent} from './auto-save/auto-save.component'
import {BadgeComponent} from './badge/badge.component'
import {CadComponent} from './cad/cad.component'
import {FileUploadComponent} from './file-upload/file-upload.component'
import {FloatingMenuComponent} from './floating-menu/floating-menu.component'
import {FooterComponent} from './footer/footer.component'
import {FormDirective} from './form-directive/form.directive'

import {HeaderComponent} from './header/header.component'
import {ImagePipe} from './image-pipe/image.pipe'
import {InitialsPipe} from './initials-pipe/initials.pipe'
import {ProjectOverviewComponent} from './kulladal/project-overview.component'
import {LinkConv} from './link-pipe/link-conv.pipe'
import {
  LoadingIndicatorComponent
} from './loading-indicator/loading-indicator.component'
import {LockComponent} from './lock/lock.component'
import {LockPipe} from './lock/pipe/lock.pipe'
import {CounterTopMaterialPipe} from './pipes/counter-top-material.pipe'
import {
  FactoryExtraPaintProcessPipe
} from './pipes/factory-extra-paint-process.pipe'
import {PaintProcessPipe} from './pipes/paint-process.pipe'
import {SettingOptionsPipe} from './pipes/setting-options.pipe'
import {SortPipe} from './pipes/sort.pipe'
import {TranslateExtraPipe} from './pipes/translate-extra.pipe'
import {WhiteGoodsTypePipe} from './pipes/white-goods-type.pipe'
import {
  HeaderPrintButtonsComponent
} from './print/header-print-buttons.component'
import {ProblemListComponent} from './problem-list/problem-list.component'
import {ProjectLoaderComponent} from './project-loader/project-loader.component'
import {ShortcutsComponent} from './shortcuts/shortcuts.component'
import {SnoozeComponent} from './snooze/snooze.component'
import {UsersPipe} from './users-pipe/users.pipe'
import {FormatGroupPipe} from './format-group/format-group.pipe'

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    ProblemListComponent,
    LoadingIndicatorComponent,
    FileUploadComponent,
    HeaderPrintButtonsComponent,
    AutoSaveComponent,
    ProjectLoaderComponent,
    ShortcutsComponent,
    FloatingMenuComponent,
    BadgeComponent,
    LinkConv,
    ImagePipe,
    ProjectOverviewComponent,
    LockComponent,
    LockPipe,
    FormDirective,
    InitialsPipe,
    SortTagPipe,
    FormatGroupPipe,
    HiddenTagPipe,
    BallsTagDialogComponent,
    TagDialogComponent,
    SnoozeComponent,
    FactoryExtraPaintProcessPipe,
    SettingOptionsPipe,
    TranslateExtraPipe,
    CounterTopMaterialPipe,
    UsersPipe,
    WhiteGoodsTypePipe
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    ProblemListComponent,
    LoadingIndicatorComponent,
    FileUploadComponent,
    ProjectLoaderComponent,
    FloatingMenuComponent,
    CadComponent,
    BadgeComponent,
    LinkConv,
    ImagePipe,
    ProjectOverviewComponent,
    LockComponent,
    LockPipe,
    FormDirective,
    InitialsPipe,
    SortTagPipe,
    HiddenTagPipe,
    BallsTagDialogComponent,
    TagDialogComponent,
    SnoozeComponent,
    PaintProcessPipe,
    FactoryExtraPaintProcessPipe,
    SettingOptionsPipe,
    TranslateExtraPipe,
    CounterTopMaterialPipe,
    GeneralSettingsComponent,
    SortPipe,
    UsersPipe,
    WhiteGoodsTypePipe,
    FormatGroupPipe
  ],
  imports: [
    SortPipe,
    PaintProcessPipe,
    GeneralSettingsComponent,
    CadComponent,
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatIconModule,
    I18nModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatButtonModule,
    MatExpansionModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatMenuModule,
    MatToolbarModule,
    MatAutocompleteModule,
    MatCardModule,
    NgOptimizedImage,
    MatDatepickerModule,
    MatLuxonDateModule,
    MatRadioModule,
    MatTabsModule,
    MatBadgeModule,
    MatChipsModule
  ]
})
export class KdlCommonModule {
}
