import {Appliance} from '../model/appliance'
import {
  ApplianceCabinetCategoriesMultiAppliance,
  AppliancePossibleCabinets,
  ApplianceTrees,
  FreestandingApplianceTrees,
  IApplianceConfig,
  IApplianceConfigCondition,
  IAppliancePossibleCabinetsConfig,
  TApplianceConfigConditionParam,
  TApplianceItemKey
} from '../model/appliance-map'
import {TApplianceTypeName} from './appliance.service'
import {ProdboardCabinet} from '../../model/cabinet/prodboard-cabinet'

interface ICanConnectResult {
  /**
   * If true, appliance can be connected to cabinet. If false, it cannot.
   * When true, there might be some warnings. None if result is false.
   */
  result: boolean
  /**
   * All possible warning that this connection might have
   */
  warnings?: string[]
}

export class ApplianceUtils {
  /**
   * Checks if an appliance has a given appliance-tree.
   * @return If so, it returns true. Returns false otherwise.
   * @param appliance Appliance to be checked.
   * @param treeToCheck Appliance tree to compare to.
   */
  public static includesTree(
    appliance: Appliance,
    treeToCheck: TApplianceItemKey[]
  ): boolean {
    return treeToCheck.every((key: TApplianceItemKey) =>
      appliance.applianceTree.includes(key))
  }

  /**
   * It checks if a path, a tree of appliance type keys, are the same.
   * @param path1 First path to compare.
   * @param path2 Second path to compare.
   */
  public static isSamePath(
    path1: TApplianceItemKey[],
    path2: TApplianceItemKey[]
  ): boolean {
    // Paths needs to be checked stringified, otherwise they will
    // differ because, two array objects are not the same.
    return JSON.stringify(path1) === JSON.stringify(path2)
  }

  /**
   * Checks if an appliance is freestanding, evaluating its appliance tree.
   * All trees defined as "freestanding" have been manually selected and saved
   * locally.
   * Also, a second parameter can be passed for extra filtering. Checking a
   * required appliance type. Quite useful if we need to check if appliance is
   * a "freestanding fridge" for example.
   * @param appliance Appliance to check if is considered freestanding.
   * @param allowedTypes Allowed appliance type for extra filtering.
   */
  public static isFreestanding(
    appliance: Appliance,
    allowedTypes: TApplianceTypeName[] = []
  ): boolean {
    return FreestandingApplianceTrees.some((tree: TApplianceItemKey[]): boolean => {
      return this.includesTree(appliance, tree) &&
        (!allowedTypes.length ||
          (allowedTypes.length && allowedTypes.includes(appliance.applianceType)))
    })
  }

  /**
   * Method that checks if a cabinet needs to have connected appliances or not.
   * @param cabinet
   */
  public static cabinetNeedsConnectedAppliances(cabinet: ProdboardCabinet): boolean {
    return AppliancePossibleCabinets
      // Filter out all those possibilities that don't match given cabinet's
      // category.
      .filter(pc => this.checkCabinetCat(cabinet, [pc.cat]))
      // Check if AT LEAST ONE of the possibilities either don't have cabinet
      // options required or, in case they do, if cabinet has these options
      // active.
      .some(pc => !pc.neededCabinetOptions ||
        pc.neededCabinetOptions.every(option =>
          cabinet.getActiveOption(option)?.active))
  }

  public static cabinetMaxConnections(cabinet: ProdboardCabinet): number {
    return ApplianceCabinetCategoriesMultiAppliance.includes(cabinet.cat) ?
      2 : 1
  }

  /**
   * It determines if the given appliance can be connected to the given
   * cabinet.
   * It follows some rules defined by Adam/KDL in an Excel Sheet and
   * translated to here. Quite Picasso but it is what it is. We need to
   * maintain it and check it well in tests.
   * @param appliance Appliance to check that can be connected to cabinet.
   * @param cabinet Cabinet to check if appliance fits in.
   */
  public static canBeConnectedToCabinet(
    appliance: Appliance,
    cabinet: ProdboardCabinet
  ): ICanConnectResult {
    // Check if cabinet category is included in appliance config possible
    // cabinet's categories (any of them). If so, we save that condition to
    // be evaluated later.
    const possibleCabinetsConfig =
      this.getPossibleCabinetsConfig(appliance, cabinet)
    // If no possible cabinets config was found, meaning no matches.
    // Return false, appliance cannot be connected to cabinet.
    if (!possibleCabinetsConfig) {
      return {result: false}
    }

    // Check if config needs cabinet options (all need to be active to pass).
    // If they do, check if cabinet has those needed options active. If it
    // doesn't, return false, appliance cannot be connected to cabinet.
    if (possibleCabinetsConfig.neededCabinetOptions &&
      !possibleCabinetsConfig.neededCabinetOptions
        .every(option =>
          cabinet.getActiveOption(option)?.active)) {
      return {result: false}
    }

    console.log(`CHECKING APPLIANCE ${appliance.brand} ${appliance.name}; With cabinet ${cabinet.displayName}`)
    console.log('Possible cabinets: ', possibleCabinetsConfig)

    // Evaluate the rest of conditions, connection and warning ones, to
    // finally check if appliance can/cannot be connected. And if it can, if
    // there is any warning associated.
    return this.evaluateConnectionConditions(appliance, cabinet, possibleCabinetsConfig)
  }

  private static getPossibleCabinetsConfig(
    appliance: Appliance,
    cabinet: ProdboardCabinet
  ): IAppliancePossibleCabinetsConfig | undefined {
    // Go down in tree-levels using the applianceTree passed. And keep
    // updating "currentItem" value.
    // We get the first level that has possible-cabinets configuration.
    let currentItem: IApplianceConfig
    let cabinetsConfigs: IAppliancePossibleCabinetsConfig[]
    appliance.applianceTree.forEach((key: TApplianceItemKey) => {
      currentItem = currentItem ?
        currentItem.options.find(a => a.key === key) :
        ApplianceTrees.find(a => a.key === key)

      if (currentItem?.possibleCabinetsConfigs && !cabinetsConfigs) {
        cabinetsConfigs = currentItem.possibleCabinetsConfigs
      }
    })

    // Return FIRST matching possible-cabinets configuration. It is matched by
    // cabinet category, which needs to be included in config's categories.
    return cabinetsConfigs?.find(config =>
      this.checkCabinetCat(cabinet, config.categories))
  }

  private static checkCabinetCat(
    cabinet: ProdboardCabinet,
    possibleCategories: (RegExp | string)[]
  ): boolean {
    return possibleCategories.some(r =>
      new RegExp(r).test(cabinet.cat))
  }

  /**
   * Evaluates all possible connect conditions that an appliance can have. And
   * if any of them is true, the appliance cannot be connected to the cabinet.
   *
   * Also, if it can be connected, it will evaluate if there is any warning.
   * @private
   */
  private static evaluateConnectionConditions(
    appliance: Appliance,
    cabinet: ProdboardCabinet,
    possibleCabinetsConfig: IAppliancePossibleCabinetsConfig
  ): ICanConnectResult {
    // Evaluate if it can be purely connected: if some of the connect
    // conditions fails, it cannot be connected. Result = false
    if (possibleCabinetsConfig.connectConditions.some(c =>
      !this.evaluateApplianceConfigCondition(appliance, cabinet, c))) {
      return {result: false}
    }

    // If it can be indeed connected, then we check if it might have some
    // warning.
    return {
      result: true,
      warnings: possibleCabinetsConfig.warningConditions
        // Filter all those conditions that are not met, the ones we want to
        // show warnings from.
        .filter(c =>
          !this.evaluateApplianceConfigCondition(appliance, cabinet, c))
        .map(c => c.description)
    }
  }

  /**
   * It evaluates an appliance config condition.
   * @private
   */
  private static evaluateApplianceConfigCondition(
    appliance: Appliance,
    cabinet: ProdboardCabinet,
    condition: IApplianceConfigCondition
  ): boolean {
    const DO_NOT_EVALUATE = 'DO-NOT-EVALUATE-USE-ZERO'
    const applianceParameterMap: { [key in TApplianceConfigConditionParam]: string } = {
      width: 'width',
      height: 'height',
      depth: 'depth',
      openingWidth: 'width',
      openingHeight: 'height',
      widthWithRecess: 'width',
      applianceHeight: 'height',
      cabinetOpeningWidth: DO_NOT_EVALUATE
    }
    const cabinetParameterMap: { [key in TApplianceConfigConditionParam]: string } = {
      width: 'visibleWidth',
      height: 'actualHeight',
      depth: 'depth',
      openingWidth: 'openingWidth',
      openingHeight: 'openingHeight',
      widthWithRecess: 'widthWithRecess',
      applianceHeight: DO_NOT_EVALUATE,
      cabinetOpeningWidth: 'openingWidth'
    }

    const applianceParam =
      applianceParameterMap[condition.parameter] === DO_NOT_EVALUATE ?
        0 : appliance[applianceParameterMap[condition.parameter]]
    const cabinetParam =
      cabinetParameterMap[condition.parameter] === DO_NOT_EVALUATE ?
        0 : cabinet[cabinetParameterMap[condition.parameter]]

    // If either cabinet or appliance parameter are null/undefined,
    // not numbers, the check is immediately false.
    let result = !Number.isNaN(Number(applianceParam)) &&
      !Number.isNaN(Number(cabinetParam))
    console.log(`Evaluating "${condition.description}" -> initial...`, result)

    // Evaluate upper limit (max appliance parameter), if any
    if (condition.upperExtra !== undefined) {
      console.log('evaluating upper...', (applianceParam <= (cabinetParam + condition.upperExtra)))
      result = result &&
        (applianceParam <= (cabinetParam + condition.upperExtra))
    }

    // Evaluate lower limit (min appliance parameter), if any
    if (condition.lowerExtra !== undefined) {
      console.log('evaluating lower...', (applianceParam >= (cabinetParam + condition.lowerExtra)))
      result = result &&
        (applianceParam >= (cabinetParam + condition.lowerExtra))
    }

    console.log(`AP ${applianceParam}, CP ${cabinetParam} -> ${result}`)
    // Return final result
    return result
  }
}
