<mat-card [ngClass]="{'card-cloak': cloak}">
  @if (project) {
    <form [formGroup]="form">
      <!-------------- GROUP 1 -------------->
      <div [ngClass]="{cloak}" class="group">
        <!-- Project Name -->
        <div
          [ngClass]="{ 'hide-form-control' : formControlToHide['projectName'] }"
          class="input-holder">
          <mat-form-field [style.width.px]="350">
            <mat-label kdlI18n label="projectName"></mat-label>
            <input formControlName="customerName"
                   matInput
                   type="text">
            <mat-hint>
              <span kdlI18n label="projectNameHint"></span>
            </mat-hint>
          </mat-form-field>
        </div>

        <!-- Approximate delivery date -->
        <div
          [ngClass]="{ 'hide-form-control' : formControlToHide['approxDeliveryDate'] }"
          class="input-holder">
          <mat-form-field>
            <mat-label kdlI18n label="approximateDelivery"></mat-label>
            <input [matDatepicker]="picker"
                   [min]="minDate"
                   formControlName="approxDeliveryDate"
                   matInput>

            <mat-datepicker-toggle [for]="picker"
                                   matIconSuffix></mat-datepicker-toggle>
          </mat-form-field>
          <mat-datepicker #picker></mat-datepicker>
        </div>
      </div>
      <!----------- End of GROUP 1 ----------->

      <!-------------- GROUP 2 -------------->
      <div [ngClass]="{cloak}" class="group">
        <!-- Customer Version - customerVersion -->
        <div
          [ngClass]="{ 'hide-form-control' : formControlToHide['customerVersion'] }"
          class="input-holder">
          <mat-form-field>
            <mat-label kdlI18n label="customerVersion"></mat-label>
            <input class="kdl-no-flippers"
                   formControlName="customerVersion"
                   matInput
                   type="number">
            <mat-hint><span kdlI18n label="customerVersionHint"></span>
            </mat-hint>
          </mat-form-field>
        </div>

        <!-- Country, should be checked how this relates to Customer -->
        <mat-form-field
          [ngClass]="{ 'hide-form-control' : formControlToHide['lc'] }">
          <mat-label kdlI18n label="country"></mat-label>
          <mat-select [placeholder]="'countryPlaceholder' | i18n | async"
                      formControlName="lc" id="country" ngDefaultControl>
            <mat-option value="sv"><span kdlI18n label="sweden"></span>
            </mat-option>
            <mat-option value="fi"><span kdlI18n label="finland"></span>
            </mat-option>
            <mat-option value="da"><span kdlI18n label="denmark"></span>
            </mat-option>
            <mat-option value="nb"><span kdlI18n label="norway"></span>
            </mat-option>
            <mat-option value=""></mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Socel Height -->
        <div
          [ngClass]="{ 'hide-form-control' : formControlToHide['socelHeight'] }"
          class="input-holder">
          <mat-form-field>
            <mat-label kdlI18n label="socelHeight"></mat-label>
            <input class="kdl-no-flippers" formControlName="socelHeight"
                   matInput type="number">
            <span matTextSuffix="">mm</span>
            <mat-hint><span kdlI18n label="socelHeightHint"></span></mat-hint>
          </mat-form-field>
        </div>
      </div>
      <!----------- End of GROUP 2 ----------->

      <!-------------- GROUP 3 -------------->
      <div [ngClass]="{cloak: cloak}" class="group">
        <!-- Total customer price, auto calculated and non-editable -->
        <!-- Price is hidden if no country ("lc") is selected -->
        <div
          [ngClass]="{ 'hide-form-control' : formControlToHide['customerPrice'] || !form.controls.lc.value }"
          class="input-holder">
          <mat-form-field class="narrow">
            <mat-label kdlI18n label="priceToCustomer"></mat-label>
            <!-- Project customer price cannot be edited -->
            <input class="kdl-no-flippers"
                   [value]="project.pricing.totalCustomerPrice | number:'1.0-0':'fr' "
                   disabled
                   matInput>
            <span matTextSuffix="">kr</span>

            @if (form.controls.priceAdjustments.length) {
              <mat-hint>
                Price w/o adjustment:
                {{ project.pricing.originalCustomerPrice | number:'1.0-0':'fr' }}
              </mat-hint>
            }
          </mat-form-field>
        </div>

        <!-- Locking of prices -->
        @if (project.priceLockTime) {
          <div
            [ngClass]="{ 'hide-form-control' : formControlToHide['customerPrice'] || !form.controls.lc.value }">
            <span kdlI18n label="priceLockedTime"></span>
            <span>{{ project.priceLockTime | date: 'd MMMM yyyy' }}.</span>
            <div class="price-lock">
              <span>Do you want to update the project to the current price list?</span>
              <button class="yes-button" (click)="resetPriceLock()" color="warn"
                      kdlI18n label="yes"
                      mat-stroked-button></button>
            </div>
          </div>
        }
      </div>
      <!----------- End of GROUP 3 ----------->

      <!-------------- GROUP 4 - Adjustments -------------->
      <!-- Adjustments are hidden if no country ("lc") is selected -->
      <div
        class="group price-adjustment-row"
        [ngClass]="{ 'hide-form-control' : formControlToHide['discount'] || !form.controls.lc.value, cloak }">
        <!-- Price adjustments -->
        @for (control of form.controls.priceAdjustments.controls; track $index) {
          <div class="price-adjustment-form-field">
            <mat-form-field>
              <mat-label kdlI18n label="priceAdjustment"></mat-label>
              <input
                class="kdl-no-flippers"
                [formControl]="control"
                matInput
                type="number">

              <span matTextSuffix="">kr</span>
            </mat-form-field>

            <button mat-icon-button color="warn" class="remove-button"
                    (click)="removePriceAdjustment($index)">
              <mat-icon class="material-icons-outlined">cancel</mat-icon>
            </button>
          </div>
        }

        <button mat-icon-button color="primary"
                (click)="addPriceAdjustment()">
          <mat-icon class="material-icons-outlined">add_circle</mat-icon>
        </button>
      </div>
      <!-------------- End of GROUP 4 -------------->

      <!-------- Installation options -------->
      <!-------------- GROUP 5 -------------->
      <div
        [ngClass]="{ 'hide-form-control' : formControlToHide['assemblyData'], cloak }"
        class="group">
        <!-- Assembly customer price cannot be edited -->
        <mat-form-field>
          <mat-label kdlI18n label="assemblyTotalCustomer"></mat-label>
          <input
            class="kdl-no-flippers"
            [value]="project.pricing.assemblyCustomerPrice | number:'1.0-0':'fr' "
            disabled
            matInput>
          <span matTextSuffix="">kr</span>
        </mat-form-field>

        <mat-checkbox formControlName="assemblyToCustomer" ngDefaultControl>
          <span kdlI18n label="showAssemblyInQuote"></span>
        </mat-checkbox>
      </div>
      <!----------- End of GROUP 5 ----------->

      <!-------------- GROUP 6 -------------->
      @if (form.controls.assemblyToCustomer.value) {
        <div
          [ngClass]="{ 'hide-form-control' : formControlToHide['assemblyData'], cloak }"
          class="group">
          <!-- Assembly Options -->
          <kdl-assembly-options [projectForm]="project.form">
          </kdl-assembly-options>
        </div>
      }
      <!----------- End of GROUP 6 ----------->
      <!---- End of Installation options ---->

      <!-------------- GROUP 7 -------------->
      <div [ngClass]="{ cloak }" class="group">
        <!-- Paint process -->
        <mat-form-field
          [ngClass]="{ 'hide-form-control' : formControlToHide['paintProcess'] }"
          [style.width.px]="350">
          <mat-label kdlI18n label="paintProcess"></mat-label>
          <mat-select [placeholder]="'paintProcessPlaceholder' | i18n | async"
                      formControlName="paintProcess"
                      panelClass="no-max-height"
                      ngDefaultControl
                      panelWidth="350px">
            @for (opt of PaintProcessValues; track opt) {
              <mat-option [value]="opt">
                {{ opt | paintProcess:'i':'en' }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
        <!-- Color -->
        @if (!hideColor) {
          <div
            [ngClass]="{ 'hide-form-control' : formControlToHide['color'] }"
            class="input-holder">
            <kdl-general-settings
              [control]="form.controls.color"
              [optionsMap]="colors"
              [width]="250"
              hint="Choose a color, or write the name of a custom color"
              label="colorLabel"></kdl-general-settings>
          </div>
        }
      </div>
      <!----------- End of GROUP 7 ----------->

      <!-------------- GROUP 8 -------------->
      <div [ngClass]="{cloak: cloak}" class="group">
        <!-- Carpentry -->
        <kdl-general-settings
          [control]="form.controls.carpentry"
          [ngClass]="{ 'hide-form-control' : formControlToHide['carpentry'] }"
          [optionsMap]="carpenters"
          label="carpentryLabel"
        ></kdl-general-settings>

        <!-- CAD -->
        <kdl-general-settings
          [control]="form.controls.cad"
          [ngClass]="{ 'hide-form-control' : formControlToHide['cad'] }"
          [optionsMap]="caders"
          label="cadLabel"
        ></kdl-general-settings>

        <!-- Painter -->
        @if (!hidePainter) {
          <kdl-general-settings
            [control]="form.controls.painter"
            [ngClass]="{ 'hide-form-control' : formControlToHide['painter'] }"
            [optionsMap]="painters"
            label="painterLabel"
          ></kdl-general-settings>
        }
      </div>
      <!----------- End of GROUP 8 ----------->

      <!-------------- GROUP 9 -------------->
      <div
        [ngClass]="{ 'hide-form-control' : formControlToHide['hideFactoryInvoice'] }">
        <mat-checkbox formControlName="hideFactoryInvoice" ngDefaultControl>
          <span kdlI18n label="priceToCarpentry"></span>
        </mat-checkbox>
      </div>

      <div
        [ngClass]="{ 'hide-form-control' : formControlToHide['hideRecommendedAppliances'] }">
        <mat-checkbox formControlName="hideRecommendedAppliances"
                      ngDefaultControl>
          <span kdlI18n label="recommendedAppliances"></span>
        </mat-checkbox>
      </div>
      <!----------- End of GROUP 9 ----------->
    </form>
  }
</mat-card>
