<button
  [disabled]="!path || !(openProjectService.project$ | async)?.form.lc"
  [routerLink]="[customerPath + path]"
  matTooltip="Preview customer spec"
  matTooltipClass="mat-tooltip-extra"
  class="compact customer" mat-mini-fab>
  <img alt="customer" height="24" ngSrc="assets/kundunderlag.svg" width="24">
</button>

<button
  [disabled]="!path"
  [routerLink]="[factoryPath + path]"
  matTooltip="Preview carpentry order sheet"
  matTooltipClass="mat-tooltip-extra"
  class="compact factory"
  mat-mini-fab>
  <img alt="factory" height="24" ngSrc="assets/snickeriunderlag.svg" width="24">
</button>
