import {ProjectAppliance} from '../appliances/model/appliance'
import {Comment} from '../comments/model/comment'
import {CounterTop} from '../counter-top/model/counter-top'
import {CustomerStateLabel} from '../customer/customer-types'
import {FactoryExtra} from '../factory-extras/model/factory-extra'
import {IProjectImage} from '../images/model/project-image'
import {CabinetLighting} from '../model/cabinet/cabinet-lighting'
import {ITag} from '../model/tags/types'
import {ProjectPricing} from './project-pricing-types'
import {
  IExternalAppliance,
  IFreestandingApplianceConfig
} from './project-extra-types'

/**
 * The project base is used to store information from the backend
 * These properties are relevant for backend operations.
 */
export interface IProjectBase {
  id: string

  version: number

  /**
   * A project is strictly tied to a file
   */
  fileId: string

  /**
   * Let us begin build customers
   */
  customer: ProjectCustomer

  /**
   * This is a server property, we use it to sort when opening
   * a new project.
   */
  timeStamp: number

  /**
   * This is the name of the real customer. Not the name
   * of the "project" that we erroneously save on the "customer"
   * object.
   */
  customerName?: string

  /**
   * The customer ID so that we can route properly
   */
  customerId?: string

  /**
   * Inherited state indicator.
   */
  projectPhase: CustomerStateLabel

  /**
   * The customer project ID so that we can find
   * and fetch the customer project if we need
   * to manipulate it. Typically, archive it.
   */
  customerProjectId?: string

  /**
   * Tells if this is an "order" then it is very special
   */
  isOrder?: boolean

  /**
   * A list of tags
   */
  tags: Partial<ITag>[]
}

/**
 * Another step in creating real customers.
 *
 * Currently, information is fetched from Prodboard file as well as
 * from Project form.
 */
export interface ProjectCustomer {

  name: string

  phone: string

  email: string

  url: string

  /**
   * Called 'number' in prodboard and is a string like '123123-01'
   */
  prodboardNumber: string

  /**
   * This is a numeric id like 823
   */
  prodboardId: string
}

/**
 * The Project extends the project base with information computed/set
 * by the application.
 */
export interface IProject extends IProjectBase {
  /**
   * This is an object of really "any" information, each cabinet can have
   * a range of options set with various values. These values are just stored.
   *
   * We also old a generic "comment" for each and every option, and possibly
   * other generic fields.
   */
  cabinets: { [key: string]: any }

  /**
   * This is data from input forms, we cannot specify it to hard.
   * But every time we get a form we do Object.assign( form, JSON.parse(JSON.stringify(input),
   */
  form: ProjectFormData

  /**
   * A list of comments on the "Project".
   */
  comments?: Comment[]

  /**
   * New list of all comments relating to this "Kitchen",
   * this is an aggregate of all comments.
   */
  commentList?: Comment[]

  /**
   * Selected appliances
   */
  appliances: ProjectAppliance[]
  /**
   * External appliances make reference to those appliances that are bought by
   * the customer. And in order to know what they are, we keep their URL.
   * They are always liked to a cabinet.
   */
  externalAppliances: IExternalAppliance[]
  /**
   * Freestanding-appliance options, which should contain all
   * paths (appliance type tree) of possible freestanding appliances (setup
   * locally in code) as "keys"; and as value, it will indicate if the customer
   * wants that type of appliance for they kitchen, and in case they do, it
   * will also indicate if they buy them themselves, or they buy from us.
   */
  faConfigs: IFreestandingApplianceConfig[]

  /**
   * Images
   */
  images: IProjectImage[]

  /**
   * Counter tops
   */
  counterTops: CounterTop[]

  /**
   *
   */
  factoryExtras: FactoryExtra[]

  /**
   * Pricing object that contains all price information about everything that
   * the project contains: cabinets, appliances, counter tops, and factory
   * extras.
   * Do not send to API.
   */
  pricing?: ProjectPricing

  /**
   * Lights object that contains all lighting information about every cabinet
   * in the project. All information related to LED-strips underneath,
   * LED-strips inside and spotlights.
   * Do not send to API.
   */
  lights?: CabinetLighting[]

  /**
   * The time the user locked the current cabinet prices (and settings)
   */
  priceLockTime: number | null

  modifiedBy: string | null
}

/**
 * 0 - is not set, the rest are the normal ones
 *
 *   * Paint process comes in on of 4 flavors
 *    * 1. Line seed full paint -> [0]?
 *    * 2. Base paint (primered)
 *    * 3. Normal paint
 *    * 4. Unpainted, waxed inside
 *    * 5. Unpainted, no wax on wax off
 *    * 6. Normal, primered
 *    * 7. Linseed paint in factory
 *    * 8. Ordinary paint in factory
 *
 */

export const PaintProcessValues = [1, 2, 3, 4, 5, 6, 7, 8] as const
export type TPaintProcessValue = typeof PaintProcessValues[number]

const CountryLanguageCodes = ['sv', 'fi', 'da', 'nb'] as const
export type TCountryLanguageCode = typeof CountryLanguageCodes[number]
export const DefaultCountryLanguageCode: TCountryLanguageCode = 'sv'
export const LanguageCodeCountryMap: Record<TCountryLanguageCode, string> = {
  'sv': 'Sweden',
  'fi': 'Finland',
  'da': 'Denmark',
  'nb': 'Norway'
}

/**
 * Pretty random data that can be set on a project.
 */
export interface ProjectFormData {
  /**
   * Customer name, is also set on the project.customer.name property
   */
  customerName: string

  /* TODO - It should be remove since now we use "priceAdjustments", but we need
  *   to get all project in DB right now and change their discount for
  *   priceAdjustments. Probably a script for this. */
  /**
   * This is the customer discount percentage expressed 2,5 %
   * @deprecated
   */
  discountPercent: number

  /* TODO - It should be remove since now we use "priceAdjustments", but we need
  *   to get all project in DB right now and calculate an adjustment for
  *   each one of them depending on their auto-calculated price.
  *   Probably a script for this. */
  /**
   * A "rounded" price to show to the customer if needed.
   * @deprecated
   */
  customerPrice: number

  /**
   * Customer's price adjustments expressed in SEK. They can be positive or
   * negative, and they will adjust the project's final customer price.
   */
  priceAdjustments: number[]

  /**
   * This is a version displayed to the customer
   * different from the real project version.
   */
  customerVersion: number

  /**
   * Color, found in factory document. Only mentioned
   * as T.B.D. A string for now, maybe a NCS picker in the
   * future.
   */
  color: string

  /**
   * Carpenter which the user selects in the project settings
   */
  carpentry: string

  /**
   * CAD:er which the user selects in the project settings
   */
  cad: string

  /**
   * Painter which the user selects in the project settings
   */
  painter: string

  /**
   * Paint process comes in on of 8 flavors
   */
  paintProcess: TPaintProcessValue

  /**
   * We currently support four... Used in the customer quote
   */
  lc: TCountryLanguageCode

  /**
   * Default height of socel (Plinth) anything but 0/undefined will
   * be used instead of the cabinet setting
   */
  socelHeight: number

  /**
   * The approximate delivery date for the kitchen
   */
  approxDeliveryDate: string

  /**
   * Set to true if we should remove all options for
   * door/drawer knobs and handles.
   */
  ignoreHandles: boolean

  /**
   * Tells if the factory invoice should be hidden.
   */
  hideFactoryInvoice: boolean

  hideRecommendedAppliances: boolean

  /**
   * Assembly instructions
   */
  assemblyCarry: boolean

  assemblyWaste: boolean

  assemblyMeasure: boolean

  /**
   * Manually enter custom price for all assembly services, installation.
   * It will override the automatically calculated price.
   * @Deprecated
   */
  /* TODO - Remove it once we know it is safe. We'll need to check DB for
      items with this value and maybe remove it? It's not used anymore. */
  assemblyPrice: number

  // Show assembly data to customer.
  assemblyToCustomer: boolean
}

/**
 * Change, changed yes/no + type? + change?
 */
export interface IChange {
  changed: boolean
  fileChanged: boolean
}

export interface ICounterTopBakkant {
  length: number

  height: number

  thickness: number

  /**
   * Price per sqm
   */
  price: number

  /**
   * Price per sqm for factory
   */
  labor: number
}
