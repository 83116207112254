import {Comment} from '../comments/model/comment'
import {TWhiteGoodsTypeKey} from '../common/interface/product-types'
import {CabinetOption, TOptionSelectName} from '../model/cabinet-option'
import {ProdboardCabinet} from '../model/cabinet/prodboard-cabinet'
import {Lighting} from '../model/lighting'
import {
  AssemblyPrice,
  BaseItemPrice,
  CommentPrice,
  IAssemblyPrice,
  ItemPrice,
  OptionPrice,
  ProjectPricing,
  TPriceType,
  TPriceTypes
} from './project-pricing-types'
import {IProject} from './project-types'
import {ISettingsConfigSupplier} from './settings-item.service'

// VAT percentage to use when calculating appliances costs prices
const APPLIANCE_VAT_COST_PERCENTAGE = 0.2

// Installation service start fee
const START_FEE = 9800
// Installation services per cabinet
const VOLUME_PRICE = 3100
const CABINET_COUNT_PRICE = 800
// Cabinets
const WALL_CABINET_PRICE = 1380
const OSH_PRICE = -300
const SINK_CABINET = 1380
const PANTRY = 7000
const DISH_WASHER = 1380
// Cabinets with white-good adaptations
const FRIDGE_FREEZER = 2300
const WINE_FRIDGE = 1700
const OVEN = 1150
const OVEN_MICRO = 2300
const MICRO = 1150
const WASHING_MACHINE = 1700
const TUMBLE_DRYER = 1700
const FAN_ADOPTION = 2300
// Cabinet options
// (Fan adoptions fall here too)
const COVER_SIDE = 500
const OVER_SIZE = 500
const SPOTS = 290
const LEDS = 290
// Counter tops
const COUNTER_TOP_AREA = 600
const COUNTER_TOP_COUNT = 600
// Extra services for cabinets
const CARRY_PRICE_START_FEE = 4000
const CARRY_VOLUME_PRICE = 350
const WASTE_PRICE_START_FEE = 2300
const WASTE_VOLUME_PRICE = 150
const MEASURING_SERVICE = 2900

const CabinetCategoryMap: Record<string, IAssemblyPrice> = {
  OD: {type: 'wallCabinets', perQuantityCustomer: WALL_CABINET_PRICE},
  OSH: {type: 'shelves', perQuantityCustomer: OSH_PRICE},
  BSP: {type: 'sink', perQuantityCustomer: SINK_CABINET},
  TD1C: {type: 'pantry', perQuantityCustomer: PANTRY}
}

const WhiteGoodsMap: Record<TWhiteGoodsTypeKey, IAssemblyPrice> = {
  ff: {type: 'fridgeFreezer', perQuantityCustomer: FRIDGE_FREEZER},
  wf: {type: 'wineFridge', perQuantityCustomer: WINE_FRIDGE},
  o: {type: 'oven', perQuantityCustomer: OVEN},
  'o+m': {type: 'ovenMicro', perQuantityCustomer: OVEN_MICRO},
  m: {type: 'micro', perQuantityCustomer: MICRO},
  dw: {type: 'dishWasher', perQuantityCustomer: DISH_WASHER},
  wm: {type: 'washingMachine', perQuantityCustomer: WASHING_MACHINE},
  td: {type: 'tumbleDryer', perQuantityCustomer: TUMBLE_DRYER},
  ex: {type: 'fanAdoption', perQuantityCustomer: FAN_ADOPTION}
}

const CabinetOptionsMap: Map<TOptionSelectName, IAssemblyPrice> = new Map([
  ['CoverSide', {type: 'coverSide', perQuantityCustomer: COVER_SIDE}],
  ['Scribings', {type: 'overSize', perQuantityCustomer: OVER_SIZE}],
  ['FanAdoption', {type: 'fanAdoption', perQuantityCustomer: FAN_ADOPTION}]
])

type TBaseItemSection =
  'item'
  | 'option'
  | 'comment'
  | 'service'

export class ProjectPricingUtils {
  public static calculatePriceOfItemAndSubitems<T extends BaseItemPrice>(
    baseItem: T | T[],
    excludedSections: string[] = []
  ): TPriceTypes {
    const items = this.getAllSubitemsFromItem(baseItem, excludedSections)
    return {
      customer: items.reduce((acc, i) =>
        acc + i.getPriceType('customer'), 0),
      factory: items.reduce((acc, i) =>
        acc + i.getPriceType('factory'), 0),
      costs: items.reduce((acc, i) =>
        acc + i.getPriceType('costs'), 0)
    }
  }

  public static calculatePriceTypeOfItemAndSubitems<T extends BaseItemPrice>(
    baseItem: T | T[],
    priceType: TPriceType,
    excludedSections: string[] = []
  ): number {
    const items = this.getAllSubitemsFromItem(baseItem, excludedSections)
    return items.reduce((acc, i) =>
      acc + i.getPriceType(priceType), 0)
  }

  public static calculatePriceTypeOfItems<T extends BaseItemPrice>(
    object: T | T[],
    itemType: TBaseItemSection,
    priceType: TPriceType
  ): number {
    return ProjectPricingUtils.getItemsWithSameClass(object, itemType)
      .reduce((acc, item) => acc + item.getPriceType(priceType), 0)
  }

  public static getItemsWithSameClass<T extends BaseItemPrice>(
    object: T | T[],
    itemType: TBaseItemSection
  ): BaseItemPrice[] {
    return this.getAllItemsWithSameClass(
      Array.isArray(object) ? object : [object],
      this.getItemClassFromType(itemType)
    )
  }

  public static getCabinetOptionId(option: CabinetOption): string {
    return `${option.cabinetIndex}_${option.name}`
  }

  public static getProjectPricing(
    millColours: string[],
    applianceSuppliers: ISettingsConfigSupplier[],
    project: IProject,
    cabinets: ProdboardCabinet[]
  ): ProjectPricing {
    return new ProjectPricing(
      project.form.priceAdjustments ?? [],
      ProjectPricingUtils.getProjectServices(project),
      [
        ...ProjectPricingUtils.getCabinetPrices(project, cabinets),
        ...ProjectPricingUtils.getAppliancesPrices(applianceSuppliers, project),
        ...ProjectPricingUtils.getCounterTopMakePrices(project),
        ...ProjectPricingUtils.getCounterTopBuyPrices(project),
        ...ProjectPricingUtils.getFactoryExtrasPrices(project)
      ],
      {
        isAssemblyActive: project.form.assemblyToCustomer,
        isOutsideSweden: project.form.lc !== 'sv',
        hasCustomColor: project.form.color && !millColours.includes(project.form.color)
      }
    )
  }

  private static getProjectServices(project: IProject): AssemblyPrice[] {
    const services: AssemblyPrice[] = []

    // Start fee
    services.push(new AssemblyPrice({
      type: 'startFee',
      perQuantityCustomer: START_FEE
    }))

    // Carrying service (start fee only)
    if (project.form.assemblyCarry) {
      services.push(new AssemblyPrice({
        type: 'carryStartFee',
        perQuantityCustomer: CARRY_PRICE_START_FEE
      }))
    }
    // De-packaging service (start fee only)
    if (project.form.assemblyWaste) {
      services.push(new AssemblyPrice({
        type: 'wasteStartFee',
        perQuantityCustomer: WASTE_PRICE_START_FEE
      }))
    }
    // Measuring service
    if (project.form.assemblyMeasure) {
      services.push(new AssemblyPrice({
        type: 'measure',
        perQuantityCustomer: MEASURING_SERVICE
      }))
    }

    return services
  }

  private static getCabinetPrices(project: IProject, cabinets: ProdboardCabinet[]): ItemPrice[] {
    return cabinets
      .map((cabinet: ProdboardCabinet) => {
        // Create item price
        const item = new ItemPrice('c', {
          name: `${cabinet.displayName} - ${cabinet.deSwLaSw}`,
          id: cabinet.uid,
          perQuantityCustomer: cabinet.basePrice,
          perQuantityFactory: cabinet.baseLabor,
          perQuantityCosts: cabinet.baseMaterial
        })

        // Assembly price per unit
        item.services.push(new AssemblyPrice({
          type: 'cabinetCount',
          perQuantityCustomer: CABINET_COUNT_PRICE
        }))
        // Assembly price per volume
        item.services.push(new AssemblyPrice({
          type: 'volume',
          perQuantityCustomer: VOLUME_PRICE,
          quantity: cabinet.volume
        }))
        // Carrying assembly price per volume
        if (project.form.assemblyCarry) {
          item.services.push(new AssemblyPrice({
            type: 'carry',
            perQuantityCustomer: CARRY_VOLUME_PRICE,
            quantity: cabinet.volume
          }))
        }
        // De-packaging assembly price per volume
        if (project.form.assemblyWaste) {
          item.services.push(new AssemblyPrice({
            type: 'waste',
            perQuantityCustomer: WASTE_VOLUME_PRICE,
            quantity: cabinet.volume
          }))
        }

        // Some cabinets have a special installation service
        item.services.push(...this.createCabinetServices(cabinet))

        // Cabinet options (only those with some price on them)
        item.options = this.createCabinetOptions(cabinet)

        // Special options
        item.specialOptions = this.createCabinetSpecialOptions(cabinet)

        // Cabinet comments (only those with some price on them)
        item.comments = this.createCommentPrices(cabinet.comments)

        return item
      })
      .filter(item => item.isValid)
  }

  private static getCounterTopMakePrices(project: IProject): ItemPrice[] {
    return (project.counterTops ?? [])
      .filter(ctm => ctm.type === 'make')
      .map(ctm => {
        const item = new ItemPrice('ctm', {
          id: ctm.id,
          name: ctm.customerDisplayName,
          quantity: ctm.area,
          perQuantityCustomer: ctm.price,
          perQuantityFactory: ctm.labor,
          manualCustomer: ctm.customPrice
        })

        // Plinth option if existent
        if (ctm.hasBakkant) {
          item.options.push(new OptionPrice({
            id: ctm.id + 'bakkant',
            name: ctm.bakkantString,
            quantity: ctm.bakkantArea,
            perQuantityCustomer: ctm.price,
            perQuantityFactory: ctm.labor,
            // If counter top has an overriding manual price, the option will
            // have no value, so it will have a manualCustomer = 0.
            manualCustomer: ctm.customPrice ?? null
          }))
        }

        // Comments
        item.comments = this.createCommentPrices(ctm.comments)

        // Installation services per area
        item.services.push(new AssemblyPrice({
          type: 'counterTopArea',
          perQuantityCustomer: COUNTER_TOP_AREA,
          quantity: ctm.area
        }))
        // Installation services per unit
        item.services.push(new AssemblyPrice({
          type: 'counterTopCount',
          perQuantityCustomer: COUNTER_TOP_COUNT
        }))

        return item
      })
      .filter(item => item.isValid)
  }

  private static getCounterTopBuyPrices(project: IProject): ItemPrice[] {
    return (project.counterTops ?? [])
      .filter(ct => ct.type === 'buy')
      .map(ctb => {
        const item = new ItemPrice('ctb', {
          id: ctb.id,
          name: ctb.customerDisplayName,
          perQuantityCustomer: ctb.price,
          perQuantityCosts: ctb.materialCost,
          // Counter top discount is in total SEK, we'll pass it to %
          discount: ctb.discount / ctb.price * 100
        })

        // Comments
        item.comments = this.createCommentPrices(ctb.comments)

        // Installation services per unit
        item.services.push(new AssemblyPrice({
          type: 'counterTopCount',
          perQuantityCustomer: COUNTER_TOP_COUNT
        }))

        return item
      })
      .filter(item => item.isValid)
  }

  private static getAppliancesPrices(applianceSuppliers: ISettingsConfigSupplier[], project: IProject): ItemPrice[] {
    return (project.appliances ?? [])
      .map(a => {
        // Get supplier associated to appliance, and some params from it
        const supplier = applianceSuppliers
          .find(s => s.name === a.supplier.name)

        const supplierName = supplier?.name ? `(${a.supplier.name})` : ''

        // Appliances always have costs price. And it is based on their
        // supplier's discount (Mill > Settings > Configuration > Suppliers)
        // Costs = (customer * (1 - VAT)) * (1 - discount_% / 100)
        const priceMinusVat =
          a.price * (1 - APPLIANCE_VAT_COST_PERCENTAGE)
        const applianceCosts =
          priceMinusVat * (1 - (supplier?.discount ?? 0) / 100)

        const item = new ItemPrice('a', {
          id: a.id,
          name: `${a.name} ${supplierName}`.trim(),
          // Appliances don't have factory price
          perQuantityCustomer: a.price,
          perQuantityCosts: applianceCosts,
          discount: a.discount,
          quantity: a.quantity
        })

        // Comments
        item.comments = this.createCommentPrices(a.comments)

        // Appliance comments should never have factory. It is old legacy thing
        item.comments.forEach(c => c.perQuantityFactory = 0)

        return item
      })
      .filter(item => item.isValid)
  }

  private static getFactoryExtrasPrices(project: IProject): ItemPrice[] {
    return (project.factoryExtras ?? [])
      .map(fe => new ItemPrice('fe', {
          id: fe.id,
          name: `projectTable_row_factoryExtra_${fe.extrasType}`,
          // Since we want the real price per unit, for Factory Extras we
          // need to get their total and divide by quantity.
          perQuantityCustomer: fe.totalCustomerPrice / fe.quantity,
          perQuantityFactory: fe.totalFactoryPrice / fe.quantity,
          perQuantityCosts: fe.totalMaterialPrice / fe.quantity,
          quantity: fe.quantity,
          manualCustomer: fe.setPrice,
          manualFactory: fe.setLabor
        })
      )
      .filter(item => item.isValid)
  }

  private static createCabinetOptions(cabinet: ProdboardCabinet): OptionPrice[] {
    return (cabinet.options ?? [])
      .filter(co => co.active)
      .map((cabinetOption) => {
        const option = new OptionPrice({
          id: this.getCabinetOptionId(cabinetOption),
          name: cabinetOption.title,
          perQuantityCustomer: cabinetOption.price,
          perQuantityFactory: cabinetOption.labor,
          perQuantityCosts: cabinetOption.material
        })

        // Cabinet option comments (only those with some price on them)
        option.comments = this.createCommentPrices(cabinetOption.comments)

        // Cabinet option services (installation on some options like LEDs)
        option.services.push(
          ...this.createCabinetOptionServices(cabinetOption))

        return option
      })
      .filter(o => o.isValid)
  }

  private static createCabinetSpecialOptions(cabinet: ProdboardCabinet): OptionPrice[] {
    return (cabinet.specialOptions ?? [])
      .map(so => new OptionPrice({
        id: `${cabinet.index}_${so.id}`,
        name: so.id,
        perQuantityCustomer: so.price,
        perQuantityFactory: so.labor
      }))
      .filter(o => o.isValid)
  }

  private static createCabinetServices(cabinet: ProdboardCabinet): AssemblyPrice[] {
    const services: AssemblyPrice[] = []

    // Installation service per category
    if (cabinet.cat) {
      const foundKey = Object.keys(CabinetCategoryMap)
        .find(key => cabinet.cat.includes(key))
      if (foundKey) {
        services.push(new AssemblyPrice(CabinetCategoryMap[foundKey]))
      }
    }

    // Installation service for those cabinets that are adapted for white-goods
    if (cabinet.whiteGoodsAdaptation && WhiteGoodsMap[cabinet.whiteGoodsAdaptation]) {
      services.push(new AssemblyPrice(WhiteGoodsMap[cabinet.whiteGoodsAdaptation]))
    }

    // Special case for dishwashers installation service
    if (cabinet.isDishwasherCabinet) {
      services.push(new AssemblyPrice({
        type: 'dishWasher',
        perQuantityCustomer: DISH_WASHER
      }))
    }

    return services
  }

  private static createCabinetOptionServices(cabinetOption: CabinetOption): AssemblyPrice[] {
    const services: AssemblyPrice[] = []

    // Normal options, which only have a single installation price.
    if (CabinetOptionsMap.has(cabinetOption.optionSelectName)) {
      services.push(new AssemblyPrice(
        CabinetOptionsMap.get(cabinetOption.optionSelectName)))
    }

    // Special case for Lighting, that could have two installation services,
    // for LEDs and for spotlights
    if (cabinetOption.optionSelectName === 'Lighting') {
      const lighting = cabinetOption as Lighting
      if (lighting.ledInside || lighting.ledUnderneath) {
        services.push(new AssemblyPrice({
          type: 'leds',
          perQuantityCustomer: LEDS
        }))
      }
      if (lighting.spots) {
        services.push(new AssemblyPrice({
          type: 'spots',
          perQuantityCustomer: SPOTS
        }))
      }
    }
    return services
  }

  private static createCommentPrices(comments: Comment[]): CommentPrice[] {
    return (comments ?? [])
      .map((c, index) => new CommentPrice({
        id: c.id,
        name: `#${index + 1} - ${c.comment ?? c.translation ?? ''}`,
        perQuantityCustomer: c.price,
        perQuantityFactory: c.labor,
        perQuantityCosts: c.material
      }))
      .filter(c => c.isValid)
  }

  private static getItemClassFromType(
    type: TBaseItemSection
  ): new (...args: any) => BaseItemPrice {
    let clazz: (new (...args: any) => BaseItemPrice) = ItemPrice
    if (type === 'option') {
      clazz = OptionPrice
    }
    if (type === 'service') {
      clazz = AssemblyPrice
    }
    if (type === 'comment') {
      clazz = CommentPrice
    }
    return clazz
  }

  private static getAllSubitemsFromItem<T extends BaseItemPrice>(
    baseItem: T | T[],
    excludedSections: string[]
  ) {
    const getAllSubItems = (item: T): T[] => {
      const subItems = Object.keys(item)
        .filter(key => !excludedSections.includes(key))
        .filter(key => this.isBaseItemArray(item[key]))
        .map((key: string): T[] => item[key])
        .flatMap(subItems => subItems
          .flatMap(si => getAllSubItems(si)))
      return [item, ...subItems]
    }
    return Array.isArray(baseItem) ?
      baseItem.flatMap(item => getAllSubItems(item)) :
      getAllSubItems(baseItem)
  }

  private static getAllItemsWithSameClass<T extends BaseItemPrice>(
    array: BaseItemPrice[],
    clazz: new (...args: any) => T
  ): T[] {
    return array
      .flatMap((baseItem: BaseItemPrice): T[] => {
        // First check if baseItem is already of the type we want.
        // If so, just return it.
        if (baseItem instanceof clazz) {
          return [baseItem]
        }
        // If not, we will get all properties inside the object that are, again,
        // an array of BaseItemPrice, and we will investigate them for more
        // items of the class we want.
        return Object.keys(baseItem)
          // Filter for BaseItemPrice arrays
          .filter(key => this.isBaseItemArray(baseItem[key]))
          .flatMap((key): T[] =>
            // For every array of BaseItemPrice we call "getAllOfTypeFromArray",
            // which is basically going deeper and deeper in the objects.
            ProjectPricingUtils.getAllItemsWithSameClass(baseItem[key], clazz))
      })
  }

  private static isBaseItemArray(prop: any): boolean {
    return Array.isArray(prop) &&
      prop.length > 0 &&
      prop[0] instanceof BaseItemPrice
  }
}
