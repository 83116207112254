import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {BehaviorSubject, finalize, forkJoin, NEVER, Observable, of} from 'rxjs'
import {catchError, filter, map, switchMap, tap} from 'rxjs/operators'
import {environment} from '../../environments/environment'
import {CustomerStateMap} from '../customer/customer-state-map'
import {ICustomerProject} from '../customer/customer-types'

import {CustomerProject} from '../customer/model/customer-project.class'
import {IProjectImage} from '../images/model/project-image'
import {PhaseTag} from '../model/tags/phase-tag'
import {ProdboardQuoteTag} from '../model/tags/prodboard-quote-tag'
import {ITag, PB_QUOTE_REQ_ID, PHASE_TAG_ID} from '../model/tags/types'
import {UserTag} from '../model/tags/user-tag'
import {AuthService} from './auth.service'
import {ProblemService} from './problem.service'
import {ProdboardFileService, ProdboardListItem} from './prodboard-file.service'
import {IProjectFile} from './project-file-types'
import {ProjectFileUtils} from './project-file-utils'
import {IProject, IProjectBase} from './project-types'
import {SettingsService} from './settings.service'
import {TagService} from './tag.service'

/**
 * This service is responsible for two things and two things only
 * 1. It can save, get, delete projects and files.
 * 2. It can receive prodboard files and convert them to cabinets.
 *
 * Everything else, that has to bother about the logic of these items
 * has, shall and must be handled elsewhere.
 *
 */
@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  /**
   * Let innocent bystanders get all projects from here.
   */
  public projects$: BehaviorSubject<IProjectBase[]> = new BehaviorSubject<IProjectBase[]>([])

  /**
   * Yet another semaphore to prevent double save ...
   */
  private isSavingProject = false
  private isSavingFile = false

  constructor(
    private httpClient: HttpClient,
    private prodboardService: ProdboardFileService,
    private problemService: ProblemService,
    private settingsService: SettingsService,
    private authService: AuthService,
    private prodboardFileService: ProdboardFileService
  ) {
  }

  public static newProject(id?: string): IProject {
    return {
      appliances: [],
      externalAppliances: [],
      cabinets: {},
      comments: [],
      customer: {} as any,
      projectPhase: CustomerStateMap.FIRST_STATE_LABEL,
      fileId: '',
      form: {} as any,
      id,
      images: [],
      counterTops: [],
      timeStamp: 0,
      version: 0,
      tags: [],
      factoryExtras: [],
      faConfigs: [],
      priceLockTime: null,
      modifiedBy: ''
    }
  }

  /**
   * Fetch a project from the server. This means that we start over on files
   * and project. A project that is fetched must have both version and id.
   *
   * It first fetches the project, then the file attached to that project.
   * When we have the project we emit that. But we then have to wait for the
   * file to load and create the Cabinets.
   */
  public getProject(id: string, version: string): Observable<[IProjectBase, IProjectFile]> {
    return this.getProjectFromServer(id, version)
  }

  public getProjectVersions(id: string): Observable<IProject[]> {
    const url = `${environment.productUrl}/projects/${id}`
    return this.httpClient.get<IProject[]>(url)
  }

  /**
   * 1. Create one Project and one File on the server.
   * 2. Submit the requests in parallell
   * 3. Get both results back (forkJoin)
   * 4. In parallell on the server, update the file with the project and vice versa.
   * 5. Then we return the newly created project. We could return just about
   *    anything
   *
   * Note that it all depends on that we already have a file.
   *
   * @param projectBase - The project is basically empty but has a name (hopefully)
   * @param file - Prodboard file that has been updated in ProdboardUpload first
   */
  public createProject(projectBase: IProjectBase, file: IProjectFile): Observable<IProjectBase> {
    // Add logged-in user as owner. And set the base phase to A
    projectBase.tags = [
      {id: this.authService.currentUser$().email, type: 'u'} as UserTag,
      {
        id: PHASE_TAG_ID,
        type: 'ph',
        state: CustomerStateMap.FIRST_STATE_LABEL
      } as PhaseTag
    ]

    // Create project from server with received file
    return this.createProjectOnServer(projectBase, file)
  }

  /**
   * Delete a project including linked resources, we should delete
   * images and appliances as well. Million-dollar question is if we
   * should do this shite on backend instead.
   *
   * @param project
   */
  public deleteProject(project: IProjectBase): Observable<any> {
    const projectUrl = `${environment.productUrl}/projects/${project.id}`
    const fileUrl = `${environment.productUrl}/files/${project.fileId}`
    const customerProjectUrl = `${environment.productUrl}/customer-projects/${project.customerProjectId}`
    return forkJoin([
      this.httpClient.delete<any>(customerProjectUrl),
      this.httpClient.delete<any>(projectUrl),
      this.httpClient.delete<any>(fileUrl)
    ])
      .pipe(
        tap(() => {
          this.getProjects()
        })
      )
  }

  /**
   * Blindly just send a "project" to the backend. In theory, we get the 'updated'
   * project back. We namely need the new version to be able to update the routes.
   */
  public updateProject(project: IProject, force = false): Observable<IProject> {
    if (this.isSavingProject && !force) {
      return of(project)
    }
    this.isSavingProject = true
    // Make sure that the object you send to update is a copy of real one.
    // It seems like a "soft-copy", {...object}, is enough.
    const projectToUpdate = {...project}
    // Do not save long image urls for viewing.
    projectToUpdate.modifiedBy = this.authService.currentUser$().name
    projectToUpdate.images.forEach((im: IProjectImage) => delete im.viewUrl)
    // We must not, ever, save the complete tags
    // But many times they come here as false tags.
    projectToUpdate.tags = projectToUpdate.tags.map(t => {
      if (t.getRawValue) {
        return t.getRawValue()
      }
      return t
    })
    // Remove som properties that we do not want
    delete projectToUpdate.projectPhase
    delete projectToUpdate.pricing
    delete (projectToUpdate as any).hasMissingState
    delete (projectToUpdate as any).isWaitingForCustomer
    const url = `${environment.productUrl}/projects/${projectToUpdate.id}`
    return this.httpClient.put<IProject>(url, projectToUpdate).pipe(
      finalize(() => this.isSavingProject = false)
    )
  }

  public updateFile(file: IProjectFile): Observable<IProjectFile> {
    if (this.isSavingFile) {
      return of(file)
    }
    this.isSavingFile = true
    const url = `${environment.productUrl}/files/${file.id}`
    return this.httpClient.put<IProjectFile>(url, file).pipe(
      tap(() => {
        this.isSavingFile = false
      })
    )
  }

  public getOneProject(id: string): Observable<IProject> {
    const url = `${environment.productUrl}/projects/${id}/$LATEST`
    return this.httpClient.get<IProject>(url)
  }

  /**
   * Make needed conversions and filtering
   *
   * @param projects
   */
  private filterProjectNames(projects: IProjectBase[]): IProjectBase[] {
    projects
      .filter((project: IProject) => !!project)
      .forEach((project: IProjectBase) => {
        project.customer = project.customer || {name: ''} as any
        // Convert saved tags to real tags.
        project.tags = TagService.createTags(project.tags ?? [], project)
        /**
         * Until we have sorted this out!
         */
        this.tagToPhase(project)
      })
    /**
     * Remove projects that should not be seen.
     */
    if (this.authService.isAgentOnly()) {
      const email = this.authService.currentUser$().email
      projects = projects
        .filter(p => p.tags
          .filter(t => t.type === 'u')
          .find(t => t.id === email))
    }
    return projects
  }

  /**
   * Simple get that fetches all projects
   */
  public getProjects(): void {
    // First, reset previous projects
    this.projects$.next(null)

    const url = `${environment.productUrl}/projects`
    this.httpClient.get<IProjectBase[]>(url)
      .subscribe({
        next: (projects: IProjectBase[]) => {
          this.getProdboardProjects()
          this.projects$.next(this.filterProjectNames(projects))
        }
      })
  }

  private getProdboardProjects(): void {
    this.prodboardService.getOrders()
      .pipe(
        filter(orders => orders.length > 0),
        switchMap((orders: ProdboardListItem[]) =>
          // We have one or more orders, but we create only one project
          this.createProjectFromProdboard(orders[0])
        )).subscribe()
  }

  /**
   * Fetches and fixes the Prodboard file from the quote/order.
   * Deletes the original so that it will not be created again.
   */
  private getProdboardFile(id: string): Observable<IProjectFile> {
    return this.prodboardFileService.getFile(id).pipe(
      switchMap((fileFromProdboard) => {
        const file = ProjectFileUtils.fixFile(
          ProjectFileUtils
            .createNewProjectFile(JSON.stringify(fileFromProdboard)),
          null)
        file.serverId = id

        return forkJoin([
          of(file),
          this.prodboardFileService.deleteFile(id)
        ])
      }),
      map((res: [IProjectFile, void]) => {
        return res[0]
      })
    )
  }

  private getProjectFromServer(id: string, version: string): Observable<[IProjectBase, IProjectFile]> {
    let baseProject: IProjectBase

    let needToSaveAfterFix: boolean = false

    const url = `${environment.productUrl}/projects/${id}/${version}`
    return this.httpClient.get<IProjectBase>(url)
      .pipe(
        switchMap((p) => {
          // Save recovered project
          baseProject = p
          // Then, recover project file
          const fileUrl = `${environment.productUrl}/files/${p.fileId}/$LATEST`
          return this.httpClient.get<IProjectFile>(fileUrl)
        }),
        tap((file) => needToSaveAfterFix = !file.isMilf),
        // Always fix file before use
        map((file) => ProjectFileUtils.fixFile(file, null)),
        switchMap((file) => {
          if (needToSaveAfterFix) {
            return this.updateFile(file)
          } else {
            return of(file)
          }
        }),
        map((file): [IProjectBase, IProjectFile] => {
          return [baseProject, file]
        }),
        catchError((e) => {
          if (e.message.includes('Error mapping file')) {
            this.problemService.problems$.next({
              description: e.message, handled: false
            })
          } else {
            this.problemService.problems$.next({
              description: 'Kunde inte öppna projektet', handled: false
            })
          }
          return NEVER
        })
      )
  }

  private createProjectFromProdboard(order: ProdboardListItem): Observable<any> {
    const project = ProjectService.newProject()
    project.customer = {
      name: `Quote request via Prodboard: ${order.clientName}`,
      phone: '',
      email: order.clientEmail,
      url: order.url,
      prodboardId: order.prodboardId?.toString(),
      prodboardNumber: order.prodboardNumber
    }
    project.tags = [
      new PhaseTag().getRawValue(),
      new ProdboardQuoteTag().getRawValue()
    ]

    // Recover Prodboard file from order's ID and then create project on server
    return this.getProdboardFile(order.id)
      .pipe(
        switchMap((file) =>
          this.createProjectOnServer(project, file))
      )
  }

  private createProjectOnServer(projectBase: IProjectBase, file: IProjectFile): Observable<IProject> {
    // We always make sure that file has type "F". It's a DB must-have sorting
    file.type = 'F'

    return this.httpClient.put<IProjectBase>(`${environment.productUrl}/projects`, projectBase)
      .pipe(
        switchMap((createdProject: IProject) => {
          Object.assign(projectBase, createdProject)
          return this.createAndSetProdboardFile(file, projectBase)
        }),
        switchMap((updateRes: [IProjectFile, IProject]) => {
          return this.createAndSetCustomerProject(updateRes[1])
        }),
        switchMap((project: IProject) => {
          // Save the project with CP-id and File-id
          return this.updateProject(project)
        })
      )
  }

  /**
   * Create a new file, set the file id on the project and vice versa
   * then save the file but _not_ the project. It returns both the file
   * and the project.
   */
  private createAndSetProdboardFile(fileBase: IProjectFile, project: IProjectBase): Observable<[IProjectFile, IProjectBase]> {
    const url = `${environment.productUrl}/files`
    return this.httpClient.put<IProjectFile>(url, fileBase)
      .pipe(switchMap((file: IProjectFile) => {
          project.fileId = file.id
          file.projectId = project.id
          return this.httpClient
            .put<IProjectFile>(`${url}/${file.id}`, file)
        }),
        map((file: IProjectFile) => [file, project])
      )
  }

  private createAndSetCustomerProject(project: IProject): Observable<IProjectBase> {
    // Since we cannot include CustomerService (circular dependency) we use the Settings service
    // to create and update objects
    const cp = new CustomerProject()
    cp.projectId = project.id
    return this.settingsService
      .createItem<ICustomerProject>('customer-projects', cp.getSaveData())
      .pipe(
        map((customerProject: ICustomerProject) => {
          project.customerProjectId = customerProject.id
          return project
        })
      )
  }

  /**
   * You better be damn sure that the project actually
   * have this tag!
   *
   * This must be called after migration as well since we
   * should not save the state outside the tag.
   *
   * @param project
   * @private
   */
  private tagToPhase(project: IProjectBase): void {
    project.projectPhase =
      project.tags.find((t: ITag): t is PhaseTag => t.id === PHASE_TAG_ID)?.state ||
      CustomerStateMap.FIRST_STATE_LABEL
    project.isOrder =
      project.tags.some((t: ITag) => t.id === PB_QUOTE_REQ_ID)
  }
}
