import {Component, DestroyRef, EventEmitter, Input, OnChanges, Output} from '@angular/core'
import {DateTime} from 'luxon'
import {FormControl, Validators} from '@angular/forms'
import {skip} from 'rxjs'
import {tap} from 'rxjs/operators'
import {takeUntilDestroyed} from '@angular/core/rxjs-interop'

import {originalOrder} from '../../application/helpers'

const day = 60 * 60 * 24 * 1000


@Component({
  selector: 'kdl-snooze',
  templateUrl: './snooze.component.html',
  styleUrl: './snooze.component.scss'
})
export class SnoozeComponent implements OnChanges {

  /**
   * Add the current date as an ISO-string
   */
  @Input() dueDate: string = new Date().toISOString()

  @Input() suppressConfirmAction = false

  /**
   * Emits a DateTime on every change.
   */
  @Output() selection = new EventEmitter<DateTime>()
  public presets = new Map<string, DateTime>([
    ['Today', DateTime.fromMillis(Date.now())],
    ['Tomorrow', DateTime.fromMillis(Date.now() + day)],
    ['Next week', DateTime.fromMillis(Date.now() + day * 7)],
    ['Two weeks', DateTime.fromMillis(Date.now() + day * 14)],
    ['Next month ', DateTime.fromMillis(Date.now() + day * 30)],
    ['In two months', DateTime.fromMillis(Date.now() + day * 30 * 2)],
    ['In six months ', DateTime.fromMillis(Date.now() + day * 30 * 6)],
    ['Next year', DateTime.fromMillis(Date.now() + day * 365)]
  ])
  public control = new FormControl<DateTime>(DateTime.fromISO(this.dueDate),
    {validators: [Validators.required]})
  protected readonly originalOrder = originalOrder

  constructor(private destroyRef: DestroyRef) {
    this.control.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        skip(1),
        tap((val) => {
          // Update the form control to set the date selector.
          this.control.setValue(val, {emitEvent: false})
          this.selection.emit(val)
        }),
      )
      .subscribe()
  }

  public ngOnChanges(): void {
    this.control.setValue((DateTime.fromISO(this.dueDate)))
  }
}
