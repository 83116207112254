export const PRODUCT_HOME_PATH = 'products'
export const FACTORY_INTERNAL_HOME_PATH = 'factory-internal'
export const CUSTOMER_INTERNAL_HOME_PATH = 'customer-internal'
export const LOGIN_HOME_PATH = 'login'
export const USER_HOME_PATH = 'user'
export const ADMIN_HOME_PATH = 'dashboard'
export const SETTINGS_HOME_PATH = 'settings'
export const EDIT_PROJECT_HOME_PATH = 'edit-project'
export const CUSTOMER_HOME_PATH = 'customer'
export const PRODBOARD_HOME_PATH = 'prodboard'

export const OPEN_PROJECT_WARNINGS_PATH = 'warnings'
export const OPEN_PROJECT_CABINETS_PATH = 'cabinets'
export const OPEN_PROJECT_PROJECT_PATH = 'settings'
