import {ReplaySubject} from 'rxjs'
import {TBallTagOwner} from '../../model/tags/balls-tag'
import {
  CAD_CHECKED_BY_CUSTOMER,
  CAD_DRAWINGS_FIRST_VERSION,
  CAD_PROOFREADING_FINISHED,
  CAD_UPDATED_AFTER_CUSTOMER,
  CAD_UPDATED_AFTER_PROOFREADING,
  CONFIRMED_MESSAGE_SENT,
  CREATE_SLACK_CHANNEL_ID,
  CSC_ADD_NUMBER_TO_PROJECT,
  CSC_AGACCO_ECONOMY_SHEET,
  CSC_ANYTHING_ELSE_TO_ORDER,
  CSC_CAD_PROOFREAD_KULLADAL,
  CSC_CAD_PROOFREAD_KULLADAL_UPDATED,
  CSC_CAD_SENT_TO_CUSTOMER,
  CSC_CARPENTRY_PICKUP,
  CSC_CUSTOMER_DELIVERY_DATE,
  CSC_CUSTOMER_INFO_EMAIL,
  CSC_CUSTOMER_STENY,
  CSC_DRIVE_CREATE_FOLDERS,
  CSC_EXPORT_ORDERSHEET,
  CSC_FITTING_INSTRUCTION_PDF,
  CSC_INSERT_CARPENTRY_DELIVERY_SCHEDULE,
  CSC_INSERT_DELIVERY_SCHEDULE,
  CSC_INVOICE_2_OF_3,
  CSC_LIGHTS_PACKED,
  CSC_NUMBERS_IN_NYA_EKONOMIN,
  CSC_ORDER_SPECIAL_PAINT,
  CSC_PAINT_EMAIL_TO_CUSTOMER,
  CSC_PROOFREADER_SENT,
  CSC_SEND_INVOICE_1,
  CSC_SENT_QUOTE_TO_CUSTOMER,
  CSC_TRANSPORTER_PICKUP,
  CSC_UPLOAD_PRODBOARD_FILE,
  CustomerStateMap,
  HAS_CUSTOMER_RESPOND,
  KITCHEN_DELIVERED,
  PAINTING_FINISHED,
  REMIND_THE_CUSTOMER,
  REMIND_THE_CUSTOMER_AGAIN,
  SEND_QUOTE_TO_CUSTOMER,
  WAITING_FOR_CUSTOMER_QUOTE_ACCEPT
} from '../customer-state-map'
import {
  CustomerStateLabel,
  CustomerStateName,
  ICustomerProject,
  ICustomerState,
  ICustomerStateCondition
} from '../customer-types'
import {CrossState} from './cross-state'

export interface IActionTagItem {
  /**
   * The due date as unix epoch
   */
  ts: number

  /**
   * This is a back reference to the source item.
   */
  id: string

  /**
   * The description of the condition
   */
  d: string
}

/**
 * The map uses a BallTagOwner as key, the value is a key pair of conditions,
 * if the first is checked but not the other, then that should mean add a tag.
 *
 */
const mapOfOwners = new Map<TBallTagOwner, [string, string][]>([
  ['customer', [
    // If remind is true and to continue is false ...
    [CSC_SENT_QUOTE_TO_CUSTOMER, WAITING_FOR_CUSTOMER_QUOTE_ACCEPT],
    [SEND_QUOTE_TO_CUSTOMER, REMIND_THE_CUSTOMER],
    [REMIND_THE_CUSTOMER, REMIND_THE_CUSTOMER_AGAIN],
    [CSC_CAD_SENT_TO_CUSTOMER, CAD_CHECKED_BY_CUSTOMER]
  ]
  ],
  ['cad', [
    [CREATE_SLACK_CHANNEL_ID, CAD_DRAWINGS_FIRST_VERSION],
    [CAD_PROOFREADING_FINISHED, CAD_UPDATED_AFTER_PROOFREADING],
    [CAD_CHECKED_BY_CUSTOMER, CAD_UPDATED_AFTER_CUSTOMER],
    [CSC_CAD_PROOFREAD_KULLADAL, CSC_CAD_PROOFREAD_KULLADAL_UPDATED]
  ]
  ],
  ['proofreader', [[CSC_PROOFREADER_SENT, CAD_PROOFREADING_FINISHED]]],
  ['carpentry', [[CONFIRMED_MESSAGE_SENT, KITCHEN_DELIVERED]]],
  ['painter', [[KITCHEN_DELIVERED, PAINTING_FINISHED]]],
  ['me', [
    [CSC_UPLOAD_PRODBOARD_FILE, SEND_QUOTE_TO_CUSTOMER],
    [WAITING_FOR_CUSTOMER_QUOTE_ACCEPT, CSC_CUSTOMER_STENY],
    [WAITING_FOR_CUSTOMER_QUOTE_ACCEPT, CSC_LIGHTS_PACKED],
    [WAITING_FOR_CUSTOMER_QUOTE_ACCEPT, CSC_CUSTOMER_DELIVERY_DATE],
    [WAITING_FOR_CUSTOMER_QUOTE_ACCEPT, CSC_CUSTOMER_INFO_EMAIL],
    [WAITING_FOR_CUSTOMER_QUOTE_ACCEPT, CSC_CARPENTRY_PICKUP],
    [WAITING_FOR_CUSTOMER_QUOTE_ACCEPT, CSC_TRANSPORTER_PICKUP],
    [WAITING_FOR_CUSTOMER_QUOTE_ACCEPT, CSC_PAINT_EMAIL_TO_CUSTOMER],
    [WAITING_FOR_CUSTOMER_QUOTE_ACCEPT, CSC_FITTING_INSTRUCTION_PDF],
    [WAITING_FOR_CUSTOMER_QUOTE_ACCEPT, CSC_INVOICE_2_OF_3],
    [WAITING_FOR_CUSTOMER_QUOTE_ACCEPT, CSC_EXPORT_ORDERSHEET],
    [WAITING_FOR_CUSTOMER_QUOTE_ACCEPT, CSC_ADD_NUMBER_TO_PROJECT],
    [WAITING_FOR_CUSTOMER_QUOTE_ACCEPT, CREATE_SLACK_CHANNEL_ID],
    [WAITING_FOR_CUSTOMER_QUOTE_ACCEPT, CSC_DRIVE_CREATE_FOLDERS],
    [WAITING_FOR_CUSTOMER_QUOTE_ACCEPT, CSC_INSERT_DELIVERY_SCHEDULE],
    [WAITING_FOR_CUSTOMER_QUOTE_ACCEPT, CSC_INSERT_CARPENTRY_DELIVERY_SCHEDULE],
    [WAITING_FOR_CUSTOMER_QUOTE_ACCEPT, CSC_NUMBERS_IN_NYA_EKONOMIN],
    [WAITING_FOR_CUSTOMER_QUOTE_ACCEPT, CSC_SEND_INVOICE_1],
    [WAITING_FOR_CUSTOMER_QUOTE_ACCEPT, CSC_AGACCO_ECONOMY_SHEET],
    [WAITING_FOR_CUSTOMER_QUOTE_ACCEPT, CSC_ANYTHING_ELSE_TO_ORDER],
    [WAITING_FOR_CUSTOMER_QUOTE_ACCEPT, CSC_ORDER_SPECIAL_PAINT],
    [CAD_DRAWINGS_FIRST_VERSION, CSC_CAD_PROOFREAD_KULLADAL],
    [CAD_DRAWINGS_FIRST_VERSION, CSC_PROOFREADER_SENT]
  ]]
])

/**
 * A Customer Project can exist w/o project or Customer
 */
export class CustomerProject implements ICustomerProject {
  public id = ''
  public version = -1
  public type = 'CSTP' as const

  // Take name from the project or from the customer?
  public name = ''

  public projectId: string

  public states: ICustomerState[] = []

  public customerId: string

  public customerName: string

  public notes: string

  /**
   * If this has been archived then special rules apply.
   */
  public archived = false

  /**
   * Tells if there are incomplete states before the
   * highest state that is complete. E.g. A,B and D are
   * complete but not C then this is hollow.
   */
  public isHollow = false

  public nextStateLabel$: ReplaySubject<CustomerStateLabel> = new ReplaySubject<CustomerStateLabel>(1)

  public nextStateName: CustomerStateName

  public stateItems: ICustomerStateCondition[]

  /**
   * Populate this with info on missing infos.
   */
  public waitMap = new Map<TBallTagOwner, IActionTagItem>()

  /**
   * The complete state.
   */
  private customerStateMap: CustomerStateMap = new CustomerStateMap()

  private conditionsMap: Map<string, ICustomerStateCondition> = new Map()

  constructor(customerProject: ICustomerProject = {} as any) {
    Object.assign(this, customerProject)

    this.initialiseStates(customerProject)

    // Special case for initial state that have a selector for project id.
    // We can use "!" after find because previous methods have ensured that
    // we have all states added, even new ones.
    this.states.find(s =>
      s.state.state === CustomerStateMap.FIRST_STATE_LABEL).projectId =
      this.projectId

    this.stateItems = this.getStateItems()
    this.createConditionMap()
    this.nextState()
  }

  public setCustomColor(customColor: boolean): boolean {
    let stateToChange: ICustomerStateCondition
    this.states.forEach((state: ICustomerState) => {
      stateToChange = state.conditions
        .filter(c => c.stateIds)
        .find((condition: ICustomerStateCondition) => condition.stateIds[0] === 'SPECIAL_PAINT') || stateToChange
    })
    const initial = stateToChange.notApplicable
    stateToChange.notApplicable = !customColor
    stateToChange.completed = !customColor
    return initial !== stateToChange.notApplicable
  }

  public setColorProcess(completePaint: boolean): void {
    let stateToChange: ICustomerStateCondition
    this.states.forEach((state: ICustomerState) => {
      stateToChange = state.conditions
        .filter(c => c.stateIds)
        .find((condition: ICustomerStateCondition) =>
          condition.stateIds[0] === 'PAINT_FINISHED') || stateToChange
    })
    // Mark it as "Not Applicable" and set it to complete
    // if it is not a paint where paint is needed.
    stateToChange.notApplicable = !completePaint
    stateToChange.completed = !completePaint
  }

  public getStateByLabel(label: CustomerStateLabel): ICustomerState {
    return this.states.find((state: ICustomerState) => state.state.state === label)
  }

  /**
   * Return only what you want to save on the server.
   * When creating it was the stateCodes and StateMap.
   */
  public getSaveData(): ICustomerProject {
    const result = {...this}
    delete result.nextStateLabel$
    delete result.customerStateMap
    delete result.stateItems
    delete result.conditionsMap
    delete result.waitMap
    result.states = result.states.map(s => {
      const res = {...s}
      delete res.descriptionEnd
      res.state = {state: s.state.state} as any
      return res
    })
    return result
  }

  /**
   * Review all states to see if they are complete or not.
   * Should only be called when one or more of the states
   * have changed.
   */
  public updateState(): void {
    const allDone = this.states
      .map((state: ICustomerState) => {
        this.setState(state)
        return state.completed
      })
      .slice(0, -2) // Only look at not archived
      .reduce((acc: boolean, next: boolean) => acc && next, true)
    // Archive state if all states are complete
    if (allDone) {
      // All completed means customer archive (H) which is second from last
      const archiveState: ICustomerState = this.states[this.states.length - 2]
      archiveState.completed = true
      this.archived = true
    }
    // Figure out if next ...
    this.nextState()
  }

  /**
   * Returns the state object (name, color, icon etc.) for the
   * current state.
   */
  public currentState(): CustomerStateName {
    let best = this.nextState()
    /**
     * If we are already archived we cannot have a better state.
     */
    if (this.archived) {
      best = this.bestState()
    }
    return this.customerStateMap.getState(best).state
  }

  /**
   * Returns the highest completed state.
   */
  public bestState(): CustomerStateLabel {
    this.isHollow = false
    let bestState: CustomerStateLabel = CustomerStateMap.FIRST_STATE_LABEL
    const completed = this.states
      .filter((state: ICustomerState) => state.completed)
    if (completed.length === 0) {
      return bestState
    }
    bestState = completed[completed.length - 1].state.state
    const bestStateIndex = this.states.findIndex((state: ICustomerState) => state.state.state === bestState)
    this.isHollow = completed.length !== bestStateIndex + 1
    return bestState
  }

  /**
   * Archives the project, always set it to "skissakrviet" (I)
   * that is the last state.
   */
  public archive(): void {
    const archiveState: ICustomerState = this.states
      .find(s => s.state.state === 'I')
    archiveState.completed = true
    archiveState.conditions.forEach((condition: ICustomerStateCondition) => condition.completed = true)
    this.archived = true
  }

  /**
   * Restore this to the last best state.
   */
  public unArchive(): void {
    // We currently know that the last two states are archives
    // can be improved.
    this.states.slice(-2)
      .forEach((state: ICustomerState) => {
        state.completed = false
        state.conditions.forEach((c: ICustomerStateCondition) => c.completed = false)
      })
    this.archived = false

    /**
     * Special case:
     * If we return to state/phase "PRE_A", in which we have the condition
     * to select if the customer is silent or not, we will unselect this
     * option manually here. It is a way of making in it "kinda-mandatory"
     */
    const returnState =
      this.states.find(s => s.state.state === this.nextState())
    const condition = returnState.conditions
      .find(c => c.id === HAS_CUSTOMER_RESPOND)
    if (condition) {
      condition.completed = false
      delete condition.selection
    }
  }

  /**
   * Set all items to completed in a given state
   */
  public completeState(stateLabel: CustomerStateLabel): void {
    const state = this.getStateByLabel(stateLabel)
    state.conditions.forEach((c: ICustomerStateCondition) => c.completed = true)
    state.completed = true
  }

  /**
   * Checks if all conditions in a state is complete
   */
  public checkAllConditionsComplete(state: ICustomerState): boolean {
    return state.conditions
      .map((condition: ICustomerStateCondition) => condition.completed || condition.optional || condition.notApplicable)
      .reduce((acc: boolean, completed: boolean) => acc && completed, true)
  }

  public updateStateTimeStamp(id: string, timeStamp: number): boolean {
    const condition = this.getConditionById(id)
    if (condition) {
      if (condition.deadline !== timeStamp) {
        condition.deadline = timeStamp
        return true
      }
    }
    return false
  }

  /**
   * A special case of interest if we only wait for sign off
   * from customer. This is state 3 (C) and the
   */
  public isWaitingForCustomer(): boolean {
    return !!this.checkAdverseCondition(CSC_SENT_QUOTE_TO_CUSTOMER, WAITING_FOR_CUSTOMER_QUOTE_ACCEPT)
  }

  public shouldHavePQTag(): boolean {
    // It will basically always find the tag??
    return !this.getConditionById(SEND_QUOTE_TO_CUSTOMER)?.completed
  }


  /**
   * Review all special cases where the next tag not checked
   * triggers a wait state for the next.
   */
  public checkForWaitingStates(): void {
    // Clear the map
    this.waitMap.clear()

    // Iterate over the keys in the map. For each entry there is a pair of checked if
    // next is checked. They return null if not so or the item if they are. If one
    // or more is we add them to the map.
    //
    // The reverse is to make sure we get the latest, this can be argued...
    for (const k of mapOfOwners) {
      // k[1] is the array of set -> checked pairs.
      k[1].map(t => this.checkAdverseCondition(...t))
        //.reverse() // Make sure the latest is first
        .filter(c => c) // Remove nulls
        .filter(c => !c.notApplicable) // Ignore the not applicable
        .sort((res, cur) => cur.deadline - res.deadline)
        .forEach(p => {
          // Since the resulting map can only have one the last one will win.
          this.waitMap.set(k[0], {
            ts: p.deadline ?? Date.now() + 60 * 60 * 24 * 14 * 1000,
            id: p.id,
            d: p.label
          })
        })
    }
  }

  /**
   * See if there are any cross-states to update
   * @param condition
   */
  public updateRelatedStates(condition: ICustomerStateCondition): void {
    // Populate the conditions map that should have direct access to
    // all conditions by id that are hopefully unique
    CrossState.checkCrossState(condition, this.conditionsMap)
  }

  public getConditionById(id: string): ICustomerStateCondition | undefined {
    return this.conditionsMap.get(id)
  }

  private initialiseStates(customerProject: ICustomerProject) {
    // It happens that we get undefined from server
    this.states = (customerProject?.states || [])
      .filter(s => !!s)
      .map(s => {
        // Conditions must always be an array, even if empty
        return {...s, conditions: s.conditions || []}
      })

    // Get current highest completed state, which is "bestState".
    // It will be used later to complete new added states if needed.
    // Also, get the original length of states array. We need it later to
    // compare if any state have been added or removed.
    const initialLength = this.states.length
    const bestState: ICustomerState | undefined = this.getStateByLabel(this.bestState())

    // Fill all states, using server states when present and template ones
    // when not.
    this.states = this.customerStateMap.labels
      .map((label: CustomerStateLabel) =>
        this.states.find(s => s.state.state === label)
        ?? {...this.customerStateMap.getState(label)})

    // Aux method to find conditions that are present in other states different
    // from the one passed. This is used when a condition is defined in
    // customer-state-map.ts in other state, so it will need to be moved.
    const isConditionInOtherState = (
      stateToAvoid: CustomerStateLabel, cId: string
    ): [ICustomerState, ICustomerStateCondition] | undefined => {
      let state: ICustomerState | undefined
      let condition: ICustomerStateCondition | undefined
      this.states
        .filter(s => s.state.state !== stateToAvoid)
        .forEach(s => {
          const found = s.conditions.find(c => c.id === cId)
          if (found) {
            condition = found
            state = s
          }
        })
      return (state && condition) ? [state, condition] : undefined
    }

    /**
     * Go through all current states, updating them with the template values.
     * All "static" fields are updated without any remorse. However, we need
     * to be careful with "dynamic" values, they are important to be kept.
     *
     * Also, some conditions can move between states. In those cases, we will
     * need to remove from the state that is now, and add it to the new
     * template state.
     * "It is complex, annoying, and very, very Picasso.
     * Sorry for that" - Darío 02/05/2024.
     */
    this.states.forEach(currentState => {
      // Get the template state to use as reference for values
      const templateState =
        this.customerStateMap.getState(currentState.state.state)

      // Update all "static" fields (ICustomerStateStatic)
      currentState.state = templateState.state
      currentState.descriptionEnd = templateState.descriptionEnd

      templateState.conditions.forEach(tmpCond => {
        const currentCond = currentState.conditions
          .find(c => c.id === tmpCond.id)

        if (currentCond) {
          const result =
            isConditionInOtherState(currentState.state.state, tmpCond.id)
          if (result) {
            // Remove condition from the state it is right now
            result[0].conditions
              .splice(result[0].conditions
                .findIndex(c => c.id === tmpCond.id), 1)

            // Get condition in the state that we are in instead of the one
            // in the wrong state (the one in the currentConditionsMap is now
            // deleted)
            Object.assign(currentCond, result[1])
          }

          // Modify static values
          currentCond.type = tmpCond.type
          currentCond.options = tmpCond.options
          currentCond.advancedOptions = tmpCond.advancedOptions
          currentCond.optional = tmpCond.optional
          currentCond.stateIds = tmpCond.stateIds
          currentCond.autoDeadlineSubtraction = tmpCond.autoDeadlineSubtraction
          currentCond.position = tmpCond.position

          // Do not update the label and disabled state if these
          // are controlled externally. This is for the special case
          // where we set invoice n of x label. The correct solution
          // is to have multiple states for that, not updating the label.
          // If you see this and are in the mode, fix it.
          if (!currentCond.stateIds || currentCond.stateIds?.indexOf('INVOICE_TWO') === -1) {
            currentCond.label = tmpCond.label
          }
        } else {
          // If we did not have the state, we add it, if it is added
          // to a completed state we set the condition to completed
          // as well.
          if (currentState.completed) {
            tmpCond.completed = true
          }
          currentState.conditions.push(tmpCond)
        }
      })

      // Re-check all state "complete" flags. Maybe, with the new states (if any)
      // and reordering of conditions the completeness of a state is not true
      // anymore.
      this.setState(currentState)
    })

    // Now, compare each state with the original "bestState" (if any).
    // And all those states below the original highest, will be manually
    // completed here. This way, ongoing projects in states than do not care
    // anymore about previous ones are not affected.
    // It does not affect archive projects.
    // NOTE: Only applicable when states array is modified (add a new one or
    // remove an existing one).
    if (initialLength !== this.states.length && bestState && !this.archived) {
      this.states
        .filter(s => s.state.position < bestState.state.position)
        .forEach(s => this.completeState(s.state.state))
    }
  }

  /**
   * Returns true if the first condition is checked but not the other. This
   * is equivalent to a "waiting" state.
   * @param conditionId - The first condition
   * @param sentId - The second.
   */
  private checkAdverseCondition(conditionId: string, sentId: string): ICustomerStateCondition | null {
    const condition = this.getConditionById(conditionId)
    const sent = this.getConditionById(sentId)
    if (condition?.completed === true && sent?.completed === false) {
      return sent
    }
    return null
  }

  /**
   * Put all conditions in a map for easier access. For some reason
   * this does not work if created in the constructor?
   */
  private createConditionMap(): void {
    this.states.forEach(s =>
      s.conditions.forEach(c =>
        this.conditionsMap.set(c.id, c)))
  }

  /**
   * Filters all conditions looking for the ones with "stateIds"
   */
  private getStateItems(): ICustomerStateCondition[] {
    let conditions: ICustomerStateCondition[] = []
    this.states.forEach((state: ICustomerState) => {
      conditions = conditions.concat(state.conditions
        .filter(c => c.stateIds)
        .filter((c: ICustomerStateCondition) => c.stateIds[0]))
    })
    return conditions
  }

  /**
   * Sets the overall state based on checks and potential other stuff.
   * This is for one specific state only
   */
  private setState(state: ICustomerState): void {
    state.completed = this.checkAllConditionsComplete(state)
  }

  /**
   * Set "next" based on what we think is "best"
   */
  private nextState(): CustomerStateLabel {
    // Best state is the highest completed state
    const bestState = this.bestState()
    // If we are already archived, next is the same as best
    if (this.archived) {
      this.nextStateLabel$.next(bestState)
      this.nextStateName = this.getStateByLabel(bestState).state
      return bestState
    }

    // Now we want to find the state that is one better than
    // the best. If best is C then D and if best is F then G
    // This is how we set the index +1
    let index = 0
    let state: ICustomerState = this.states[0]
    this.states.forEach((s: ICustomerState, i: number) => {
      if (s.state.state === bestState) {
        state = s
        index = i + 1
      }
    })

    // If the last state is the highest state (I) and is complete
    // There is no next so next must be the last. It "should" be archived
    // but could be an old state.
    index = Math.min(this.states.length - 1, index)

    // If the state we found is complete that is also the
    // best state, we return the state that comes after, which is
    // the index
    if (state.completed) {
      this.nextStateLabel$.next(this.states[index].state.state)
      this.nextStateName = this.states[index].state
      return this.states[index].state.state
    }

    // Now, if we did find a state that is complete, it must
    // be 'A' and we return the state
    this.nextStateLabel$.next(state.state.state)
    this.nextStateName = state.state
    return state.state.state
  }
}
