import {Component, inject, input} from '@angular/core'
import {OpenProjectService} from '../../services/open-project.service'
import {ProjectFormData} from '../../services/project-types'
import {MatCheckbox} from '@angular/material/checkbox'
import {I18nModule} from '../../i18n/i18n.module'

@Component({
  selector: 'kdl-assembly-options',
  templateUrl: './assembly-options.component.html',
  styleUrl: './assembly-options.component.scss',
  imports: [
    MatCheckbox,
    I18nModule
  ],
  standalone: true
})
export class AssemblyOptionsComponent {

  public projectForm = input.required<ProjectFormData>()

  private openProjectService: OpenProjectService = inject(OpenProjectService)

  public setOption(
    option: 'assemblyWaste' | 'assemblyCarry' | 'assemblyMeasure',
    value: boolean
  ) {
    const initial = this.projectForm()[option]
    this.projectForm()[option] = value

    if (initial !== value) {
      this.openProjectService.triggerChanges({projectChange: true})
    }
  }
}
