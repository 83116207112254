import {Component} from '@angular/core'
import {
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog'
import {
  EditProjectComponent
} from '../../../edit-project/project-data/edit/edit-project.component'
import {MatButton} from '@angular/material/button'

@Component({
  selector: 'kdl-floating-menu',
  templateUrl: './select-customer-country-dialog.component.html',
  styleUrls: ['./select-customer-country-dialog.component.scss'],
  imports: [
    MatDialogContent,
    EditProjectComponent,
    MatDialogTitle,
    MatDialogActions,
    MatButton,
    MatDialogClose
  ],
  standalone: true
})
export class SelectCustomerCountryDialogComponent {
  public static open(dialog: MatDialog): MatDialogRef<SelectCustomerCountryDialogComponent> {
    return dialog.open(SelectCustomerCountryDialogComponent, {
      minWidth: '500px',
      maxWidth: 'min(95vw, 1000px)'
    })
  }
}
