import {Pipe, PipeTransform} from '@angular/core'
import {I18nService} from '../service/i18n.service'
import {Observable} from 'rxjs'

@Pipe({
  name: 'i18n',
  standalone: true
})
export class I18nPipe implements PipeTransform {
  constructor(private service: I18nService) {
  }

  transform(label: string): Observable<string> {
    return this.service.getLabel(label)
  }
}

